import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import PropTypes from 'prop-types';

export default function SocialShare(props) {
  SocialShare.propTypes = {
    shareUrl: PropTypes.string.isRequired,
    budget: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  };

  const { shareUrl } = props;
  const budget = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(props.budget);
  const title = `Tengo un presupuesto de ${budget} para esta necesidad: \n \n  ${props.title} \n \n Si puedes ayudarme a resolverla, ingresa Pliss y haz tu oferta ;)`;

  return (
    <div className="center-social-icons">
      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <EmailShareButton url={shareUrl} subject="Tarea en Pliss" body={title}>
        <EmailIcon size={32} round iconFillColor="white" bgStyle={{ fill: '#5078FF' }} />
      </EmailShareButton>
    </div>
  );
}
