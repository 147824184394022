import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import Form from '../common/form';
import 'moment/locale/es';

class Feedback extends Form {
  render() {
    const { comments, avatar } = this.props;

    return (
      <Grid container>
        <Card className="comment-container">
          <List>
            {comments &&
              comments.map((comment) => (
                <ListItem alignItems="flex-start" key={comment._id}>
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Moment fromNow>{comment.created}</Moment>}
                    secondary={<>{comment.text}</>}
                  />
                </ListItem>
              ))}
          </List>
        </Card>
      </Grid>
    );
  }
}

export default Feedback;
