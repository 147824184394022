import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';
import logger from '../common/services/logService';

const tokenKey = 'auth-t';

export async function login(idToken) {
  const { data: jwt } = await http.post(`${apiEndpoint}/auth`, { idToken });
  const appToken = jwt.data[0].token;
  const { isFirstLogin } = jwt.data[0];
  localStorage.setItem('auth-t', `${appToken}`);
  localStorage.setItem('isRegU', jwt.data[1]);
  if (isFirstLogin) localStorage.setItem('tour', true);
  http.setJwt(appToken);
  return isFirstLogin;
}
export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  localStorage.removeItem('isRegU');
  localStorage.removeItem(tokenKey);

  indexedDB.deleteDatabase('firebaseLocalStorageDb');
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    http.setJwt(getJwt());
    return jwtDecode(jwt);
  } catch (error) {
    logger.log(error);
    return '';
  }
}

export async function updateUserAvatar(photoUrl) {
  await http.put(`${apiEndpoint}/users/updateUserAvatar`, {
    avatar: photoUrl,
  });
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
