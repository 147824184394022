import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

function deleteMessageReply(taskId, messageId, replyId) {
  return http.delete(
    `${apiEndpoint}/messages/${taskId}/message/${messageId}/messageReply/${replyId}`,
  );
}

export default deleteMessageReply;
