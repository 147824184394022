import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

export function getTask(taskId) {
  return http.get(`${apiEndpoint}/tasks/${taskId}`);
}

export function getContactInformation(userId) {
  return http.get(`${apiEndpoint}/users/contactInformation/${userId}`);
}

export function createOffer(taskId, offer) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/offers`, offer);
}

export function deleteTask(taskId, cancellationText) {
  return http.put(`${apiEndpoint}/tasks/${taskId}`, { cancellationText });
}

export function rejectTaskExecution(taskId, rejectionMessage) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/approvals/reject`, {
    rejectionMessage,
  });
}

export function completeTask(taskId, offerId) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/approvals/${offerId}/complete`);
}

export function loadImage(taskId, data) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/loadImage`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getTaskImages(taskId) {
  return http.get(`${apiEndpoint}/tasks/${taskId}/getTaskImages`);
}
