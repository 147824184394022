import React from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Offer from '../offer/Offer';

class Offers extends React.PureComponent {
  render() {
    const {
      offers,
      user,
      taskId,
      isTaskOwner,
      taskState,
      isTaskInfoEditable,
      assignedOffer,
    } = this.props;

    return (
      <>
        <Grid container direction="column" className="t-detail-offers">
          {assignedOffer.userId ? (
            <Typography variant="h6">OFERTA ASIGNADA</Typography>
          ) : (
            <Typography variant="h6">OFERTAS</Typography>
          )}
          {isTaskOwner && offers.length === 0 && (
            <Typography variant="caption" style={{ color: 'grey' }}>
              Aún no tienes Ofertas.
            </Typography>
          )}
          {!isTaskOwner && offers.length === 0 && (
            <Grid
              container
              alignItems="center"
              justify="center"
              alignContent="center"
              className="offers-empty-container"
              direction="column"
            >
              <>
                <Grid item>
                  <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" color="#a6a6a6" />
                </Grid>
                <Grid style={{ padding: '20px' }} />
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className="camel-case t-button-p"
                    onClick={this.props.onHandleOffer}
                  >
                    Ofertar
                  </Button>
                </Grid>
              </>
            </Grid>
          )}

          <List>
            {assignedOffer && assignedOffer.userId && (
              <>
                <Offer
                  key={assignedOffer._id}
                  offer={assignedOffer}
                  isTaskOwner={isTaskOwner}
                  taskState={taskState}
                  isTaskInfoEditable={isTaskInfoEditable}
                  user={user}
                  taskId={taskId}
                  refreshTaskInfo={this.props.refreshTaskInfo}
                  onHandleOffer={this.props.onHandleOffer}
                />
              </>
            )}

            {!assignedOffer.userId && (
              <>
                {offers.map((offer) => (
                  <Offer
                    key={offer._id}
                    offer={offer}
                    isTaskOwner={isTaskOwner}
                    taskState={taskState}
                    isTaskInfoEditable={isTaskInfoEditable}
                    user={user}
                    taskId={taskId}
                    refreshTaskInfo={this.props.refreshTaskInfo}
                    onHandleOffer={this.props.onHandleOffer}
                  />
                ))}
              </>
            )}
          </List>
        </Grid>
      </>
    );
  }
}

export default Offers;

Offers.propTypes = {
  // userId: PropTypes.string.isRequired,
  // length: PropTypes.number.isRequired,
  // offers: PropTypes.number.isRequired,
  // assignedOffer: PropTypes.number.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
  onHandleOffer: PropTypes.func.isRequired,
  // user: PropTypes.string.isRequired,
  // taskId: PropTypes.number.isRequired,
  isTaskOwner: PropTypes.bool.isRequired,
  isTaskInfoEditable: PropTypes.bool.isRequired,
  taskState: PropTypes.string.isRequired,
};
