import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SplittedView from './SplittedView';
import * as bService from '../browse/services';
import { appPaths } from '../../config.json';
import Loading from '../common/loading';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const PATHS = appPaths;

export default function GeneralTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const userId = props.user ? props.user._id : null;
  const [value, setValue] = React.useState(0);
  const [isLoading, setisLoading] = React.useState(true);
  const [basePath, setBasePath] = React.useState(PATHS.TASKS);
  const [taskSelected, setTaskSelected] = React.useState(0);
  const [badges, setBadges] = React.useState({});
  const [pushTask] = React.useState(false);

  useEffect(() => {
    const loadTaskFromUrlIfExists = (pathToReplace) => {
      const { url } = props.match;
      const urlTaskId = url.replace(`${pathToReplace}`, '');
      if (urlTaskId) setTaskSelected(urlTaskId);
      setisLoading(false);
    };

    const { path } = props.match;

    setTaskSelected(0);
    switch (path) {
      case PATHS.TASKS_ID:
        setValue(0);
        setBasePath(PATHS.TASKS);
        loadTaskFromUrlIfExists(`${PATHS.TASKS}/`);
        break;
      case PATHS.TASKS:
        setValue(0);
        setBasePath(PATHS.TASKS);
        break;
      case PATHS.MY_TASKS:
        setValue(1);
        setBasePath(PATHS.MY_TASKS);
        break;
      case PATHS.MY_TASKS_ID:
        setValue(1);
        setBasePath(PATHS.MY_TASKS);
        loadTaskFromUrlIfExists(`${PATHS.MY_TASKS}/`);
        break;
      case PATHS.MY_OFFERS:
        setValue(2);
        setBasePath(PATHS.MY_OFFERS);
        break;
      case PATHS.MY_OFFERS_ID:
        setValue(2);
        setBasePath(PATHS.MY_OFFERS);
        loadTaskFromUrlIfExists(`${PATHS.MY_OFFERS}/`);
        break;
      case PATHS.MY_ASSIGNMENTS:
        setValue(3);
        setBasePath(PATHS.MY_ASSIGNMENTS);
        break;
      case PATHS.MY_ASSIGNMENTS_ID:
        setValue(3);
        setBasePath(PATHS.MY_ASSIGNMENTS);
        loadTaskFromUrlIfExists(`${PATHS.MY_ASSIGNMENTS}/`);
        break;
      default:
        setValue(0);
        setBasePath(PATHS.TASKS);
        break;
    }
    getBadges();

    const interval = setInterval(() => {
      getBadges();
    }, 90000);
    setisLoading(false);
    return () => clearInterval(interval);
  }, [props]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        props.history.push(PATHS.TASKS);
        break;
      case 1:
        props.history.push(PATHS.MY_TASKS);
        break;
      case 2:
        props.history.push(PATHS.MY_OFFERS);
        break;
      case 3:
        props.history.push(PATHS.MY_ASSIGNMENTS);
        break;
      default:
        props.history.push(PATHS.TASKS);
        break;
    }
  };

  const handleDetail = (taskid) => {
    setTaskSelected(taskid);
    window.history.pushState({}, 'Pliss', `${basePath}/${taskid}`);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    handleChange(undefined, index);
  };

  const updateView = async (taskId) => {
    setTaskSelected(taskId);
  };

  const getBadges = async () => {
    if (props.user) {
      const badgesR = await bService.getNotificationsBadge();
      setBadges(badgesR.data);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className="appbar-index">
        {userId && (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              className="tutorial_all_tasks"
              label={
                <Badge color="secondary" badgeContent={badges ? badges.tasksBadge : 0}>
                  Todas las Tareas
                </Badge>
              }
              {...a11yProps(0)}
            />
            <Tab
              className="tutorial_my_tasks"
              label={
                <Badge color="secondary" badgeContent={badges ? badges.userTasksBadge : 0}>
                  Mis Tareas Publicadas
                </Badge>
              }
            />

            <Tab
              className="tutorial_my_offers"
              label={
                <Badge color="secondary" badgeContent={badges ? badges.useroOfferedTasksBadge : 0}>
                  Ofertas Realizadas
                </Badge>
              }
              {...a11yProps(0)}
            />

            <Tab
              className="tutorial_tasks_assigned"
              label={
                <Badge color="secondary" badgeContent={badges ? badges.userAssignedTasksBadge : 0}>
                  Tareas Asignadas
                </Badge>
              }
            />
          </Tabs>
        )}
      </AppBar>
      {isLoading ? (
        <Loading />
      ) : (
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction} className="main-cont-layouts">
            {basePath === PATHS.TASKS && (
              <SplittedView
                displayBadge={false}
                user={props.user}
                basePath={basePath}
                taskSelected={taskSelected}
                handleDetail={handleDetail}
                updateView={updateView}
                {...props}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction} className="main-cont-layouts">
            {basePath === PATHS.MY_TASKS && (
              <SplittedView
                displayBadge
                user={props.user}
                basePath={basePath}
                taskSelected={taskSelected}
                pushState={pushTask}
                handleDetail={handleDetail}
                updateView={updateView}
                {...props}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction} className="main-cont-layouts">
            {basePath === PATHS.MY_OFFERS && (
              <SplittedView
                displayBadge
                user={props.user}
                basePath={basePath}
                taskSelected={taskSelected}
                handleDetail={handleDetail}
                updateView={updateView}
                {...props}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction} className="main-cont-layouts">
            {basePath === PATHS.MY_ASSIGNMENTS && (
              <SplittedView
                displayBadge
                user={props.user}
                basePath={basePath}
                taskSelected={taskSelected}
                handleDetail={handleDetail}
                updateView={updateView}
                {...props}
              />
            )}
          </TabPanel>
        </SwipeableViews>
      )}{' '}
    </div>
  );
}
