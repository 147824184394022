import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {
  apiKey,
  authDomain,
  projectId,
  messagingSenderId,
  appId,
  measurementId,
} from '../config.json';

const config = {
  apiKey,
  authDomain,
  projectId,
  messagingSenderId,
  appId,
  measurementId,
};
firebase.initializeApp(config);
const analytics = firebase.analytics();

const FirebaseContext = React.createContext({ analytics });
export default FirebaseContext;
