import React from 'react';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const StyledRating = withStyles({
  iconFilled: {
    color: '#01d494',
  },
  iconHover: {
    color: '',
  },
})(Rating);

const labels = {
  1: 'Malo',
  2: 'Regular',
  3: 'Bueno',
  4: 'Muy Bueno',
  5: 'Excellente',
};

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <Tooltip title={labels[value] || ''}>
      <div {...other} />
    </Tooltip>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function SimpleRating(props) {
  const [value, setValue] = React.useState(0);
  const { onHandleRatingChange, size, initialRating, readOnly, classProp } = props;

  return (
    <>
      <StyledRating
        className={classProp}
        name="hover-tooltip"
        value={initialRating || value}
        icon={<FontAwesomeIcon icon={faStar} size="1x" color="#01d494" />}
        emptyIcon={<FontAwesomeIcon icon={faStar} size="1x" color="#b8b8b8" />}
        IconContainerComponent={IconContainer}
        size={size}
        readOnly={readOnly}
        onChange={(event, newValue) => {
          onHandleRatingChange(newValue);
          setValue(newValue);
        }}
      />
    </>
  );
}
