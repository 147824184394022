import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Form from '../common/form';
import deleteMessageReply from './services';
import logger from '../common/services/logService';

class MessageReply extends Form {
  handleDeleteAction = async () => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    try {
      const { taskId, messageId, reply } = this.props;
      await deleteMessageReply(taskId, messageId, reply._id);
      await this.props.refreshTaskInfo(taskId);
      Toast.fire({
        icon: 'success',
        title: 'Mensaje eliminado!',
      });
    } catch (err) {
      logger.log(err, { component: 'MessageReply' });
    }
  };

  render() {
    let isMessageOwner;
    const { reply, isTaskInfoEditable, user, key } = this.props;
    if (user) isMessageOwner = reply.userId._id === user._id;

    return (
      <ListItem key={key} className="generic-reply-style">
        <ListItemAvatar>
          <Avatar
            component={Link}
            className="small-avatar"
            src={reply.userId ? reply.userId.avatar : null}
            to={`/PublicProfile/${reply.userId ? reply.userId._id : null}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography component="span" color="secondary" variant="subtitle2">
                {reply.userId.name}
              </Typography>
              &nbsp;dijo&nbsp;
              <Moment fromNow className=".m-time-stamp-message">
                {reply.created}
              </Moment>
            </>
          }
          secondary={reply.text}
        />
        {isTaskInfoEditable && isMessageOwner && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={this.handleDeleteAction}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

export default MessageReply;
