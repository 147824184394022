import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TaskDetail from '../taskDetail/taskDetail';
import PreviewTasks from '../PreviewTasks/PreviewTasks';

export default function SplittedView(props) {
  const { user, taskSelected, handleDetail, displayBadge, basePath, pushTask, updateView } = props;

  const [showRightPanel, setShowRightPanel] = React.useState(true);
  const [showLeftPanel, setShowLeftPanel] = React.useState(true);
  const [taskSelectedFromUrl, setTaskSelectedFromUrl] = React.useState(false);

  useEffect(() => {
    resizeTasks();
    window.addEventListener('resize', resizeTasks);
  });

  const resizeTasks = () => {
    const pathName = window.location.pathname;
    const pathRegex = new RegExp(`${props.basePath}/|${props.basePath}`, 'gi');
    const residue = pathName.replace(pathRegex, '');
    if (residue.length > 0) setTaskSelectedFromUrl(true);
    else setTaskSelectedFromUrl(false);
    if (window.innerWidth <= 959) {
      if (taskSelectedFromUrl) {
        setShowLeftPanel(false);
        setShowRightPanel(true);
      } else {
        setShowLeftPanel(true);
        setShowRightPanel(false);
      }
    } else {
      setShowLeftPanel(true);
      setShowRightPanel(true);
    }
  };

  return (
    <Grid container justify="center" className="pliss-body">
      {showLeftPanel && (
        <Grid item sm={12} md={4} lg={4} className="browse-body prev-tasks" id="prev-tasks">
          <PreviewTasks
            displayBadge={displayBadge}
            taskSelected={taskSelected}
            basePath={basePath}
            updateView={updateView}
            user={user}
            pushTask={pushTask}
            onDetail={(taskid) => {
              handleDetail(taskid);
              resizeTasks();
            }}
          />
        </Grid>
      )}

      {showRightPanel && (
        <Grid item sm={12} md={8} lg={8} className="task-detail-w">
          {taskSelected !== 0 && <TaskDetail taskid={taskSelected} user={user} />}
        </Grid>
      )}
    </Grid>
  );
}
