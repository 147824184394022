import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Tooltip from '@material-ui/core/Tooltip';
import PlissRating from '../plissRating/PlissRating';
import * as pService from '../profile/services';
import logger from '../common/services/logService';

const MySwal = withReactContent(Swal);

class QualificationForm extends Component {
  state = {
    authorId: '',
    task: {},
    assignedOffer: {},
    comment: '',
    rating: 0,
    swalCustomErrorValidation: false,
  };

  handleRatingChange = (newValue) => {
    this.setState({ rating: newValue });
  };

  handleChange = (value) => {
    this.setState({ comment: value.target.value });
  };

  handleClick = async () => {
    const that = this;

    MySwal.fire({
      showCancelButton: true,
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
      confirmButtonText: 'Guardar Calificación',
      cancelButtonText: 'Cancelar',
      html: (
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <Typography variant="h6">
              Califica entre 1 y 5 a{' '}
              {this.props.isTaskOwner && this.props.assignedOffer.userId.name}
              {this.props.isOfferOwner && this.props.task.userId.name}
            </Typography>
          </Grid>
          <Grid item>
            {this.props.isTaskOwner && this.props.assignedOffer.userId && (
              <Avatar
                src={this.props.assignedOffer.userId.avatar}
                style={{ margin: '10px', width: '90px', height: '90px' }}
              />
            )}

            {this.props.isOfferOwner && this.props.task.userId && (
              <Avatar
                src={this.props.task.userId.avatar}
                style={{ margin: '10px', width: '90px', height: '90px' }}
              />
            )}
          </Grid>
          <Grid item>
            <PlissRating
              onHandleRatingChange={this.handleRatingChange}
              size="large"
              readOnly={false}
            />
          </Grid>
          {/* <Typography variant="subtitle1">
            Incluye también un comentario de mínimo 15 caracteres:
          </Typography> */}
          {/* <Typography variant="caption">(Minimo 20 caracteres)</Typography> */}
          <TextField
            id="reviewWorker"
            label="Comentarios"
            variant="outlined"
            multiline
            required
            rows="5"
            rowsMax="4"
            fullWidth
            inputProps={{ maxLength: 500 }}
            onChange={this.handleChange}
          />
        </Grid>
      ),
      preConfirm() {
        const swalMessage = document.createElement('div');
        swalMessage.className = 'swal2-validation-message';
        swalMessage.id = 'swal2-validation-message';
        swalMessage.setAttribute(
          'style',
          'display: flex; margin-left: -20px; margin-right: -20px;',
        );
        swalMessage.innerHTML = 'Debes seleccionar las estrellas para calificar de 1 a 5.';

        const swalContent = document.getElementsByClassName('swal2-content')[0];
        if (that.state.rating === 0) {
          if (!that.state.swalCustomErrorValidation) {
            swalContent.appendChild(swalMessage);
            that.setState({ swalCustomErrorValidation: true });
            return Promise.resolve(false);
          }
          return Promise.resolve(false);
        }
        return Promise.resolve();
      },
    }).then(async (result) => {
      if (result.value) {
        if (this.state.rating !== 0) {
          try {
            const { authorId, task, assignedOffer } = this.props;
            const { rating, comment } = this.state;
            const reviewType = authorId === task.userId._id ? 'W' : 'B';
            let reviewData = '';
            let messageTest = '';

            if (this.props.isTaskOwner) {
              messageTest = `Muchas gracias por calificar a <b>${this.props.assignedOffer.userId.name}</b>. Le notificaremos que ya aceptaste y calificaste la ejecución de la tarea.`;
            } else {
              messageTest = `Muchas gracias por calificar a <b>${this.props.task.userId.name}</b>. Felicitaciones por terminar la tarea cumpliento con las necesidades de <b>${this.props.assignedOffer.userId.name}</b>, con esto damos por finalizado completamente el servicio. Gracias por hacer crecer nuestra comunidad ;).`;
            }

            if (reviewType === 'W') {
              reviewData = {
                offerId: assignedOffer._id,
                authorId: `${authorId}`,
                userId: `${assignedOffer.userId._id}`,
                rating,
                comment,
                reviewType,
              };
            } else {
              reviewData = {
                offerId: assignedOffer._id,
                authorId: `${authorId}`,
                userId: `${task.userId._id}`,
                rating,
                comment,
                reviewType,
              };
            }
            await pService.postReview(reviewData, task._id);
            this.props.refreshTaskInfo(task._id);

            MySwal.fire({
              html: messageTest,
              icon: 'success',
              confirmButtonText: 'Aceptar',
            });
          } catch (error) {
            logger.log(error, { component: 'QualificationForm' });
            MySwal.fire({
              title: 'Error!',
              text: `Se presento un error al momento de almacenar el comentario, intenta de nuevo mas tarde.`,
              icon: 'error',
            });
          }
        } else {
          MySwal.fire(
            'Importante!',
            'Para que nuestra comunidad siga creciendo, debes calificar de 1 a 5.',
            // 'Por favor asigna una calificación de 1 a 5 a tu experiencia!',
            'info',
          );
        }
      }
    });
  };

  render() {
    return (
      <Tooltip
        placement="bottom"
        title="Próximamente habilitaremos los Pagos!"
        disableTouchListener
      >
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          className="camel-case t-button-p t-button-qualification"
          onClick={this.handleClick}
        >
          {this.props.isTaskOwner && 'Confirmar'}
          {this.props.isOfferOwner && 'Finalizar'}
        </Button>
      </Tooltip>
    );
  }
}

export default QualificationForm;
