import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';

export default function loading() {
  return (
    <div>
      <Backdrop open style={{ zIndex: 10000, background: '#fff', opacity: 0.8 }}>
        <img
          src="https://pliss-assets.s3.amazonaws.com/common/plissFaceLogo.svg"
          className="spinner-logo"
          alt="spinner-logo"
        />
      </Backdrop>
    </div>
  );
}
