import axios from 'axios';
import Swal from 'sweetalert2';
import logger from './logService';
import { apiEndpoint } from '../../../config.json';
// eslint-disable-next-line import/no-cycle
import { logout, getCurrentUser, loginWithJwt } from '../../auth/services';

let expirationDate; // Inicio de conteo
const offset = 5; // ¿Faltando cuántos minutos actualizar el token?
let lastInteraction = new Date(); // última interacción del usuario
const maxInactiveTime = 90; // Tiempo máximo de inactividad
let activeModalIsOpen = false;

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function closeSession() {
  logout();
  window.location.href = '/';
}

function getExpirationDate() {
  const currentUser = getCurrentUser();
  const iDate = currentUser ? currentUser.exp : undefined;

  return iDate ? new Date(iDate * 1000) : iDate;
}

function getDiffInMinutes() {
  expirationDate = getExpirationDate();

  const currentDate = new Date();
  const diffInMinutes = (expirationDate - currentDate) / 1000 / 60;
  return diffInMinutes;
}

async function refreshToken() {
  try {
    const refreshResult = await axios.post(`${apiEndpoint}/auth/refresh`);
    const newToken = refreshResult.data.data[0].idToken;
    loginWithJwt(newToken);
  } catch (error) {
    closeSession();
    logger.log(error, { component: 'httpService' });
  }
}

function checkSession() {
  const diffInMinutes = getDiffInMinutes();
  if (diffInMinutes <= 0) closeSession();
  else if (diffInMinutes <= offset) {
    refreshToken();
  }
}

axios.interceptors.response.use(
  async (response) => {
    lastInteraction = new Date();
    checkSession();

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) closeSession();
      // if (error.response.status === 404) window.location = '/not-found';
      if (error.response.status >= 500) {
        logger.log(error, { component: 'httpService' });
        Toast.fire({ icon: 'error', title: 'Un error inesperado ha ocurrido' });
      }
    }

    return Promise.reject(error);
  },
);

function userIsActive() {
  const currentDate = new Date();
  return (currentDate - lastInteraction) / 1000 / 60 <= maxInactiveTime;
}

setInterval(async () => {
  if (!userIsActive() && getCurrentUser() && !activeModalIsOpen) {
    activeModalIsOpen = true;
    const timeOut = setTimeout(() => {
      closeSession();
    }, 20000);
    Swal.fire({
      icon: 'question',
      title: '¿Aún estás ahí?',
      text: 'Responde si para continuar',
      confirmButtonText: 'Si',
      confirmButtonColor: '#5078ff',
    }).then(() => {
      checkSession();
      clearTimeout(timeOut);
      activeModalIsOpen = false;
      lastInteraction = new Date();
    });
  }
}, 5000);

function setJwt(jwt) {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  checkSession,
};
