/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { red } from '@material-ui/core/colors';
import WebFont from 'webfontloader';
import * as serviceWorker from './serviceWorker';
import App from './App';
import logger from './components/common/services/logService';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4f77fc',
    },
    secondary: {
      main: '#0077d7',
      contrastText: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
  },
});

logger.init();

WebFont.load({
  google: {
    families: ['Nunito:200,300,400,700,900', 'sans-serif'],
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
