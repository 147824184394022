/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Profile from './components/profile/Profile';
import Auth from './components/auth/auth';
import Logout from './components/logout/Logout';
import { getCurrentUser } from './components/auth/services';
import ProtectedRoute from './components/common/protectedRoute';
import NotFound from './components/common/NotFound';
import TaskGeneral from './components/taskGeneral/taskGeneral';
import PublicProfile from './components/profile/PublicProfile';
import Home from './components/home/home';
import LabelBottomNavigation from './components/mobile/menu/LabelBottomNavigation';
import ContextNavbar from './components/context-navbar/ContextNavbar';
import UserContext from './contexts/user-context';
import CalendarView from './components/calendar/calendarView';
import QualificationForm from './components/qualificationForm/QualificationForm';
import DoersReport from './components/doers-report/DoersReport';
import AboutUs from './components/home/aboutUs';
import FaqPage from './components/home/faqPage';

class App extends Component {
  state = {
    user: null,
  };

  async componentDidMount() {
    const user = getCurrentUser();
    if (user) {
      this.setState({ user });
    }
  }

  render() {
    const { user } = this.state;
    const loggedBaseUrl = 'myaccount';
    const userValue = {
      user: this.state.user,
    };

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        <UserContext.Provider value={userValue}>
          <ContextNavbar {...this.props} />
          <Switch>
            <Route path="/no-encontrado/" component={NotFound} />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-tasks/:myTaskId`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-offers/:myOfferId`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-assigned-tasks/:myAssignId`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-tasks`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-offers`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/my-assigned-tasks`}
              render={(props) => <TaskGeneral {...props} user={user} />}
            />
            <ProtectedRoute
              path="/qualificationForm/:taskId"
              render={(props) => <QualificationForm {...props} user={user} />}
            />
            <ProtectedRoute
              path={`/${loggedBaseUrl}/calendar`}
              render={() => <CalendarView user={user} />}
            />
            <ProtectedRoute path={`/${loggedBaseUrl}/doers`} render={() => <DoersReport />} />

            <Route
              path="/signin/"
              render={(props) => <Auth {...props} tabSelected={0} user={user} />}
            />
            <Route path="/logout/" component={Logout} />
            <Route path="/about-us/" component={AboutUs} />
            <Route path="/faq-page/" component={FaqPage} />
            <Route path="/register/" render={(props) => <Auth {...props} tabSelected={1} />} />
            <Route path="/tasks/:id" render={(props) => <TaskGeneral {...props} user={user} />} />
            <Route path="/tasks/" render={(props) => <TaskGeneral {...props} user={user} />} />
            <Route
              path="/publicProfile/:id"
              render={(props) => <PublicProfile {...props} user={user} />}
            />

            <ProtectedRoute
              path={`/${loggedBaseUrl}/profile`}
              render={(props) => <Profile {...props} user={user} />}
            />
            <Route exact path="/" render={(props) => <Home {...props} user={user} />} />
            <Redirect from="/index.html" to="/" />
            <Route component={NotFound} />
          </Switch>
          <Hidden smUp>
            {user && (
              <LabelBottomNavigation
                className="mobile-bottom-menu"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...this.props}
                user={user}
              />
            )}
          </Hidden>
        </UserContext.Provider>
      </>
    );
  }
}

export default App;
