import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import * as moment from 'moment';
import 'moment/locale/es';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import imageCompression from 'browser-image-compression';
import Offers from '../offers/Offers';
import Messages from '../messages/Messages';
import * as pService from '../profile/services';
import * as tService from './services';
import { baseUrl } from '../../config.json';
import SocialShare from '../common/socialShare';
import Feedback from '../feedback/feedback';
import NumberFormatSwal from '../newTask/NumberFormat';
import Loading from '../common/loading';
import Budget from './budget';
import TaskStepper from './taskStepper';
import Details from './details';
import { minBudget, maxBudget } from '../common/params.json';
import { formatBudget } from '../common/utils';
import logger from '../common/services/logService';

const messageRangeValueBudget = `El valor debe estár entre ${formatBudget(
  minBudget,
)} y ${formatBudget(maxBudget)}`;

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3500,
  timerProgressBar: true,
});

class TaskDetail extends Component {
  constructor(props) {
    super(props);
    this.updateMessage = this.refreshTaskInfo.bind(this);
  }

  state = {
    task: {
      userId: '',
    },
    offers: [],
    user: '',
    goToProfile: false,
    goToAuth: false,
    assignedOffer: {},
    isLoading: true,
    messages: [],
    activeStep: 1,
    taskImages: [],
    avatarLoaderVisible: true,
  };

  async componentDidMount() {
    let orderedOffers = {};
    let offers = [];
    let assignedOffer = {};
    const taskId = this.props.taskid;
    if (taskId !== 0) {
      try {
        const task = await tService.getTask(taskId);
        if (task.data.offers) {
          orderedOffers = this.orderOffers(task.data, task.data.offers);
          offers = orderedOffers.offers;
          assignedOffer = orderedOffers.assignedOffer;
        }
        this.setState({
          task: task.data,
          taskState: task.data.state,
          messages: task.data.messages,
          offers,
          assignedOffer,
          isLoading: false,
          taskImages: task.data.images,
        });
      } catch (err) {
        logger.log(err, { component: 'TaskDetail' });
        this.setState({ isLoading: false });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.taskid !== this.props.taskid) {
      let offers = [];
      let assignedOffer = {};
      let orderedOffers = {};
      this.setState({ isLoading: true });

      const { taskid } = this.props;
      try {
        const task = await tService.getTask(taskid);
        if (task.data.offers) {
          orderedOffers = this.orderOffers(task.data, task.data.offers);
          offers = orderedOffers.offers;
          assignedOffer = orderedOffers.assignedOffer;
        }
        this.setState({
          task: task.data,
          taskState: task.data.state,
          messages: task.data.messages,
          offers,
          assignedOffer,
          isLoading: false,
          taskImages: task.data.images,
        });
      } catch (err) {
        logger.log(err, { component: 'TaskDetail' });
      }
    }
  }

  orderOffers = (task, offers) => {
    let sortedOffers = [];
    let assignedOffer = {};
    sortedOffers = _.sortBy(offers, ['created']).reverse();
    if (task.state !== 'Created' && task.state !== 'Cancelled') {
      assignedOffer = offers.find((offer) => {
        if (offer.state !== 'Created' && offer.state !== 'Cancelled') return offer;
        return null;
      });
      if (assignedOffer) {
        const position = sortedOffers.indexOf(assignedOffer);
        sortedOffers.splice(position, 1);
        sortedOffers.unshift(assignedOffer);
      }
    } else {
      assignedOffer = {};
    }
    return { offers: sortedOffers, assignedOffer };
  };

  getTaskIdBasedOnPath = () => {
    let { pathname } = window.location;
    pathname = pathname.replace(pathname, '');
    if (pathname) {
      return pathname;
    }
    return this.props.taskid;
  };

  refreshTaskInfo = async (taskid) => {
    try {
      const task = await tService.getTask(taskid);
      this.setState({ task: task.data, messages: task.data.messages });
      if (task.data.offers) {
        const { offers, assignedOffer } = this.orderOffers(task.data, task.data.offers);
        this.setState({ offers, assignedOffer });
      }
    } catch (err) {
      logger.log(err, { component: 'TaskDetail' });
    }
  };

  handleNewOffer = async (taskId, newOffer) => {
    try {
      const res = await tService.createOffer(taskId, newOffer);
      this.refreshTaskInfo(taskId);
      return res;
    } catch (error) {
      logger.log(error, { component: 'TaskDetail' });
      return null;
    }
  };

  cancelTask = async (cancellationText) => {
    try {
      await tService.deleteTask(this.props.taskid, cancellationText);
      this.refreshTaskInfo(this.props.taskid);
      PubSub.publish('UPDATE_TASK_STATE', {
        taskId: this.props.taskid,
        state: 'Cancelled',
      });
      Swal.fire({
        title: 'Tarea cancelada exitosamente',
        icon: 'success',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#5078ff',
      });
    } catch (error) {
      logger.log(error, { component: 'TaskDetail' });
      Swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  handleCancellation = async () => {
    Swal.mixin({
      text: 'Cuéntanos, cual el motivo de cancelación de tu tarea?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
    })
      .queue([
        {
          input: 'textarea',
          inputAttributes: {
            maxlength: 1499,
            minlength: 50,
          },
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.length < 10 || value.length >= 1000) {
                resolve('La longitud mínima son 10 caracteres, la máxima 1000');
              } else {
                resolve();
              }
            });
          },
        },
      ])
      .then(async (result) => {
        const { value } = result;
        if (value) {
          const cancellationText = value[0];
          this.cancelTask(cancellationText);
        }
      });
  };

  handleRejection = async () => {
    Swal.mixin({
      text: 'Cuéntanos por qué motivo no aceptas la tarea como finalizada?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enviar',
    })
      .queue([
        {
          input: 'textarea',
          inputAttributes: {
            maxlength: 1000,
            minlength: 20,
          },
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.length > 19 && value.length <= 1000) {
                resolve();
              } else if (value.length === 0) {
                resolve('El campo es requerido');
              } else {
                resolve('La longitud mínima son 20 caracteres, la máxima 1000');
              }
            });
          },
        },
      ])
      .then(async (result) => {
        const { value } = result;
        if (value) {
          try {
            const rejectionMessage = value[0];
            const task = await tService.rejectTaskExecution(this.props.taskid, rejectionMessage);
            Swal.fire({
              text: 'Tarea devuelta exitosamente!',
              icon: 'success',
              confirmButtonText: 'Aceptar',
            });
            this.setState({ task: task.data });
          } catch (error) {
            logger.log(error, { component: 'TaskDetail' });
            Swal.fire({
              icon: 'warning',
              title: 'Inténtelo más tarde!',
              confirmButtonText: 'Aceptar',
            });
          }
        }
      });
  };

  completeTask = async () => {
    Swal.fire({
      text: 'Finalizaste la tarea de acuerdo con la descripción?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then(async (result) => {
      if (result.value) {
        try {
          const { assignedOffer } = this.state;
          const task = await tService.completeTask(this.props.taskid, assignedOffer._id);
          this.setState({ task: task.data });
          Swal.fire({
            title: 'Buen Trabajo!',
            text: 'Vamos a notificarle al dueño de la tarea que ésta fue completada exitosamente!',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        } catch (error) {
          logger.log(error, { component: 'TaskDetail' });
          Swal.fire({
            title: 'Oops...',
            text: 'Ha ocurrido un error, intenta más tarde.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      }
    });
  };

  verifyTask = async () => {
    try {
      const task = await tService.completeTask(this.props.taskid);
      this.setState({ task });
      Swal.fire({
        title: 'Tarea verificada exitosamente!',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      logger.log(error, { component: 'TaskDetail' });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  editOffer = async () => {
    this.handleOffer();
  };

  handleOffer = async () => {
    if (!this.props.user) {
      Swal.fire({
        icon: 'info',
        title: 'Importante!',
        text:
          'Para poder ofertar debes primero iniciar sesión y tener todos tus datos básicos completados!',
        confirmButtonText: 'Aceptar',
        footer: '<a aria-label="Ir al home" href="/">Ir al Home</a>',
      });
    } else {
      const profile = await pService.getProfile();
      const { offers } = profile.data;

      const that = this;
      that.setState({ swalCustomErrorValidation: false });
      const reSwal = withReactContent(Swal);

      const hadOffered = offers.find((taskId) => {
        return taskId === this.state.task._id;
      });

      if (hadOffered) {
        Swal.fire({
          text:
            'Parece que ya has ofertado para esta tarea! Recuerda: solo puedes ofertar una sola vez o modificar la oferta realizada.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#5078ff',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Cerrar',
        });
        return;
      }

      if (profile.data.identification && profile.data.phone) {
        reSwal
          .mixin({
            confirmButtonText: 'Siguiente &rarr;',
            confirmButtonColor: '#448aff',
            showCancelButton: true,
            showCloseButton: true,
            background: '#fff',
            cancelButtonText: 'Cancelar',
            progressSteps: ['1', '2'],
            allowOutsideClick: false,
          })
          .queue([
            {
              title: 'Descripción',
              text: `Cuéntale a quién publicó esta necesidad porque eres el indicado para hacerla el ${moment(
                this.state.task.dueDate,
              ).format('DD/MMMM')}`,
              confirmButtonText: 'Siguiente &rarr;',
              inputAutoTrim: false,
              input: 'textarea',
              inputAttributes: {
                maxlength: 1000,
                minlength: 10,
              },
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  if (value.length < 10 || value.length >= 1000) {
                    resolve('La longitud mínima son 10 caracteres, la máxima 1000');
                  } else {
                    resolve();
                  }
                });
              },
            },
            {
              html: (
                <>
                  <div className="swal2-title">¿Cuál es tu oferta?</div>
                  <div className="swal2-content">
                    <div style={{ marginBottom: '10px' }}>
                      <span>La propuesta inicial de la tarea es de: </span>
                      <NumberFormat
                        value={this.state.task.budget}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="$"
                      />
                    </div>
                    <NumberFormatSwal />
                  </div>
                </>
              ),
              preConfirm() {
                const swalMessage = document.createElement('div');
                swalMessage.className = 'swal2-validation-message';
                swalMessage.id = 'swal2-validation-message';
                swalMessage.setAttribute(
                  'style',
                  'display: flex; margin-left: -20px; margin-right: -20px;',
                );
                swalMessage.innerHTML = messageRangeValueBudget;
                const swalContent = document.getElementsByClassName('swal2-content')[0];
                const valueBudget = document
                  .getElementById('formatted-numberformat-input')
                  .value.replace(/\./g, '');
                if (!valueBudget || valueBudget < minBudget || valueBudget > maxBudget) {
                  if (!that.state.swalCustomErrorValidation) swalContent.appendChild(swalMessage);

                  that.setState({ swalCustomErrorValidation: true });
                  return Promise.resolve(false);
                }
                that.setState({ swalCustomErrorValidation: false });
                return Promise.resolve(valueBudget);
              },
            },
          ])
          .then(async (result) => {
            const { value } = result;
            if (value) {
              const newOffer = {
                description: value[0],
                value: parseFloat(value[1].replace(/,/g, '')),
                taskId: this.props.taskid,
              };
              const res = await this.handleNewOffer(this.props.taskid, newOffer);
              if (res.status === 200) {
                reSwal.fire({
                  icon: 'success',
                  title: 'Excelente!',
                  text: 'Has ofertado exitosamente!',
                  confirmButtonText: 'Aceptar',
                });
              } else {
                reSwal.fire({
                  title: 'Oops...',
                  text: 'Há ocurrido un error, intenta más tarde!',
                  confirmButtonText: 'Aceptar',
                  icon: 'error',
                });
              }
            }
          });
      } else {
        reSwal
          .fire({
            title: 'Oops...',
            text: 'Debes completar tu perfil antes de ofertar a una solicitud!',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#5078ff',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
          })
          .then((result) => {
            if (result.value) {
              this.setState({ goToProfile: true });
            }
          });
      }
    }
  };

  handleFile = async (event) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
      onProgress: () => {},
    };
    const photo = event.target.files[0];
    if (photo && photo.size < 51242880) {
      const compressedFile = await imageCompression(photo, options);
      const formData = new FormData();
      try {
        Toast.fire({
          icon: 'info',
          title: 'Cargando Imagen , Un momento porfavor! ',
        });
        formData.append('image', compressedFile);
        const res = await tService.loadImage(this.props.taskid, formData);

        const newImages = this.state.taskImages.concat(res.data);
        this.setState({ taskImages: newImages });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Há ocurrido un error, intenta más tarde!',
          confirmButtonText: 'Aceptar',
        });
        logger.log(error, { component: 'TaskDetail' });
      }
    } else if (photo !== undefined) {
      Swal.fire({
        icon: 'info',
        title: 'Importante!',
        text: 'El tamaño del archivo no debe superar 5 MB!',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  render() {
    if (this.state.goToProfile) {
      this.setState({ goToProfile: false });
      return <Redirect push to="/myaccount/profile/" />;
    }

    if (this.state.goToAuth) {
      this.setState({ goToAuth: false });
      return <Redirect push to="/" />;
    }

    const { task, offers, taskState, assignedOffer, messages, taskImages } = this.state;
    const { user } = this.props;
    let isTaskOwner = false;
    let isOfferOwner = false;
    const isTaskInfoEditable = task.state === 'Created';
    const taskHascomments = task.comments ? task.comments.length > 0 : false;

    if (user) {
      user.isRegistered = JSON.parse(localStorage.getItem('isRegU'));
      isTaskOwner = user._id === task.userId._id;
      if (assignedOffer.userId) {
        isOfferOwner = user._id === assignedOffer.userId._id;
      }
    }

    return (
      <Slide direction="down" in mountOnEnter unmountOnExit className="paper-wrapper">
        <Paper>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <Card className="t-detail-content">
              <Grid container className="t-basic-info">
                <Grid container alignItems="flex-start">
                  <Grid item xs={12} sm={8} md={8} className="t-header-left-c">
                    {task.state !== 'Cancelled' && <TaskStepper taskState={task.state} />}

                    {task.state === 'Cancelled' && (
                      <Grid container>
                        <Chip label="CANCELADA" className="task-tag-cancelled" />
                      </Grid>
                    )}
                    <Typography className="t-detail-title">{task.title}</Typography>
                    <Grid container>
                      <Hidden smUp>
                        <Budget
                          quantity={task.budget}
                          isTaskOwner={isTaskOwner}
                          taskState={task.state}
                          isOfferOwner={isOfferOwner}
                          onHandleOffer={this.handleOffer}
                          onHandleCancellation={this.handleCancellation}
                          onHandleRejection={this.handleRejection}
                          onCompleteTask={this.completeTask}
                          onRefreshTaskInfo={this.refreshTaskInfo}
                          userId={user && user._id}
                          task={task}
                          assignedOffer={assignedOffer}
                        />
                      </Hidden>
                    </Grid>

                    <Details task={task} />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    {isTaskOwner ? (
                      <SocialShare
                        shareUrl={`${baseUrl}/tasks/${task._id}`}
                        title={task.title}
                        budget={task.budget}
                      />
                    ) : (
                      ''
                    )}
                    <Grid style={{ padding: '2px' }} />
                    <Hidden xsDown>
                      <Budget
                        quantity={task.budget}
                        isTaskOwner={isTaskOwner}
                        taskState={task.state}
                        isOfferOwner={isOfferOwner}
                        onHandleOffer={this.handleOffer}
                        onHandleCancellation={this.handleCancellation}
                        onHandleRejection={this.handleRejection}
                        onCompleteTask={this.completeTask}
                        onRefreshTaskInfo={this.refreshTaskInfo}
                        userId={user && user._id}
                        task={task}
                        assignedOffer={assignedOffer}
                      />
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid container direction="column" className="t-details-block" justify="center">
                  <Typography variant="h6">DESCRIPCIÓN</Typography>
                  <Typography variant="body1" paragraph className="t-detail-p line-breaks">
                    {task.description}
                  </Typography>
                </Grid>
                {((taskImages && taskImages.length > 0) || isTaskOwner) && (
                  <div>
                    <Grid container direction="row" alignItems="center">
                      <Typography variant="h6">IMAGENES</Typography>
                      {isTaskOwner && isTaskInfoEditable && (
                        <div
                          onClick={() => {
                            const el = document.getElementById('fileElem');
                            if (el) {
                              el.click();
                            }
                          }}
                        >
                          <IconButton color="primary" aria-label="Cargar Imagen" component="span">
                            <PhotoCamera />
                            <input
                              type="file"
                              id="fileElem"
                              accept=".jpeg, .jpg, .png"
                              style={{ display: 'none' }}
                              onChange={this.handleFile}
                            />
                          </IconButton>
                        </div>
                      )}
                    </Grid>

                    <Grid container spacing={1}>
                      {taskImages &&
                        taskImages.map((image) => (
                          <Grid item key={image}>
                            <a href={image}>
                              <img src={image} style={{ width: '120px' }} alt={image} />
                            </a>
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                )}

                {(isOfferOwner || isTaskOwner) && taskHascomments && (
                  <Grid container direction="column" className="t-details-block" justify="center">
                    <Typography variant="h6">RETROALIMENTACIÓN</Typography>
                    <Feedback avatar={task.userId.avatar} comments={task.comments} />
                  </Grid>
                )}
                <Grid container direction="column" justify="center">
                  <Offers
                    offers={offers}
                    user={user}
                    isTaskOwner={isTaskOwner}
                    taskState={taskState}
                    taskId={this.props.taskid}
                    refreshTaskInfo={this.refreshTaskInfo}
                    onHandleOffer={this.handleOffer}
                    isTaskInfoEditable={isTaskInfoEditable}
                    assignedOffer={assignedOffer != null ? assignedOffer : null}
                  />
                </Grid>
                <Grid container direction="column" justify="center" className="messages-bottom">
                  <Messages
                    messages={messages}
                    taskId={this.props.taskid}
                    user={user}
                    refreshTaskInfo={this.refreshTaskInfo}
                    isTaskInfoEditable={isTaskInfoEditable}
                    isTaskOwner={isTaskOwner}
                  />
                </Grid>
              </Grid>
            </Card>
          )}
        </Paper>
      </Slide>
    );
  }
}

TaskDetail.propTypes = {
  taskid: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

export default TaskDetail;
