import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

export function getTasks(size, pNumber) {
  return http.get(`${apiEndpoint}/tasks`, {
    params: {
      pageSize: size,
      pageNumber: pNumber,
    },
  });
}

export function createTask(task) {
  return http.post(`${apiEndpoint}/tasks`, task);
}

export function getUserTasks(size, pNumber) {
  return http.get(`${apiEndpoint}/tasks/me`, {
    params: {
      pageSize: size,
      pageNumber: pNumber,
    },
  });
}

export function getUserOfferedTasks(size, pNumber) {
  return http.get(`${apiEndpoint}/tasks/myOfferedTasks`, {
    params: {
      pageSize: size,
      pageNumber: pNumber,
    },
  });
}

export function getUserAssignedTasks(size, pNumber) {
  return http.get(`${apiEndpoint}/tasks/myAssignedTasks`, {
    params: {
      pageSize: size,
      pageNumber: pNumber,
    },
  });
}

export async function getNotificationsBadge() {
  return http.get(`${apiEndpoint}/users/notificationsBadges`);
}

export function getTask(taskId) {
  return http.get(`${apiEndpoint}/tasks/${taskId}`);
}
