import React, { Component } from 'react';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PubSub from 'pubsub-js';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import Swal from 'sweetalert2';
import _ from 'lodash';
import NavBarMenuOptions from './NavBarMenuOptions';
import { ReactComponent as PlissNameText } from '../../assets/plissNameText.svg';
import { ReactComponent as IconLogIn } from '../home/IconLogin.svg';
import * as searchService from './services/searchService';
import * as userService from '../auth/services';
import NewTask from '../newTask/NewTask';
import ModalLogin from './modalLogin';
import Notifications from '../notifications/notifications';
import UserContext from '../../contexts/user-context';

class NavBar extends Component {
  state = {
    isOnMobile: false,
    photoUrl: '',
    showAuthMenu: false,
    anchorEl: null,
    notifications: [],
    searchValue: '',
    loginOpen: false,
  };

  componentDidMount() {
    let photoUrl = null;
    if (userService.getCurrentUser()) photoUrl = userService.getCurrentUser().avatar;
    this.setState({ photoUrl });
  }

  handleLoginOpen = () => {
    this.setState((prevState) => ({ loginOpen: !prevState.loginOpen }));
  };

  handleRedirect = (target) => {
    this.props.history.push(target);
  };

  routeChange = () => {
    const path = `/`;
    this.handleRedirect(path);
  };

  render() {
    const { user } = this.context;
    const isOnProfile = window.location.pathname === '/myaccount/profile';

    return (
      <div className="document-body">
        <AppBar position="fixed" className="nav-logged">
          <Toolbar className="pliss-logged-container zero-padding vertical-centered">
            <Link aria-label="Ir al home" href="/">
              <Grid item>
                <PlissNameText className="toolbar-pliss-txt" />
              </Grid>
            </Link>
            {user && (
              <NewTask
                user={user}
                handleNewTaskCreation={this.props.handleNewTaskCreation}
                history={this.props.history}
              />
            )}
            <Hidden xsDown>
              {user && (
                <Button
                  color="inherit"
                  className="camel-case t-button-p2"
                  onClick={() => {
                    this.handleRedirect('/tasks');
                  }}
                >
                  <AssignmentOutlinedIcon className="bar-btn-icons" />
                  Tareas
                </Button>
              )}
            </Hidden>
            <TextField
              className="search-top-input"
              id="filled-password-input"
              type="search"
              autoComplete="off"
              variant="filled"
              size="small"
              placeholder="Buscador de tareas..."
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment>
                    <Search className="search-bar-btn-icon" />
                  </InputAdornment>
                ),
              }}
              onChange={async (event) => {
                this.setState({ searchValue: event.target.value });
              }}
              onKeyDown={async (evnt) => {
                if (evnt.key === 'Enter') {
                  if (isOnProfile) {
                    this.props.history.push(`/tasks?search`);
                  }
                  const elms = await searchService.search(this.state.searchValue);
                  if (!_.isEmpty(elms.data)) {
                    PubSub.publish('SEARCH_TASKS', { tasks: elms.data });
                  } else {
                    Swal.fire({
                      toast: true,
                      position: 'center-right',
                      showConfirmButton: false,
                      timer: 3500,
                      timerProgressBar: true,
                      onOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                      },
                      icon: 'info',
                      title: 'Sin resultados, intenta con otras palabras ;)',
                    });
                  }
                }
              }}
            />
            <Hidden xsDown>
              {user && (
                <Notifications
                  user={user}
                  history={this.props.history}
                  updateView={this.props.updateView}
                />
              )}
            </Hidden>
            {user && (
              <div>
                <NavBarMenuOptions user={user} onHandleRedirect={this.handleRedirect} />
              </div>
            )}
            {!user && (
              <div>
                <Button
                  edge="end"
                  variant="contained"
                  color="secondary"
                  className="navbar-login-btn"
                  onClick={this.handleLoginOpen}
                >
                  <Hidden mdUp>
                    <IconLogIn className="app-icon-login" />
                  </Hidden>
                  <Hidden smDown>Ingresar</Hidden>
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <ModalLogin
          mustOpen={this.state.loginOpen}
          onClose={this.handleLoginOpen}
          {...this.props}
        />
      </div>
    );
  }
}

NavBar.contextType = UserContext;

export default NavBar;
