import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Notifications from '@material-ui/icons/NotificationsNoneOutlined';
import Badge from '@material-ui/core/Badge';
import * as service from '../../notifications/services';
import NotificationItems from '../../notifications/notificationItems';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileNotifications(props) {
  const classes = useStyles();
  const { getBadges } = props;
  const [notificationsBadge, setNotificationsBadge] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const history = useHistory();

  useEffect(() => {
    getUserNotificationsInfo();
    const interval = setInterval(() => {
      getUserNotificationsInfo();
    }, 90000);
    return () => clearInterval(interval);
  }, []);

  const viewNotification = async (taskId, pathNumber, notificatonState) => {
    if (notificatonState === 'Created') {
      await service.updateUserNotificationState(taskId);
    }
    const notPath = service.getPathByNumber(pathNumber);
    history.push(`${notPath}/${taskId}`);
    getUserNotificationsInfo();
  };

  const getUserNotificationsInfo = async () => {
    if (getBadges) {
      getBadges();
    }
    const userNotifications = await service.getUserNotifications();
    if (userNotifications.data) {
      setNotifications(userNotifications.data);
      const activeNotifications =
        userNotifications.data &&
        userNotifications.data.filter((notification) => notification.state === 'Created');
      setNotificationsBadge(activeNotifications && activeNotifications.length);
    } else {
      setNotifications([]);
      setNotificationsBadge(0);
    }
  };

  return (
    <div className="mobile-dialog-menu-item">
      <Badge color="secondary" badgeContent={notificationsBadge}>
        <Notifications onClick={props.onHandleClickOpen} />
      </Badge>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onHandleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={props.onHandleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Notificaciones
            </Typography>
          </Toolbar>
        </AppBar>
        <NotificationItems
          NotificationsList={notifications}
          viewNotification={viewNotification}
          isMobile
        />
      </Dialog>
    </div>
  );
}
