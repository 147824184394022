import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Box from '@material-ui/core/Box';
import CardButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import logger from '../common/services/logService';
import { getContactInformation } from './services';

const showContact = (value) => {
  try {
    Swal.fire({
      icon: 'info',
      title: 'Cargando Datos...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: async () => {
        Swal.showLoading();
        const { data } = await getContactInformation(value.userId);
        const reSwal = withReactContent(Swal);
        reSwal.fire({
          html: renderContactInfo(data),
          title: 'Información de Contacto',
          confirmButtonText: 'Aceptar',
          icon: 'info',
        });
      },
    });
  } catch (errors) {
    logger.log(errors, { component: 'ContactInfo' });
    Swal.fire({
      html: 'Ha ocurrido un error, intenta más tarde.',
      confirmButtonText: 'Aceptar',
      icon: 'error',
    });
  }
};

const renderContactInfo = (values) => {
  return (
    <Grid container item xs={12} className="contact-info">
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Box fontWeight="fontWeightMedium" fontSize={16}>
            Nombres:
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box fontWeight="fontWeightLight" fontSize={14}>
            {`${values.name} ${values.lastName}`}
          </Box>
        </Grid>
      </Grid>
      <Grid container item className="confirm-task">
        <Grid item xs={4}>
          <Box fontWeight="fontWeightMedium" fontSize={16}>
            Teléfono:
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box fontWeight="fontWeightLight" fontSize={14}>
            {values.phone}
          </Box>
        </Grid>
      </Grid>
      <Grid container item className="confirm-task">
        <Grid item xs={4}>
          <Box fontWeight="fontWeightMedium" fontSize={16}>
            Correo:
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box fontWeight="fontWeightLight" fontSize={14}>
            {values.email}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ContactInfo = (props) => {
  return (
    <CardButton
      size="small"
      variant="outlined"
      color="secondary"
      onClick={() => showContact(props)}
    >
      Datos de Contacto
    </CardButton>
  );
};

export default ContactInfo;
