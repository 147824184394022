import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
    width: '60%',
  },
  accordionTitle: {
    backgroundColor: '#e6e6e6',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const faqList = [
  {
    id: 1,
    ask: '¿Qué significa ser un solucionador?',
    askType: 'Roles',
    response: 'Es la persona encargada de atender y solucionar las necesidades de una tarea.',
  },
  {
    id: 2,
    ask: '¿Qué es ofertar?',
    askType: 'Actividad',
    response:
      'Se le llama ofertar a la acción de indicar por cuanto se podría ejecutar una tarea, este valor puede ser inferior, igual o superior al valor que se encuentra descrito.',
  },
  {
    id: 3,
    ask: 'Calificación',
    askType: 'Actividad',
    response:
      'Es la acción de calificar tanto a la persona que publica la tarea como al que la ejecuta.',
  },
  {
    id: 4,
    ask: 'Proceso de Pago',
    askType: 'Próximamente',
    response:
      'Actualmente no estamos realizando cobros por conectar las personas, próximamente se habilitarán los pagos a través de la plataforma con un aliado estratégico.',
  },
];

export default function FaqPage() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid
        container
        justify="center"
        style={{ marginTop: '90px' }}
        direction="column"
        alignItems="center"
      >
        <Paper className={classes.paper}>
          <Grid item>
            <Typography variant="h3" className="team-title-main" gutterBottom>
              <Box fontWeight="fontWeightBold">Preguntas Frecuentes</Box>
            </Typography>
          </Grid>
          {faqList?.map(function (faq) {
            return (
              <Accordion expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.accordionTitle}
                >
                  <Typography className={classes.heading}>{faq.ask}</Typography>
                  <Typography className={classes.secondaryHeading}>{faq.askType}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.response}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Paper>
      </Grid>
    </>
  );
}
