import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';
import Link from '@material-ui/core/Link';
import * as userService from '../auth/services';
import logger from '../common/services/logService';
import { logout } from '../auth/services';
import Loading from '../common/loading';
import { ReactComponent as PlissNameText } from '../../assets/plissNameText.svg';
import FirebaseContext from '../../contexts/firebase-services';
// eslint-disable-next-line no-unused-vars
import UserContext from '../../contexts/user-context';

export default class LoginForm extends Component {
  state = {
    errors: {},
    isLoading: false,
  };

  componentDidMount() {
    logout();
  }

  createSessionInAppServer = () => {
    const params = {};

    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        await userService.login(idToken);
        if (params.r) window.location.href = params.r;
        else window.location.href = '/tasks';
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.email = error.response.data.error;
          this.setState({ errors });
        }

        logger.log(error, { component: 'LoginForm' });
      });
  };

  initOAuthFlow = (provider) => {
    firebase.auth().useDeviceLanguage();
    const that = this;
    const { analytics } = this.context;

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async function (result) {
        that.setState({ isLoading: !that.state.isLoading });
        const { user } = result;
        that.createSessionInAppServer(user);
        analytics.logEvent('login', { method: 'google' });
      })
      .catch(function (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        logger.log(`Error Login Page: ${errorCode} - ${errorMessage}`, { component: 'LoginForm' });
        that.props.handleClose();

        if (errorCode === 'auth/account-exists-with-different-credential') {
          Swal.fire({
            icon: 'warning',
            text:
              'Creaste tu cuenta con otra red social, por favor inicia sesión con la correcta ;)',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#5078ff',
          });
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Lo sentimos, no fue posible iniciar sesión, intenta más tarde.',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#5078ff',
          });
        }
      });
  };

  render() {
    return (
      <div>
        <Paper className="login-paper" elevation={8}>
          <Grid item align="center">
            <PlissNameText className="pliss-logo-type-logon" />
          </Grid>

          <Grid item align="center">
            <br />
            <Typography variant="subtitle1" gutterBottom className="inline-block">
              Si{' '}
              <Typography className="inline-block">
                <Box fontWeight="fontWeightBold">ya tienes una cuenta </Box>
              </Typography>{' '}
              o{' '}
              <Typography className="inline-block">
                <Box fontWeight="fontWeightBold">deseas registrarte</Box>
              </Typography>
              , por favor selecciona una de las siguientes opciones:
              <br />
              <br />
            </Typography>
          </Grid>
          {/Opera|OPR|Chrome|Safari|Firefox/.test(navigator.userAgent) ? (
            <Grid item container direction="column" spacing={2} align="center">
              <Grid item xs={12} className="auth-btn-full-width">
                <Button
                  className="auth-btn-full-width social-icon"
                  variant="contained"
                  aria-label="add"
                  onClick={() => {
                    this.initOAuthFlow(new firebase.auth.GoogleAuthProvider());
                  }}
                >
                  <img
                    src="https://pliss-assets.s3.amazonaws.com/common/googleSignin.svg"
                    className="auth-icons-btn-padding"
                    alt="SignInGoogle..."
                  />
                  <Typography variant="body1" color="textPrimary" className="auth-btn-login-text">
                    Iniciar con Google
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} className="auth-btn-full-width">
                <Button
                  className="auth-btn-full-width social-icon"
                  variant="contained"
                  aria-label="add"
                  onClick={() => {
                    this.initOAuthFlow(new firebase.auth.FacebookAuthProvider());
                  }}
                >
                  <img
                    src="https://pliss-assets.s3.amazonaws.com/common/facebookSignin.svg"
                    className="auth-icons-btn-padding"
                    alt="SignInFacebook..."
                  />
                  <Typography variant="body1" color="textPrimary" className="auth-btn-login-text">
                    Iniciar con Facebook
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} className="auth-btn-full-width">
                <Button
                  className="auth-btn-full-width social-icon"
                  variant="contained"
                  aria-label="add"
                  onClick={() => {
                    this.initOAuthFlow(new firebase.auth.OAuthProvider('microsoft.com'));
                  }}
                >
                  <img
                    src="https://pliss-assets.s3.amazonaws.com/common/microsoftSignin.svg"
                    className="auth-icons-btn-padding"
                    alt="SignInMicrosoft..."
                  />
                  <Typography variant="body1" color="textPrimary" className="auth-btn-login-text">
                    Iniciar con Microsoft
                  </Typography>
                </Button>
              </Grid>
              <Typography variant="body2" align="center" className="auth-text">
                Al continuar, estás aceptando los
                <Link
                  href="https://pliss-assets.s3.amazonaws.com/policies/termsAndConditions.pdf"
                  target="_blank"
                  variant="body1"
                  className="login-text login-conditions"
                  color="textSecondary"
                >
                  <b> Términos y Condiciones de Uso </b>
                </Link>
                y las
                <Link
                  href="https://pliss-assets.s3.amazonaws.com/policies/policyInformation.pdf"
                  target="_blank"
                  variant="body1"
                  className="login-text login-conditions"
                  color="textSecondary"
                >
                  <b> Políticas de Privacidad.</b>
                </Link>
              </Typography>
              {this.state.isLoading ? <Loading /> : ''}
            </Grid>
          ) : (
            <Grid item align="center">
              <br />
              <Typography
                variant="subtitle1"
                gutterBottom
                className="inline-block"
                display="inline"
              >
                <Typography display="inline" className="inline-block">
                  <Box fontWeight="fontWeightBold">
                    Lo sentimos, este navegador no es compatible con nuestros componentes, para una
                    mejor experiencia debes utilizar:{' '}
                  </Box>
                </Typography>
                <Typography display="inline" className="inline-block">
                  <Box fontWeight="fontWeightBold">
                    Chrome, Safari, Firefox, Opera o Edge-Chromium.
                  </Box>
                </Typography>

                <br />
              </Typography>
            </Grid>
          )}
        </Paper>
      </div>
    );
  }
}
LoginForm.contextType = FirebaseContext;
