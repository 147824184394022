import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { Typography } from '@material-ui/core';

const NotFound = () => {
  return (
    <Grid className="page-not-found">
      <Grid
        container
        className="not-found-box"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item style={{ textAlign: 'center' }}>
          <svg
            className="sad-pliss"
            xmlns="http://www.w3.org/2000/svg"
            fill="#5078FF"
            stroke="#5078FF"
          >
            <g className="sad-pliss-group">
              <path
                className="not-found-path"
                d="M137.7,91.6c1.6-1.5,2.4-3.7,2.3-6.1c-0.1-3.1-1.7-6.3-4.4-8.5C97.1,44.5,43.2,44.5,4.6,77
	c-2.7,2.3-4.3,5.3-4.4,8.4c0,0.1,0,0.2,0,0.3c0,2.3,0.9,4.4,2.4,5.9c3.6,3.5,9.4,3.3,13.8-0.5C32.1,78,50.7,71,70.2,71
	c19.5,0,38,7,53.6,20.2C128.1,94.9,134.1,95,137.7,91.6z"
              />
              <path
                className="not-found-path"
                d="M14,17.3c0,5.9,4.7,10.3,10.9,10.3h6c6.2,0,10.9-4.4,10.9-10.3c0-6-4.7-10.5-10.9-10.5h-6
	C18.6,6.8,14,11.2,14,17.3z"
              />
              <path
                className="not-found-path"
                d="M123.1,28.7c-1.6,2.8-5.3,3.5-8.5,1.6l-13.4-7.9l-12.7,7.5c-3.1,1.8-6.9,1.1-8.5-1.6c-1.7-2.8-0.5-6.4,2.7-8.3
	l7.3-4.3l-7-4.1c-3.1-1.8-4.3-5.5-2.6-8.3c1.6-2.7,5.4-3.4,8.5-1.6l12.4,7.3l13-7.7c3.2-1.9,6.8-1.2,8.5,1.6
	c1.6,2.8,0.5,6.4-2.7,8.3l-7.6,4.5l8,4.7C123.6,22.3,124.8,25.9,123.1,28.7z"
              />
            </g>
          </svg>
        </Grid>
        <Grid item>
          <Typography style={{ color: '#25d366' }} variant="h2">
            <Box fontWeight="fontWeightBold">Error 404</Box>
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: '10px' }}>
          <Typography variant="h4" className="logged-nav-icon-btn" style={{ textAlign: 'center' }}>
            Lo sentimos la pagina que buscas no existe...
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: '20px' }}>
          <Typography
            variant="h6"
            style={{ color: '#25d366', cursor: 'pointer' }}
            onClick={() => window.history.back()}
            align="center"
          >
            Haz clic aca para regresar a la página anterior!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFound;
