import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginForm from '../loginForm/LoginForm';
import { getCurrentUser } from './services';

function TabContainer(props) {
  const { children } = props;
  return <Typography component="div">{children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Auth extends Component {
  state = {};

  render() {
    if (getCurrentUser()) return <Redirect to="/tasks" />;

    return (
      <Grid container justify="center" className="login-principal">
        <Grid
          item
          container
          spacing={3}
          sm={10}
          xs={12}
          md={5}
          lg={5}
          className="auth-form-container"
        >
          <LoginForm {...this.props} />
        </Grid>
      </Grid>
    );
  }
}

export default Auth;
