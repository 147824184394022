import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import QualificationForm from '../qualificationForm/QualificationForm';

export default function Budget(props) {
  const {
    quantity,
    isTaskOwner,
    taskState,
    isOfferOwner,
    onHandleOffer,
    onCompleteTask,
    onHandleRejection,
    onHandleCancellation,
    onRefreshTaskInfo,
    userId,
    task,
    assignedOffer,
  } = props;

  return (
    <Grid
      container
      className="budget-container"
      alignContent="center"
      alignItems="center"
      justify="center"
      direction="column"
    >
      <Grid item className="budget-elm-title-c">
        <Typography className="budget-elm-title">Presupuesto</Typography>
      </Grid>
      <Grid item className="budget-elm-value-c">
        <Typography className="budget-elm-value">
          <NumberFormat
            value={quantity}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
          />
        </Typography>
      </Grid>
      <Grid item className="offer-button-c">
        {!isTaskOwner && taskState === 'Created' && (
          <Button
            variant="contained"
            size="large"
            // color="#5078FF"
            className="camel-case t-button-p"
            onClick={onHandleOffer}
          >
            Ofertar
          </Button>
        )}

        {isOfferOwner && (taskState === 'Assigned' || taskState === 'ReAsigned') && (
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className="camel-case t-button-p"
            onClick={onCompleteTask}
          >
            Completar Tarea
          </Button>
        )}
        {((isTaskOwner && taskState === 'Executed') ||
          (isOfferOwner && taskState === 'Verified')) && (
          <QualificationForm
            authorId={userId}
            task={task}
            assignedOffer={assignedOffer}
            refreshTaskInfo={onRefreshTaskInfo}
            isTaskOwner={isTaskOwner}
            isOfferOwner={isOfferOwner}
          />
        )}
      </Grid>

      {isTaskOwner && taskState === 'Executed' && (
        <Button
          className="btn-closeTask camel-case t-button-p1"
          variant="contained"
          size="small"
          onClick={onHandleRejection}
        >
          Devolver Tarea
        </Button>
      )}

      {isTaskOwner &&
        (taskState === 'Created' || taskState === 'Assigned') &&
        taskState !== 'Cancelled' && (
          <Button
            className="btn-closeTask camel-case t-button-p1"
            variant="contained"
            // disabled='true'
            color="secondary"
            onClick={onHandleCancellation}
          >
            Cancelar
          </Button>
        )}
    </Grid>
  );
}

Budget.propTypes = {
  quantity: PropTypes.number.isRequired,
  isTaskOwner: PropTypes.bool.isRequired,
  taskState: PropTypes.string.isRequired,
  onHandleOffer: PropTypes.func.isRequired,
  isOfferOwner: PropTypes.bool.isRequired,
  onCompleteTask: PropTypes.func.isRequired,
  onHandleRejection: PropTypes.func.isRequired,
  onHandleCancellation: PropTypes.func.isRequired,
  onRefreshTaskInfo: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  task: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  assignedOffer: PropTypes.object.isRequired,
};
