import { Component } from 'react';

class Footer extends Component {
  state = {};

  render() {
    return null;
  }
}

export default Footer;
