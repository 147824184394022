import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PubSub from 'pubsub-js';
import Alert from '@material-ui/lab/Alert';
import * as pService from '../browse/services';
import { appPaths } from '../../config.json';
import Task from '../task/Task';

const PreviewTasks = (props) => {
  const [previewTasksLoaded, setPreviewTasksLoaded] = React.useState(false);
  const [pageSize] = React.useState(9);
  const [pageNumber, setPageNumber] = React.useState(1);
  const { onDetail, user, displayBadge, taskSelected, basePath, pushTask, updateView } = props;
  const myTasksBadge = user ? user.myTasksBadge : [];
  const myAssignedTasksBadge = user ? user.myAssignedTasksBadge : [];
  const mQueriesValues = { xs: 12, sm: 12, md: 12 };
  const [tasksList, setTasksList] = React.useState([]);

  const PATHS = appPaths;

  const searchTasksEvent = (msg, data) => {
    setTasksList(data.tasks);
    if (data.tasks && data.tasks.length > 0) {
      updateView(data.tasks[0]._id);
    }
  };

  PubSub.clearAllSubscriptions();
  PubSub.subscribe('SEARCH_TASKS', searchTasksEvent);

  const scrollHandler = (event) => {
    const { scrollHeight, clientHeight, scrollTop } = event.target;
    const scrollPosition = clientHeight + scrollTop;
    const screenScroll = scrollHeight;
    if (scrollPosition >= screenScroll) {
      setPageNumber(pageNumber + 1);
    }
  };

  const loadAll = async () => {
    const tasks = await pService.getTasks(pageSize, pageNumber);
    if (tasks.data && tasks.data.length > 0) {
      updateTasksPreview(tasks.data);
    }
  };

  const loadMyTasks = async () => {
    const tasks = await pService.getUserTasks(pageSize, pageNumber);
    if (tasks.data && tasks.data.length > 0) {
      updateTasksPreview(tasks.data);
    }
  };

  const loadMyOffers = async () => {
    const tasks = await pService.getUserOfferedTasks(pageSize, pageNumber);
    if (tasks.data && tasks.data.length > 0) {
      updateTasksPreview(tasks.data);
    }
  };

  const loadMyAssignments = async () => {
    const tasks = await pService.getUserAssignedTasks(pageSize, pageNumber);
    if (tasks.data && tasks.data.length > 0) {
      updateTasksPreview(tasks.data);
    }
  };

  const getPathTasks = async () => {
    switch (basePath) {
      case PATHS.TASKS:
        await loadAll();
        break;
      case PATHS.MY_TASKS:
        await loadMyTasks();
        break;
      case PATHS.MY_OFFERS:
        await loadMyOffers();
        break;
      case PATHS.MY_ASSIGNMENTS:
        await loadMyAssignments();
        break;
      default:
        await loadAll();
        break;
    }
    setPreviewTasksLoaded(true);
  };

  const updateTasksPreview = async (tasks) => {
    const newList = [...tasksList, ...tasks];
    if (taskSelected === 0 && newList[0]) {
      updateView(newList[0]._id);
    } else {
      const found = newList.findIndex((element) => element._id === taskSelected);
      if (found > -1) {
        newList.splice(found, 1);
      }
      const task = await pService.getTask(taskSelected);
      if (task.data) {
        newList.unshift(task.data);
      } else {
        onDetail(newList[0]._id);
      }
    }
    setTasksList(newList);
  };

  useEffect(() => {
    if (pushTask) updateTasksPreview([]);
  }, [pushTask]);

  useEffect(() => {
    if (!window.location.href.includes('search')) {
      getPathTasks();
    }
    window.addEventListener('scroll', scrollHandler, true);
    return () => window.removeEventListener('scroll', scrollHandler, true);
  }, [basePath, pageNumber]);

  return (
    <Grid
      container
      wrap="wrap"
      direction="column"
      className="prev-tasks-container"
      onScroll={scrollHandler}
    >
      {previewTasksLoaded && tasksList && tasksList.length === 0 && basePath !== '/tasks' && (
        <Alert severity="warning">No hay tareas para mostrarte en esta vista.</Alert>
      )}

      {tasksList &&
        tasksList.length > 0 &&
        tasksList.map((task) => (
          <Task
            task={task}
            displayBadge={displayBadge}
            myTasksBadge={myTasksBadge}
            myAssignedTasksBadge={myAssignedTasksBadge}
            taskSelected={taskSelected}
            onDetail={() => {
              onDetail(task._id);
            }}
            key={task._id}
            delay={40}
            mQueriesValues={mQueriesValues}
          />
        ))}
    </Grid>
  );
};

export default PreviewTasks;
