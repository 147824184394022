import React from 'react';
import Joi from 'joi-browser';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import Swal from 'sweetalert2';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import postTaskMessage from './services';
import Message from '../message/Message';
import GenericReply from '../genericReply/genericReply';
import Form from '../common/form';
import logger from '../common/services/logService';

class Messages extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: { text: '' },
      errors: {},
    };
  }

  schema = {
    text: Joi.string().required().label('Mensaje'),
  };

  handleMessage = async (messageText) => {
    try {
      const { taskId } = this.props;
      await postTaskMessage(taskId, messageText);
      await this.props.refreshTaskInfo(taskId);
    } catch (error) {
      logger.log(error, { component: 'Messages' });
      Swal.fire({
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
        icon: 'error',
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.taskId !== prevProps.taskId) {
      this.setState({ data: { text: '' } });
    }
  }

  render() {
    const { isTaskOwner, messages, taskId, user, isTaskInfoEditable } = this.props;
    const sorted = _.sortBy(messages, 'created').reverse();

    return (
      <>
        <Grid container direction="column">
          <Grid container item sm={12} className="title-container">
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">PREGUNTAS Y MENSAJES </Typography>
              {sorted.length === 0 && (
                <Typography variant="caption" style={{ color: 'grey' }}>
                  Aún no hay Preguntas.
                </Typography>
              )}

              {user && !user.isRegistered && isTaskInfoEditable && (
                <Alert severity="warning">
                  <Link to="/myaccount/profile/">
                    Has click aquí y completa tu perfil para enviar mensajes!
                  </Link>
                </Alert>
              )}

              {!user && (
                <Alert severity="warning">
                  <Link to="/myaccount/profile/">Inicia sesión para poder enviar mensajes!</Link>
                </Alert>
              )}
            </Grid>
          </Grid>

          {user && user.isRegistered && isTaskInfoEditable && !isTaskOwner && (
            <List>
              <GenericReply
                user={user}
                saveAction={(text) => this.handleMessage(text)}
                successMessage="Guardado exitosamente!"
                label="Nueva pregunta o comentario"
                autoFocus={false}
              />
            </List>
          )}

          {messages.length === 0 && (
            <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
              <FontAwesomeIcon
                className="app-icon-how-works"
                icon={faInbox}
                color="gray"
                size="2x"
              />
            </Grid>
          )}

          <List>
            {sorted.map((message) => (
              <Message
                isTaskInfoEditable={isTaskInfoEditable}
                isTaskOwner={isTaskOwner}
                key={message._id}
                message={message}
                taskId={taskId}
                user={user}
                userId={user && user._id}
                refreshTaskInfo={this.props.refreshTaskInfo}
              />
            ))}
          </List>
        </Grid>
      </>
    );
  }
}

export default Messages;
