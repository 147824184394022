import React from 'react';
import Joi from 'joi-browser';
import Swal from 'sweetalert2';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import Form from '../common/form';
import logger from '../common/services/logService';

class GenericReply extends Form {
  state = {
    data: { text: '' },
    errors: {},
  };

  schema = {
    text: Joi.string().required(),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      this.props.saveAction(data.text);
      this.setState({ data: { text: '' } });
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: this.props.successMessage,
      });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = err.response.data.error;
        this.setState({ errors });
      }

      logger.log(err, { component: 'GenericReply' });
    }
  };

  render() {
    const { user, label, autoFocus } = this.props;

    return (
      <ListItem className="generic-message-style">
        <Hidden xsDown>
          <ListItemAvatar>
            <Avatar
              component={Link}
              to="/PublicProfile/"
              className="small-avatar"
              src={user.avatar}
            />
          </ListItemAvatar>
        </Hidden>
        <ListItemText
          primary={this.renderSingleLineMultilineInput(
            'text',
            label,
            'textField-full',
            null,
            null,
            autoFocus,
          )}
        />
        <ListItemSecondaryAction>{this.renderSaveIcon()}</ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default GenericReply;
