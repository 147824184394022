/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Fab from '@material-ui/core/Fab';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import logger from '../common/services/logService';
import * as pService from '../profile/services';
import DatePicker from '../common/DatePicker';
import NumberFormat from './NumberFormat';
import * as bService from '../browse/services';
import taskStepInfoMod from './TasksStepsInfo';
import FirebaseContext from '../../contexts/firebase-services';

const reSwal = withReactContent(Swal);
let formOnSite = '';
const TYPE_REMOTE = 'remoto';
const TYPE_ONSITE = 'presencial';
class NewTask extends Component {
  state = {
    goToProfile: false,
    swalCustomErrorValidation: false,
    date: new Date(),
    address: '',
    taskType: 'presencial',
  };

  radioChangeHandler = (event) => {
    this.setState({
      taskType: event.target.value,
    });
    const element = document.getElementById('form-onSite-mayor');

    if (event.target.value === TYPE_ONSITE) {
      element.appendChild(formOnSite);
    } else {
      formOnSite = document.getElementById('form-onSite');
      element.removeChild(formOnSite);

      const errPre = document.getElementById('swal2-validation-message');
      if (errPre !== '') {
        errPre.style.display = 'none';
      }
    }
  };

  getAddressString = (address, addressComplementary, addressNeighborhood, city) => {
    let stringAdress = '';

    if (this.state.taskType === TYPE_REMOTE) {
      stringAdress = `No requiere desplazamiento.`;
    } else {
      if (address) stringAdress = `${address} `;
      if (addressComplementary) stringAdress += `${addressComplementary} `;
      if (addressNeighborhood) stringAdress += `${addressNeighborhood} `;
      if (city) stringAdress += `${city} `;
    }

    return stringAdress;
  };

  getAddressDefaultValue = (values, pos, profileAddress) => {
    if (this.state.taskType === TYPE_REMOTE) return profileAddress;
    if (values[2] && values[2][pos]) return values[2][pos];
    return profileAddress;
  };

  getAddressTypeValue = (values) => {
    if (values && values[2] === TYPE_REMOTE) {
      const event = { target: { value: TYPE_REMOTE } };
      setTimeout(() => {
        this.radioChangeHandler(event);
      }, 300);

      return TYPE_REMOTE;
    }
    return TYPE_ONSITE;
  };

  renderAddressComponent = async (values) => {
    const { profile } = this.state;

    return (
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Typography variant="h5">Selecciona el tipo de tarea:</Typography>
        <RadioGroup
          aria-label="task_type"
          name="task_type"
          className="display-inline"
          onChange={this.radioChangeHandler}
          defaultValue={this.getAddressTypeValue(values)}
        >
          <FormControlLabel
            value={TYPE_ONSITE}
            control={<Radio color="primary" />}
            label="Presencial"
          />
          <FormControlLabel
            value={TYPE_REMOTE}
            control={<Radio color="primary" />}
            label="Remoto"
          />
        </RadioGroup>

        <Grid
          container
          id="form-onSite-mayor"
          className="input-full-width"
          style={{ marginBottom: '1em' }}
          justify="center"
        >
          <Grid
            container
            id="form-onSite"
            className="input-full-width"
            spacing={2}
            justify="center"
          >
            <Grid item xs={12} className="input-full-width">
              <TextField
                id="places-autocomplete"
                label="Dirección*"
                defaultValue={this.getAddressDefaultValue(values, 0, profile.data.address)}
                variant="outlined"
                autoComplete="off"
                className="input-full-width"
              />
            </Grid>

            <Grid item xs={12} className="input-full-width">
              <TextField
                id="places-address-line-two"
                label="Apto / Torre / Bloque (Opcional)"
                defaultValue={this.getAddressDefaultValue(
                  values,
                  1,
                  profile.data.addressComplementary,
                )}
                variant="outlined"
                autoComplete="off"
                className="input-full-width"
              />
            </Grid>
            <Grid item xs={12} className="input-full-width">
              <TextField
                id="places-neighborhood"
                label="Indica el Barrio*"
                defaultValue={this.getAddressDefaultValue(
                  values,
                  2,
                  profile.data.addressNeighborhood,
                )}
                variant="outlined"
                autoComplete="off"
                className="input-full-width"
              />
            </Grid>
            <Grid item xs={12} className="input-full-width">
              <TextField
                id="places-city-address"
                label="Ciudad*"
                defaultValue={this.getAddressDefaultValue(values, 3, profile.data.cityAddress)}
                variant="outlined"
                autoComplete="off"
                className="input-full-width"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderNumberAndDateComp = (values) => {
    let taskDate = new Date();
    let numberValue = '';
    try {
      if (values[3][0]) {
        const dateSplitted = values[3][0].split('/');
        const dateFormatted = `${dateSplitted[1]} / ${dateSplitted[0]} / ${dateSplitted[2]}`;
        taskDate = new Date(dateFormatted);
      }
      if (values[3][1]) {
        numberValue = values[3][1];
      }
    } catch (error) {
      logger.log(error, { component: 'NewTask' });
    }

    return (
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ textAlign: 'center' }}
            display="inline"
          >
            Indique la{' '}
            <Typography className="inline-block">
              <Box fontWeight="fontWeightBold">fecha </Box>
            </Typography>
            y el{' '}
            <Typography className="inline-block">
              <Box fontWeight="fontWeightBold">presupuesto </Box>
            </Typography>{' '}
            para la tarea:
          </Typography>
        </Grid>
        <Grid item xs={7} md={4} lg={4} style={{ margintop: '10px', textAlignLast: 'right' }}>
          <DatePicker value={taskDate} disablePast id="new-date-picker-dialog" />
        </Grid>
        <Grid item xs={7} md={4} lg={4} style={{ margintop: '10px', textAlignLast: 'right' }}>
          <NumberFormat id="formatted-numberformat-input" value={numberValue || ''} />
        </Grid>
      </Grid>
    );
  };

  renderConfirmNewTask = (values) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });

    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid container item xs={12} style={{ textAlign: 'left' }}>
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={4}>
                  <Box fontWeight="fontWeightMedium" fontSize={16}>
                    Titulo:
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    {values[0]}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" className="confirm-task">
                <Grid item xs={4}>
                  <Box fontWeight="fontWeightMedium" fontSize={16}>
                    Descripción:
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    {values[1].substring(0, 180)}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" className="confirm-task">
                <Grid item xs={4}>
                  <Box fontWeight="fontWeightMedium" fontSize={16}>
                    Dirección:
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    {this.getAddressString(
                      values[2][0],
                      values[2][1],
                      values[2][2],
                      values[2][3],
                      values[2][4],
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" className="confirm-task">
                <Grid item xs={4}>
                  <Box fontWeight="fontWeightMedium" fontSize={16}>
                    Presupuesto:
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    {formatter.format(values[3][1])}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" className="confirm-task">
                <Grid item xs={4}>
                  <Box fontWeight="fontWeightMedium" fontSize={16}>
                    Fecha de Ejecución:
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box fontWeight="fontWeightLight" fontSize={14}>
                    {values[3][0]}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  createTaskInServer = async (values) => {
    try {
      const tempDate = values[3][0].split('/');
      const dateFormatted = `${tempDate[1]}/${tempDate[0]}/${tempDate[2]}`;
      const newTask = {
        title: values[0],
        description: values[1],
        budget: parseFloat(values[3][1].replace(/,/g, '')),
        userId: this.props.user._id,
        dueDate: dateFormatted,
      };

      if (values[2] !== TYPE_REMOTE) {
        newTask.address = values[2][0];
        if (values[2][1] !== '') newTask.addressComplementary = values[2][1];
        newTask.addressNeighborhood = values[2][2];
        newTask.cityAddress = values[2][3];
      } else {
        newTask.isRemote = true;
      }

      const task = await bService.createTask(newTask);
      const { analytics } = this.context;
      analytics.logEvent('event', 'task_created');
      const taskData = task.data;

      Swal.fire({
        icon: 'success',
        title: 'Tarea creada exitosamente',
        text:
          'Buscaremos las personas más calificadas para tu tarea, próximamente empezarás a recibir ofertas.',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#5078ff',
      });
      this.props.history.push(`/myaccount/my-tasks/${taskData._id}`);
    } catch (error) {
      Swal.fire('La tarea no se ha creado!', `Inténtalo más tarde ${error.message}`, 'error');
      logger.log(error, { component: 'NewTask' });
    }
  };

  fireQueueStep = async (currentStep, that, swalQueueStep, stepInfo, values, htmlComponents) => {
    return swalQueueStep.fire({
      title: stepInfo.title,
      input: stepInfo.input,
      text: stepInfo.text,
      // eslint-disable-next-line no-await-in-loop
      html: stepInfo.html ? await htmlComponents[stepInfo.html](values) : '',
      inputAttributes: stepInfo.inputAttributes,
      confirmButtonText: stepInfo.confirmButtonText,
      inputValidator: stepInfo.inputValidator,
      // eslint-disable-next-line no-await-in-loop
      preConfirm: stepInfo.preConfirm
        ? () => {
            return stepInfo.preConfirm(that, TYPE_REMOTE);
          }
        : null,
      inputValue: values[currentStep] ? values[currentStep] : '',
      showCancelButton: currentStep > 0,
      currentProgressStep: currentStep,
    });
  };

  getMixinConfiguration = () => {
    return reSwal.mixin({
      confirmButtonText: 'Siguiente &rarr;',
      confirmButtonColor: '#5078ff',
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: '&larr; Atrás',
      progressSteps: ['1', '2', '3', '4', '5'],
      allowOutsideClick: false,
      reverseButtons: true,
      inputAttributes: {
        required: true,
      },
      customClass: 'modal-add-task',
      validationMessage: 'Este campo es requerido',
    });
  };

  handleNewTask = async () => {
    const steps = ['1', '2', '3', '4', '5'];
    const profile = await pService.getProfile();
    const that = this;

    that.setState({
      swalCustomErrorValidation: false,
      taskType: '',
      profile,
    });

    if (profile.data.identification && profile.data.phone) {
      const swalQueueStep = this.getMixinConfiguration();

      let currentStep;
      const values = [];
      const htmlComponents = {
        renderAddressComponent: this.renderAddressComponent,
        renderNumberAndDateComp: this.renderNumberAndDateComp,
        renderConfirmNewTask: this.renderConfirmNewTask,
      };

      for (currentStep = 0; currentStep < steps.length; ) {
        const stepInfo = taskStepInfoMod.getTasksInfoInStep(currentStep);
        // eslint-disable-next-line no-await-in-loop
        const result = await this.fireQueueStep(
          currentStep,
          that,
          swalQueueStep,
          stepInfo,
          values,
          htmlComponents,
        );

        if (result.value) {
          values[currentStep] = result.value;
          currentStep += 1;
        } else if (result.dismiss === 'cancel') {
          currentStep -= 1;
        } else {
          break;
        }
      }

      if (currentStep === steps.length) {
        this.createTaskInServer(values);
      }
    } else {
      Swal.fire({
        title: 'Oops...',
        text: 'Por favor diligencia primero tus datos antes de publicar una solicitud!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#5078ff',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
      }).then(() => this.setState((prevState) => ({ goToProfile: !prevState.goToProfile })));
    }
  };

  render() {
    if (this.state.goToProfile) {
      this.setState({ goToProfile: false });
      return <Redirect push to="/myaccount/profile/" />;
    }

    return (
      <>
        {!this.props.onBar && (
          <Hidden xsDown>
            <Fab
              variant="extended"
              size="large"
              color="secondary"
              aria-label="Add"
              onClick={this.handleNewTask}
              className="btn-new camel-case"
            >
              Nueva Tarea
            </Fab>
          </Hidden>
        )}
        {this.props.onBar && (
          <Grid>
            <Grid item>
              <IconButton className="mobile-curve" onClick={this.handleNewTask}>
                <AddCircleIcon className="new-task-mobile-button" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default NewTask;

NewTask.contextType = FirebaseContext;
