import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import PlissRating from '../plissRating/PlissRating';

export default function PublicProfileCard(props) {
  return (
    <Grid item xs={12} md={6} className="profile-card">
      <Paper className="card-review">
        <div className="rel-container">
          <div className="profile-card-header">
            <div className="card-layout">
              <Avatar className="pp-user-avatar" src={props.cardInfo.authorId.avatar} />
              <Chip className="pp-user-chip" label={props.cardInfo.authorId.name} />
            </div>
          </div>
        </div>

        <div className="pp-detail-card">
          <Typography className="pp-card-task-title">
            <Box fontWeight="fontWeightBold" className="inline-block">
              &quot;{props.cardInfo.taskId.title}...&quot;,
            </Box>
            <PlissRating
              classProp="pp-rating"
              size="small"
              initialRating={props.cardInfo.rating}
              readOnly
            />
          </Typography>
          <Typography className="pp-card-task-city">Medellín, Antioquia.</Typography>

          <Typography className="pp-description">{props.cardInfo.comment}</Typography>
        </div>
      </Paper>
    </Grid>
  );
}
