import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function AsyncMultipleSelect(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const { data, limitTags, onHandleMultiValueChange, name, title, placeHolder, asyncFunc } = props;
  const [dataWithTitles, setDataWithTitles] = React.useState([]);
  const [key, setKey] = React.useState(1);

  React.useEffect(() => {
    const dataWT = data.map((elem) => {
      return { title: elem };
    });
    setDataWithTitles(dataWT);
    setKey(Math.random(10));
  }, [props.data]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await asyncFunc();
      const optionsData = response.data;

      if (active) {
        const opts = optionsData.map((option) => {
          const firstLetter = option.title[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
          };
        });
        setOptions(opts);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, asyncFunc]);

  return (
    <Autocomplete
      key={key}
      multiple
      limitTags={limitTags}
      open={open}
      loading={loading}
      noOptionsText="No hay resultados"
      loadingText={`Cargando ${placeHolder}...`}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      defaultValue={dataWithTitles}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.title}
      filterSelectedOptions
      onChange={(event, newValue) => {
        onHandleMultiValueChange(newValue, name);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          placeholder={placeHolder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

AsyncMultipleSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  limitTags: PropTypes.number.isRequired,
  onHandleMultiValueChange: PropTypes.func.isRequired,
  name: PropTypes.number.isRequired.isRequired,
  title: PropTypes.number.isRequired.isRequired,
  placeHolder: PropTypes.number.isRequired.isRequired,
  asyncFunc: PropTypes.number.isRequired.isRequired,
};
