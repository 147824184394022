import React from 'react';
import Grid from '@material-ui/core/Grid';
import GeneralTabs from '../general-tabs/GeneralTabs';

export default function TaskGeneral(props) {
  return (
    <>
      <Grid container justify="center">
        <Grid container className="general-tabs pliss-logged-container">
          <GeneralTabs {...props} />
        </Grid>
      </Grid>
    </>
  );
}
