import React from 'react';
import { Route } from 'react-router-dom';
import { getCurrentUser } from '../auth/services';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getCurrentUser()) {
          window.location = `/?r=${props.location.pathname}`;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
