import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PlissRating from '../plissRating/PlissRating';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [rating, setRating] = React.useState(0);

  useEffect(() => {
    setRating(props.bossRating);
  }, [props.bossRating, props.workerRating]);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setRating(props.bossRating);
    } else {
      setRating(props.workerRating);
    }

    props.onHandleTabChange(newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs">
          <Tab label="Publicador" {...a11yProps(0)} />
          <Tab label="Solucionador" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Grid container justify="center">
          <Grid item container justify="center">
            <PlissRating classProp="main-rating" size="small" initialRating={rating} readOnly />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container justify="center">
          <Grid item container justify="center">
            <PlissRating classProp="main-rating" size="small" initialRating={rating} readOnly />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}
