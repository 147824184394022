import 'date-fns';
import React from 'react';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

export default function DatePickerInput(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  React.useEffect(() => {
    setSelectedDate(props.value);
  }, [props.value]);

  function handleDateChange(date) {
    const { onHandleChange } = props;
    if (onHandleChange) onHandleChange(date);
    else setSelectedDate(date);
  }

  const { disablePast, name, label, id } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker
        disablePast={disablePast}
        name={name}
        label={label}
        autoOk
        inputVariant="outlined"
        id={id}
        format="d/M/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
        cancelLabel="Cancelar"
        okLabel="Aceptar"
        showTodayButton
        todayLabel="Hoy"
        invalidDateMessage="Fecha Incorrecta"
        minDateMessage="Fecha Incorrecta"
        maxDateMessage="Fecha Incorrecta"
        maxDate={new Date().setDate(new Date().getDate() + 180)}
      />
    </MuiPickersUtilsProvider>
  );
}
