import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import PubSub from 'pubsub-js';
import { ReactComponent as IconLogIn } from './IconLogin.svg';
import NavBarMenuOptions from '../common/NavBarMenuOptions';
import HowitworksVideo from './howitworksVideo';
import { ReactComponent as PlissNameText } from '../../assets/plissNameText.svg';
import { getCurrentUser } from '../auth/services';

export default function LandingNavBar() {
  const isLoggedUser = () => {
    if (getCurrentUser()) {
      return true;
    }
    return false;
  };

  const emitModalLoginEvent = (target) => {
    PubSub.publish('CHANGE_MODAL_LOGIN_STATE', target);
  };

  return (
    <AppBar className="appbar-home">
      <Toolbar className="appbar-sub-content">
        <Grid
          container
          direction="row"
          alignItems="center"
          className="responsive-bar"
          alignContent="center"
          justify="center"
        >
          <Grid
            item
            xs={4}
            sm={4}
            md={5}
            align="left"
            className="toolbar-pliss-txt toolbar-non-logged"
          >
            <Link aria-label="Ir al home" color="inherit" href="/">
              <PlissNameText className="toolbar-pliss-txt" />
            </Link>
          </Grid>
          <Grid item container xs={3} sm={3} md={4} lg={4} align="right">
            <Grid item xs={6} sm={6} md={4} align="right" className="responsive-bar-first-btn">
              <Link
                aria-label="Ir a tareas"
                color="inherit"
                style={{ textDecoration: 'none', marginLeft: 'auto' }}
                href="/tasks"
              >
                <MenuItem className="appbar-item bar-how-works-item">
                  <AssignmentOutlinedIcon className="app-icon-how-works" />
                  <Hidden mdDown>
                    <p className="appbar-icon-detail">Tareas</p>
                  </Hidden>
                </MenuItem>
              </Link>
            </Grid>
            <Grid item xs={6} sm={6} md={6} align="right">
              <HowitworksVideo />
            </Grid>
          </Grid>
          {!isLoggedUser() ? (
            <Grid container justify="center" item xs={2} sm={2} md={2} lg={1} align="right">
              <Button
                aria-label="Iniciar Sesión"
                edge="end"
                color="secondary"
                className="appbar-login-btn"
                onClick={(target) => emitModalLoginEvent(target)}
              >
                <Hidden mdUp>
                  {' '}
                  <IconLogIn className="app-icon-login" />
                </Hidden>
                <Hidden smDown>Ingresar</Hidden>
              </Button>
            </Grid>
          ) : (
            <Grid container justify="center" item xs={2} sm={2} md={1} align="right">
              <NavBarMenuOptions
                user={getCurrentUser()}
                onHandleRedirect={(target) => emitModalLoginEvent(target)}
              />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
