import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

export function acceptOffer(taskId, offerId) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/approvals/${offerId}/acceptOffer`);
}

export function createOfferMessage(taskId, offerId, text) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/offers/${offerId}/newMessage`, { text });
}

export function createOfferMessageReply(taskId, offerId, messageId, text) {
  return http.post(`${apiEndpoint}/tasks/${taskId}/offers/${offerId}/newMessageReply`, {
    messageId,
    text,
  });
}

export function deletetOffer(taskId, offerId) {
  return http.delete(`${apiEndpoint}/tasks/${taskId}/offers/${offerId}`);
}

export function updateOfferDescription(taskId, editedOffer) {
  return http.put(
    `${apiEndpoint}/tasks/${taskId}/offers/${editedOffer.id}/updateOfferDescription`,
    editedOffer,
  );
}
