import Joi from 'joi-browser';

export default {
  schema: {
    name: Joi.string()
      .required()
      .label('Nombre')
      .error(() => {
        return {
          message: 'Campo Requerido',
        };
      }),

    lastName: Joi.string()
      .required()
      .label('Apellidos')
      .error(() => {
        return {
          message: 'Campo Requerido',
        };
      }),

    sex: Joi.string()
      .required()
      .label('Genero')
      .error(() => {
        return {
          message: 'Campo Requerido',
        };
      }),

    birthdate: Joi.date()
      .label('Fecha Nacimiento')
      .error(() => {
        return {
          message: 'Debes ser mayor de edad',
        };
      }),

    birthdateDay: Joi.number()
      .required()
      .label('Dia')
      .error(() => {
        return {
          message: 'Dia Requerido',
        };
      }),

    birthdateMonth: Joi.number()
      .required()
      .label('Mes')
      .error(() => {
        return {
          message: 'Mes Requerido',
        };
      }),

    birthdateYear: Joi.number()
      .required()
      .label('Año')
      .error(() => {
        return {
          message: 'Año Requerido',
        };
      }),

    cityAddress: Joi.string()
      .required()
      .label('Ciudad')
      .error(() => {
        return {
          message: 'La ciudad es requerida',
        };
      }),

    isDoer: Joi.boolean(),

    phone: Joi.string()
      .min(10)
      .max(10)
      .required()
      .regex(/^[0-9]{10,10}$/)
      .error(() => {
        return {
          message: 'Ingrese una número de celular válido ',
        };
      })
      .label('Celular'),

    identificationType: Joi.string()
      .required()
      .label('Tipo')
      .error(() => {
        return {
          message: 'Ingrese un tipo de identificación válido',
        };
      }),

    identification: Joi.number()
      .required()
      .label('Cédula')
      .integer()
      .min(800000)
      .max(1999999999)
      .error(() => {
        return {
          message: 'Ingrese una cédula válida',
        };
      }),

    address: Joi.string()
      .required()
      .label('Dirección')
      .error(() => {
        return {
          message: 'Ingrese una dirección válida',
        };
      }),

    addressComplementary: Joi.string().allow('').label('Apto / Torre / Bloque (Opcional)'),

    addressNeighborhood: Joi.string()
      .required()
      .label('Barrio*')
      .error(() => {
        return {
          message: 'Campo Requerido',
        };
      }),

    proffExperience: Joi.string()
      .label('Experiencia')
      .allow('')
      .max(256)
      .error(() => {
        return {
          message: 'Este campo no debe superar 256 caracteres',
        };
      }),

    languages: Joi.array().items(Joi.string()).label('Idiomas').allow([]),

    skills: Joi.array().items(Joi.string()).label('Idiomas').allow([]),
  },
};
