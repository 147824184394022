import { validate, formatBudget } from '../common/utils';
import { minBudget, maxBudget } from '../common/params.json';

const messageRangeValueBudget = `El valor debe estár entre ${formatBudget(
  minBudget,
)} y ${formatBudget(maxBudget)}`;

function getTasksInStep(step) {
  const taskCreationSteps = [
    {
      title: '¿Qué necesidad tienes?',
      input: 'text',
      text: 'Escribe un título corto para la tarea que se va a publicar',
      confirmButtonText: 'Siguiente &rarr;',
      inputAttributes: {
        maxlength: 149,
        minlength: 10,
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (validate(value)) resolve('Lo sentimos pero no permitimos este tipo de palabras.');

          if (value.length >= 10 && value.length <= 149) {
            resolve();
          } else if (value.length === 0) {
            resolve('El campo es requerido');
          } else {
            resolve('La longitud mínima son 10 caracteres, la máxima 150');
          }
        });
      },
    },
    {
      title: 'Descripción',
      text:
        'Indica el mayor detalle posible para que las personas te puedan hacer ofertas validas.',
      input: 'textarea',
      confirmButtonText: 'Siguiente &rarr;',
      inputAttributes: {
        maxlength: 1499,
        minlength: 50,
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (validate(value)) resolve('Lo sentimos pero no permitimos este tipo de palabras.');

          if (value.length > 19 && value.length <= 1500) {
            resolve();
          } else if (value.length === 0) {
            resolve('El campo es requerido');
          } else {
            resolve('La longitud mínima son 20 caracteres, la máxima 100');
          }
        });
      },
    },
    {
      html: 'renderAddressComponent',
      confirmButtonText: 'Siguiente &rarr;',
      preConfirm: (that, TYPE_REMOTE) => {
        if (that.state.taskType !== TYPE_REMOTE) {
          const swalMessage = document.createElement('div');
          swalMessage.className = 'swal2-validation-message';
          swalMessage.id = 'swal2-validation-message';
          swalMessage.setAttribute(
            'style',
            'display: flex; margin-left: -20px; margin-right: -20px;',
          );
          swalMessage.innerHTML = 'Debe incluir los datos de contacto';

          const swalContent = document.getElementsByClassName('swal2-content')[0];

          const addressComplete = [];

          const valueAddress = document.getElementById('places-autocomplete')?.value;
          addressComplete.push(valueAddress);
          const placesAddressLineTwo = document.getElementById('places-address-line-two')?.value;
          addressComplete.push(placesAddressLineTwo);

          const placesNeighborhood = document.getElementById('places-neighborhood')?.value;
          addressComplete.push(placesNeighborhood);

          const placesCityAddress = document.getElementById('places-city-address')?.value;
          addressComplete.push(placesCityAddress);

          if (
            valueAddress?.length < 3 ||
            placesNeighborhood?.length < 3 ||
            placesCityAddress?.length < 3
          ) {
            if (!that.state.swalCustomErrorValidation) swalContent.appendChild(swalMessage);
            that.setState({ swalCustomErrorValidation: true });
            return false;
          }
          that.setState({ swalCustomErrorValidation: false });
          addressComplete.push(that.state.taskType);
          return addressComplete;
        }
        return TYPE_REMOTE;
      },
    },
    {
      html: 'renderNumberAndDateComp',
      confirmButtonText: 'Siguiente &rarr;',
      preConfirm: (that) => {
        const swalMessage = document.createElement('div');
        swalMessage.className = 'swal2-validation-message';
        swalMessage.id = 'swal2-validation-message';
        swalMessage.setAttribute(
          'style',
          'display: flex; margin-left: -20px; margin-right: -20px;',
        );
        swalMessage.innerHTML = messageRangeValueBudget;

        const swalContent = document.getElementsByClassName('swal2-content')[0];

        const datebudgetComplete = [];

        const datepickerdialog = document.getElementById('new-date-picker-dialog')?.value;
        datebudgetComplete.push(datepickerdialog);

        const valueBudget = document
          .getElementById('formatted-numberformat-input')
          .value.replace(/\./g, '');

        datebudgetComplete.push(valueBudget);

        if (!valueBudget || valueBudget < minBudget || valueBudget > maxBudget) {
          swalMessage.innerHTML = messageRangeValueBudget;
          if (!that.state.swalCustomErrorValidation) swalContent.appendChild(swalMessage);

          that.setState({ swalCustomErrorValidation: true });
          return false;
        }

        that.setState({ swalCustomErrorValidation: false });
        return datebudgetComplete;
      },
    },
    {
      title: 'Confirmación',
      html: 'renderConfirmNewTask',
      confirmButtonText: 'Aceptar',
      preConfirm: () => {
        return Promise.resolve();
      },
    },
  ];

  return taskCreationSteps[step];
}

export default {
  getTasksInfoInStep: getTasksInStep,
};
