import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

export function postMessageReply(taskId, messageId, text) {
  return http.post(`${apiEndpoint}/messages/${taskId}/reply/${messageId}`, { text });
}

export function updateMessage(taskId, messageId, message) {
  return http.put(`${apiEndpoint}/messages/${taskId}/messages/${messageId}`, message);
}
