import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CalendarToday from '@material-ui/icons/CalendarToday';
import LocationOn from '@material-ui/icons/LocationOn';
import LocationRemote from '@material-ui/icons/FilterDrama';
import Moment from 'react-moment';
import { Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Details(props) {
  const { task } = props;
  return (
    <>
      <Grid container className="t-first-item" alignItems="center" spacing={2}>
        <Grid item xs={1} sm={1}>
          <Tooltip
            arrow
            enterDelay={200}
            leaveDelay={100}
            placement="bottom"
            title="Ver Perfil Público"
          >
            <Avatar
              component={Link}
              to={`/PublicProfile/${task.userId._id}`}
              alt="Image Profile"
              src={task.userId.avatar}
              className="t-avatar-detail"
            />
          </Tooltip>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Typography variant="subtitle1" className="task-subtitle-i title-left">
            Publicada <Moment fromNow>{task.created}</Moment> por
          </Typography>
          <Typography variant="subtitle1" className="title-left">
            {task.userId.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          className="t-info-block t-detail-location t-detail-icon-location"
        >
          {task.isRemote && <LocationRemote className="t-detail-location" />}
          {!task.isRemote && <LocationOn className="t-detail-location" />}
        </Grid>
        <Grid item xs={10} sm={11} md={11} className="t-detail-icon-location">
          <Grid container direction="column" justify="center" className="t-publisher title-left">
            <Grid item>
              {task.isRemote && (
                <Typography variant="subtitle1" className="task-subtitle-i title-top">
                  Tarea Remota
                </Typography>
              )}
              {!task.isRemote && (
                <Hidden xsDown>
                  <Typography variant="subtitle1" className="task-subtitle-i title-top">
                    Ubicación
                  </Typography>
                </Hidden>
              )}
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{task.addressNeighborhood}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={1} sm={1}>
          <CalendarToday className="t-detail-calendar" />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Grid container direction="column" justify="center" className="t-publisher title-left">
            <Grid item>
              <Hidden xsDown>
                <Typography variant="subtitle1" className="task-subtitle-i">
                  Fecha de Ejecución
                </Typography>
              </Hidden>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                <Moment utc format="DD MMMM YYYY">
                  {task.dueDate}
                </Moment>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Details.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  task: PropTypes.object.isRequired,
};
