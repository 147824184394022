import React, { useReducer, useEffect } from 'react';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';

const TOUR_STEPS = [
  {
    title: <h2>Bienvenido</h2>,
    content: 'En este tutorial aprenderas a conocer las secciones y funciones más importantes.',
    placement: 'center',
    target: 'body',
  },
  {
    target: '.btn-new',
    content: 'Con este botón puedes crear una nueva tarea.',
  },
  {
    target: '.appbar-index',
    content: 'Estas son todas las opciones para administrar tus tareas.',
  },
  {
    target: '.tutorial_all_tasks',
    content: 'Esta es la lista de todas las tareas publicadas en el sistema',
  },
  {
    target: '.tutorial_my_tasks',
    content: 'En esta sección encontrarás la lista de tareas que tu has publicado.',
  },
  {
    target: '.tutorial_my_offers',
    content: 'Acá encontrarás las ofertas que has realizado en alguna tarea.',
  },
  {
    target: '.tutorial_tasks_assigned',
    content:
      'En esta sección encontrarás las tareas donde te aceptaron la oferta y debes ejecutar.',
  },
  {
    content: (
      <>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1" gutterBottom>
              Este es el listado de todas las tareas creadas en la plataforma, cada color indica el
              estado de éstas:
            </Typography>
          </Grid>
          <Grid item style={{ padding: '10px 10px' }}>
            <Typography className="border-tour-tasks task-chip-state-Nueva border-tour">
              Nueva
            </Typography>
            <Typography gutterBottom variant="body2">
              Indica que la tarea está recién creada y puedes ofertar en ella.
            </Typography>
            <Typography className="border-tour-tasks task-chip-state-Asignada">Asignada</Typography>
            <Typography gutterBottom variant="body2">
              La tarea ya fue asignada y se encuentra en proceso de solución.
            </Typography>
            <Typography className="border-tour-tasks task-chip-state-Finalizada">
              Finalizada
            </Typography>
            <Typography gutterBottom variant="body2">
              La tarea ya fue ejecutada.
            </Typography>
            <Typography className="border-tour-tasks task-chip-state-Cancelada">
              Cancelada
            </Typography>
            <Typography gutterBottom variant="body2">
              Es cuando el dueño de la tarea la cancela.Solo se puede cancelar cuando no ha sido
              asignada.
            </Typography>
          </Grid>
        </Grid>
      </>
    ),
    target: '.prev-tasks-container',
    placement: 'auto',
  },
  {
    target: '.avatar-tour-icon',
    content:
      'Al desplegar este menú vas a encontrar como editar tu perfil, el calendario de tareas y muchas más opciones que vamos a incluir próximamente.',
  },
  {
    title: <h2>Fin</h2>,
    content:
      'Has finalizado con éxito esta introducción, cualquier duda adicional consulta la sección de preguntas frecuentes en la página principal o contáctanos, estaremos atentos a todas tus preguntas.',
    placement: 'center',
    target: 'body',
  },
];

const INITIAL_STATE = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0, // Make the component controlled
  steps: TOUR_STEPS,
  key: new Date(), // This field makes the tour to re-render when the tour is restarted
};

const reducer = (state = INITIAL_STATE, action) => {
  localStorage.setItem('tour', false);
  switch (action.type) {
    case 'START':
      return { ...state, run: true };
    case 'RESET':
      return { ...state, stepIndex: 0 };
    case 'STOP':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

export default function Tour(props) {
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('tour'))) {
      dispatch({ type: 'START' });
    } else {
      dispatch({ type: 'STOP' });
    }
  }, []);

  const callback = (data) => {
    const { action, index, type, status } = data;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };
  const startTour = () => {
    dispatch({ type: 'START' });
  };

  return (
    <>
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton
        steps={TOUR_STEPS}
        showProgress
        continuous
        locale={{
          back: 'Regresar',
          close: 'Cerrar',
          last: 'Finalizar',
          next: 'Siguiente',
          skip: 'Saltar',
        }}
        styles={{
          options: {
            arrowColor: '#5078FF',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            primaryColor: '#5078FF',
          },
        }}
      />
      {props.menuText && <ListItemText primary="Tutorial" onClick={startTour} />}
    </>
  );
}
