import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    width: '900px',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PoliticsModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <Typography
        style={{ cursor: 'pointer' }}
        variant="caption"
        type="button"
        onClick={handleOpen}
      >
        * Al crear o actualizar tu perfil estás confirmando haber leído y aceptado las{' '}
        <Typography variant="caption" className="main-text-color">
          políticas de tratamiento de datos.
        </Typography>
      </Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Tratamiento de datos</h2>
            <p id="transition-modal-description">
              Autorizo de manera voluntaria, previa, expresa e informada a Pliss para que soliciten,
              recolecten, almacenen, consulten, verifiquen, procesen, compartan, modifiquen,
              actualicen, aclaren o retiren los datos aquí suministrados con la finalidad de
              realizar tratamientos analíticos y estadísticos, enviarme información referente a los
              informes solicitados y para contactarme a través de correo electrónico y/o mensaje de
              texto para el ofrecicimiento de productos y/o servicios.
            </p>
            <p>
              Asi mismo, declaro que he sido informado sobre el derecho que tengo a conocer,
              actualizar y rectificar mis datos personales, solicitar prueba de la autorización, ser
              informado sobre el tratamiento que se ha dado a mis datos presonales, presentar quejar
              ante la Superintendencia de Industria y Comercio (SIC), revocar la autorización
              otorgada y/o solicitar la supresión de mis datos en los casos en que sea procedente.
              Para conocer la Política para la administración de datos personales, ingrese al
              enlace:
            </p>
            <Link
              href="https://pliss-assets.s3.amazonaws.com/policies/policyInformation.pdf"
              target="_blank"
            >
              Políticas de Privacidad de Información.
            </Link>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
}
