import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import Link from '@material-ui/core/Link';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));

export default function SocialMain(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { renderType } = props;
  let RenderContact;

  switch (renderType) {
    case 'opinions':
      RenderContact = (
        <div className="social-Main">
          <Link
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=+573186197193&text=Hola, quisiera más información sobre Pliss."
            className="icon icon-whatsapp"
            target="_blank"
          >
            <FontAwesomeIcon className="icon-ws-float" icon={faWhatsapp} size="1x" />
            <Typography className="icon-float-text">Contáctenos</Typography>
          </Link>

          <Grid className="icon icon-feedback" onClick={handleOpen}>
            <FontAwesomeIcon className="icon-ws-float" icon={faComments} size="1x" />
            <Typography className="icon-float-text">Opiniones</Typography>
          </Grid>
        </div>
      );

      break;
    case 'button':
      RenderContact = (
        <Fab variant="extended" className="contact-btn" onClick={handleOpen}>
          CONTACTANOS
        </Fab>
      );
      break;
    case 'text':
      RenderContact = (
        <Typography className="contact-text" onClick={handleOpen}>
          Contacto
        </Typography>
      );
      break;
    default:
      return null;
  }

  return (
    <div>
      {RenderContact}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <iframe
              title="ContactUs"
              width="100%"
              height="480px"
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAERR0xEHtlURUxHREYxUFRUUlM4WENWUFU5OFA3RTY1SS4u&embed=true"
              frameBorder="0"
              marginWidth="0"
              marginHight="0"
              allowFullScreen
              webkitallowfullscreen
              mozallowfullscreen
              msallowfullscreen
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
