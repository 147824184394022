import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { faUser, faCalendarCheck, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PubSub from 'pubsub-js';
import { ReactComponent as IconLogOut } from '../home/IconLogOut.svg';
import { logout } from '../auth/services';
import Tour from './Tour';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={8}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#5078ff',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function NavBarMenuOptions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [localPhoto, setLocalPhoto] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const localP = localStorage.getItem('photo');
    if (localP) setLocalPhoto(localP);
  }, []);

  const updateNavbarPhoto = (msg, url) => {
    setLocalPhoto(url);
  };
  PubSub.subscribe('UPDATE_NAVBAR_PHOTO', updateNavbarPhoto);

  return (
    <div>
      <IconButton
        color="secondary"
        id="avatar-btn"
        className="logged-nav-icon-btn"
        onClick={handleClick}
      >
        <Avatar
          className="avatar-tour-icon"
          alt="Image Profile"
          src={localPhoto || props.user.avatar}
        />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            props.onHandleRedirect('/myaccount/profile');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon className="app-icon-login" icon={faUser} size="lg" />
          </ListItemIcon>
          <ListItemText primary="Perfil" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            props.onHandleRedirect('/myaccount/calendar');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon className="app-icon-login" icon={faCalendarCheck} size="lg" />
          </ListItemIcon>
          <ListItemText primary="Calendario" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            localStorage.setItem('tour', true);
            props.onHandleRedirect('/tasks');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon className="app-icon-login" icon={faQuestionCircle} size="lg" />
          </ListItemIcon>
          {/* <ListItemText primary="Tutorial" /> */}
          <Tour menuText />
        </StyledMenuItem>

        {/* <StyledMenuItem
          onClick={() => {
            props.onHandleRedirect('/myaccount/doers');
            handleClose();
          }}
        >
          <ListItemIcon>
            <FontAwesomeIcon className="app-icon-login" icon={faCalendarCheck} size="lg" />
          </ListItemIcon>
          <ListItemText primary="Solucionadores" />
        </StyledMenuItem> */}

        <Tooltip
          arrow
          TransitionComponent={Zoom}
          enterDelay={0}
          leaveDelay={200}
          placement="left"
          title="Próximamente"
          disableTouchListener
        >
          <StyledMenuItem>
            <ListItemIcon>
              <FontAwesomeIcon className="app-icon-login" icon={faHistory} size="lg" />
            </ListItemIcon>
            <ListItemText primary="Historial Pagos" />
          </StyledMenuItem>
        </Tooltip>
        <StyledMenuItem
          onClick={() => {
            logout();
            window.location.href = '/';
          }}
        >
          <ListItemIcon>
            <IconLogOut className="app-icon-login app-icon-login-option" />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesion" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
