import http from '../common/services/httpService';
import { appPaths, apiEndpoint } from '../../config.json';

const PATHS = appPaths;

export async function getUserNotifications() {
  return http.get(`${apiEndpoint}/users/notifications`);
}

export function updateUserNotificationState(taskId) {
  return http.put(`${apiEndpoint}/users/updateUserNotification/${taskId}`);
}

export function getPathByNumber(pathNumber) {
  switch (pathNumber) {
    case 0:
      return PATHS.TASKS;
    case 1:
      return PATHS.MY_TASKS;
    case 2:
      return PATHS.MY_OFFERS;
    case 3:
      return PATHS.MY_ASSIGNMENTS;
    default:
      return PATHS.TASKS;
  }
}

export default {
  getUserNotifications,
  updateUserNotificationState,
  getPathByNumber,
};
