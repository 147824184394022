import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import AdjustIcon from '@material-ui/icons/Adjust';
import 'moment/locale/es';
import PublicSimpleTabs from './PublicSimpleTabs';

export default function PublicProfileInfo(props) {
  const bossReviewsExists = props.bossReviews > 0;
  const workerReviewsExists = props.workerReviews > 0;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reviewsExists, setReviewsExists] = React.useState(
    bossReviewsExists || workerReviewsExists,
  );

  const POSTER = 'Publicador';
  const DOER = 'Solucionador';

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    if (index === 0) {
      setReviewsExists(bossReviewsExists);
      props.onBossReviews();
    } else {
      props.onWorkerReviews();
      setReviewsExists(workerReviewsExists);
    }
  };

  useEffect(() => {
    if (props.workerReviews) {
      props.onWorkerReviews();
      setSelectedIndex(1);
    } else if (props.bossReviews) {
      props.onBossReviews();
    }
  }, []);

  return (
    <Grid item xs={12} md={8} className="main-profile-card">
      <Paper className="pp-paper">
        <div className="rel-container">
          <div className="main-profile-card-header">
            <div className="card-layout">
              <Avatar className="main-pp-user-avatar" src={props.avatar} />
            </div>
          </div>
        </div>

        <div className="pp-detail-card">
          <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item container justify="center">
              {props.name && <Typography className="pp-card-task-title">{props.name}</Typography>}
              {!props.name && (
                <Typography className="pp-card-task-title">Sin información registrada</Typography>
              )}
            </Grid>
            <Grid item container justify="center">
              <Typography className="pp-card-task-city">Medellín, Antioquia.</Typography>
            </Grid>
            <Grid
              item
              container
              justify="center"
              xs={10}
              md={5}
              alignItems="center"
              className="rating-tabs"
            >
              <PublicSimpleTabs
                bossRating={props.bossRating}
                workerRating={props.workerRating}
                onHandleTabChange={handleTabChange}
              />
            </Grid>
          </Grid>

          <Typography gutterBottom className="pp-description" variant="h5">
            Experiencia
          </Typography>
          {props.experience ? (
            // <Chip size="small" label={props.experience} />
            <Typography variant="body2">{props.experience}</Typography>
          ) : (
            <Chip size="small" label="Sin información registrada" />
          )}

          <Typography className="pp-description" variant="h5">
            Habilidades
          </Typography>
          {props.skills.length ? (
            props.skills.map((option) => <Chip size="small" label={option} className="pp-chips" />)
          ) : (
            <Chip size="small" label="Sin información registrada" />
          )}

          <Grid container alignItems="center" className="pp-projects-container">
            <Grid item xs={4} container alignItems="center">
              <Grid item>
                <AdjustIcon className="pp-projects-icon" />
              </Grid>
              <Grid item>
                <Typography className="pp-projects-label">
                  {' '}
                  Proyectos realizados: {props.bossReviews + props.workerReviews}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <br />
      <Grid container justify="center">
        {reviewsExists ? (
          <Typography variant="h5" className="role-selected-label">
            Testimonios como{' '}
            <Box fontWeight="fontWeightBold" className="inline-block">
              {selectedIndex === 0 ? POSTER : DOER}
            </Box>
          </Typography>
        ) : (
          <Typography variant="h5" className="role-selected-label">
            No existen testimonios como <span>{selectedIndex === 0 ? POSTER : DOER}</span>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
