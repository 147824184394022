import React, { useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import AOS from 'aos';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Apple from '@material-ui/icons/Apple';
import Android from '@material-ui/icons/Android';
import Fab from '@material-ui/core/Fab';
import queryString from 'query-string';
import PubSub from 'pubsub-js';
import Loading from '../common/loading';
import SocialMain from '../common/socialMain';
import { ReactComponent as PlissNameText } from '../../assets/plissNameText.svg';
import ModalLogin from '../common/modalLogin';
import { getCurrentUser } from '../auth/services';
import httpService from '../common/services/httpService';
import 'aos/dist/aos.css';
import FirebaseContext from '../../contexts/firebase-services';

const Home = (props) => {
  const [isLoading, setisLoading] = React.useState(true);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const firebaseContext = useContext(FirebaseContext);

  const handleRedirect = (msg, target) => {
    if (!getCurrentUser()) {
      setLoginOpen(!loginOpen);
    } else {
      window.location.href = target;
    }
  };

  useEffect(() => {
    httpService.checkSession();
    const token = PubSub.subscribe('CHANGE_MODAL_LOGIN_STATE', handleRedirect);
    return () => {
      PubSub.unsubscribe(token);
    };
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      bottom: theme.spacing(1),
      right: 'calc(50% - (40px / 2))',
    },
  }));

  function ScrollTop(propsScroll) {
    const { children, window } = propsScroll;
    const classes = useStyles();
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }

  AOS.init();

  useEffect(() => {
    setTimeout(() => {
      setisLoading(!isLoading);
    }, 500);

    firebaseContext.analytics.logEvent('page_view', { method: 'landpage' });
    const params = queryString.parse(props.location.search);
    if (params.r) setLoginOpen(true);
  }, []);

  return (
    <>
      <Grid id="back-to-top-anchor" />

      {isLoading ? (
        <Loading />
      ) : (
        <Grid id="fullPage">
          <ModalLogin mustOpen={loginOpen} onClose={setLoginOpen} {...props} />

          <Grid container className="box-how-working box-how-working-bar">
            <Parallax
              bgImage="https://pliss-assets.s3.amazonaws.com/home/landing_img_ppal.webp"
              strength={300}
            >
              <div className="how-working-parallax" />
            </Parallax>
          </Grid>
          <Grid className="box-types" container justify="flex-end">
            <Grid container item xs={12} justify="center">
              <PlissNameText className="pliss-logo-type fade-in" />
            </Grid>

            <Grid container item xs={12} className="box-types-info" justify="center">
              <Grid
                item
                container
                xs={12}
                md={12}
                alignContent="center"
                spacing={8}
                data-aos="fade-up"
                className="do-it-for-you-container"
              >
                <Grid container item xs={12} justify="center" alignContent="center">
                  <Typography variant="h3" className="do-it-for-you">
                    Alguien puede hacerlo por ti
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                  alignContent="flex-start"
                  spacing={3}
                  className="do-it-second-one"
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    className="types-left-publisher"
                    justify="center"
                  >
                    <Grid item align="center">
                      <Typography variant="h4" className="types-choose-label">
                        PUBLICADOR DE TAREAS
                      </Typography>
                      <Typography variant="h6" className="description-home-label">
                        ¡Encuentra la persona ideal para el servicio que requieres!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    justify="center"
                    className="types-right-publisher"
                  >
                    <Grid item align="center">
                      <Typography variant="h4" className="types-choose-label">
                        SOLUCIONADOR
                      </Typography>
                      <Typography variant="h6" className="description-home-label">
                        ¿Quieres un dinero extra? Usa tus conocimientos y habilidades para ayudar a
                        los demás.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="box-how-working smile-working"
            container
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              xs={12}
              md={12}
              data-aos="fade-up"
              className="how-works-container-smile"
            >
              <Grid item xs={12} md={3}>
                <div className="working-smile" />
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" className="how-works-label">
                      ¿Quieres saber cómo funciona Pliss?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7} className="works-description">
                    <Typography
                      variant="body2"
                      className="description-home-label works-description"
                    >
                      No importa si vas a publicar tareas o a solucionarlas, !Es igual de fácil!
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} lg={5} justify="center">
                    <Grid item>
                      <Fab
                        variant="extended"
                        className="how-works-btn"
                        onClick={() => handleRedirect('', '/myaccount/profile')}
                      >
                        ¡REGISTRATE YA!
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="box-solutions" container justify="center" alignItems="center">
            <Grid
              item
              container
              xs={12}
              md={12}
              lg={12}
              alignItems="center"
              justify="center"
              className="box-solutions-resp"
            >
              <Grid item xs={12}>
                <Typography variant="h3" align="center" className="box-header-label">
                  ¿Cómo encontrar la persona ideal para tu tarea?
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Divider className="solutions-line-divider" />
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="left"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="100"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-1">
                        <span>1</span>
                      </div>
                      <div className="solutions-first-step" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-one"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-1" />
                          <Grid item xs={12}>
                            <Typography variant="h5" className="bar-step-details">
                              CREA
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h5" className="bar-step-details-sub">
                              una tarea
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="center"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-2">
                        <span>2</span>
                      </div>
                      <div className="solutions-second-step" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-two"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-2" />
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details">
                              SELECCIONA
                            </Typography>
                          </Grid>
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details-sub">
                              la mejor oferta
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="right"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="250"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-3">
                        <span>3</span>
                      </div>
                      <div className="solutions-third-step" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-third"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-3" />
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details">
                              RELÁJATE
                            </Typography>
                          </Grid>
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details-sub">
                              mientras lo hacen por ti
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="box-solutions" container justify="center" alignItems="center">
            <Grid
              item
              container
              xs={12}
              md={12}
              alignItems="center"
              justify="center"
              className="box-solutions-resp"
            >
              <Grid item xs={12}>
                <Typography className="box-header-label" variant="h3" align="center">
                  ¿Cómo solucionar tareas y ganar dinero?
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="center">
                <Divider className="solutions-line-divider" />
              </Grid>

              <Grid container direction="row">
                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="left"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="100"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-1">
                        <span>1</span>
                      </div>
                      <div className="solutions-first-step-2" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-one"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-1" />
                          <Grid item xs={12}>
                            <Typography variant="h5" className="bar-step-details">
                              ENCUENTRA
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h5" className="bar-step-details-sub">
                              una tarea que puedas hacer
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="center"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-2">
                        <span>2</span>
                      </div>
                      <div className="solutions-second-step-2" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-two"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={6}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-2" />
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details">
                              REALIZA
                            </Typography>
                          </Grid>
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details-sub">
                              una oferta de solución
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                  <Grid item container direction="column">
                    <Grid
                      item
                      align="right"
                      className="solutions-relative-cont"
                      data-aos="fade-right"
                      data-aos-offset="250"
                      data-aos-duration="700"
                    >
                      <div className="number-decorator number-decorator-3">
                        <span>3</span>
                      </div>
                      <div className="solutions-third-step-2" />
                    </Grid>
                    <Grid item xs={12} className="box-solutions-steps" container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        className="bar-steps-third"
                        justify="center"
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          justify="center"
                          alignContent="center"
                          data-aos="zoom-in"
                          className="bar-step-container"
                        >
                          <div className="bar-enum-3" />
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details">
                              COMPLETA
                            </Typography>
                          </Grid>
                          <Grid item xs={12} container justify="center">
                            <Typography variant="h5" className="bar-step-details-sub">
                              el servicio y recibe tu dinero
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className="popular-home-info" justify="center">
            <Grid item container xs={10} justify="center">
              <Typography variant="h4" component="h4">
                Tipos de tareas que podrías encontrar
              </Typography>
            </Grid>
            <Grid item xs={12} container justify="center">
              <Divider className="solutions-line-divider" />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={11}
              justify="center"
              alignItems="center"
              className="popular-task-container"
              wrap="wrap"
              spacing={3}
            >
              <Grid
                item
                container
                xs={10}
                md={4}
                lg={3}
                data-aos="zoom-out"
                data-aos-duration="500"
                justify="center"
              >
                <Card className="popular-task-item">
                  <CardContent>
                    <Grid item className="popular-principal-img-container">
                      <img
                        loading="lazy"
                        alt="popular"
                        src="https://pliss-assets.s3.amazonaws.com/home/populars_1.webp"
                        className="populars_borders"
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      className="avatar-and-twitter"
                    >
                      <Grid item xs={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src="https://pliss-assets.s3.amazonaws.com/home/profile_person_1.webp"
                          className="p-task-item-avatar"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className="social-account-name">@juancamilo58</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} justify="center" alignItems="flex-start">
                      <Grid item container xs={2} className="star-and-score-con">
                        <Grid item xs={12}>
                          <div className="popular-task-star" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="p-task-score"> 4.6</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} container>
                        <Grid item>
                          <Typography className="p-task-title">Pintar Casa</Typography>
                          <Typography className="p-task-desc">
                            ¿Quién pinta mi casa este fin de semana?
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                          <Grid item xs={1}>
                            <div className="task-place-logo" />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className="task-place-name">ENVIGADO</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                container
                xs={10}
                md={4}
                lg={3}
                data-aos="zoom-out"
                data-aos-duration="500"
                justify="center"
              >
                <Card className="popular-task-item">
                  <CardContent>
                    <Grid item className="popular-principal-img-container">
                      <img
                        loading="lazy"
                        alt="popular"
                        src="https://pliss-assets.s3.amazonaws.com/home/populars_2.webp"
                        className="populars_borders"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      className="avatar-and-twitter"
                    >
                      <Grid item xs={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src="https://pliss-assets.s3.amazonaws.com/home/profile_person_2.webp"
                          className="p-task-item-avatar"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className="social-account-name">@jorgerod</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} justify="center" alignItems="flex-start">
                      <Grid item container xs={2} className="star-and-score-con">
                        <Grid item xs={12}>
                          <div className="popular-task-star" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="p-task-score"> 4.8</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} container>
                        <Grid item>
                          <Typography className="p-task-title">Arreglar Ropa</Typography>
                          <Typography className="p-task-desc">
                            Necesito arreglar mi vestido
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                          <Grid item xs={1}>
                            <div className="task-place-logo" />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className="task-place-name">MEDELLÍN</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                container
                xs={10}
                md={4}
                lg={3}
                data-aos="zoom-out"
                data-aos-duration="500"
                justify="center"
              >
                <Card className="popular-task-item">
                  <CardContent>
                    <Grid item className="popular-principal-img-container">
                      <img
                        loading="lazy"
                        alt="popular"
                        src="https://pliss-assets.s3.amazonaws.com/home/populars_3.webp"
                        className="populars_borders"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      className="avatar-and-twitter"
                    >
                      <Grid item xs={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src="https://pliss-assets.s3.amazonaws.com/home/profile_person_3.webp"
                          className="p-task-item-avatar"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className="social-account-name">@camila_lip</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} justify="center" alignItems="flex-start">
                      <Grid item container xs={2} className="star-and-score-con">
                        <Grid item xs={12}>
                          <div className="popular-task-star" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="p-task-score">5.0</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} container>
                        <Grid item>
                          <Typography className="p-task-title">Enseñar</Typography>
                          <Typography className="p-task-desc">
                            Requiero un tutor de inglés
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                          <Grid item xs={1}>
                            <div className="task-place-logo" />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className="task-place-name">LA CEJA</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                container
                xs={10}
                md={4}
                lg={3}
                data-aos="zoom-out"
                data-aos-duration="500"
                justify="center"
              >
                <Card className="popular-task-item">
                  <CardContent>
                    <Grid item className="popular-principal-img-container">
                      <img
                        loading="lazy"
                        alt="popular"
                        src="https://pliss-assets.s3.amazonaws.com/home/populars_4.webp"
                        className="populars_borders"
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      className="avatar-and-twitter"
                    >
                      <Grid item xs={2}>
                        <Avatar
                          alt="Remy Sharp"
                          src="https://pliss-assets.s3.amazonaws.com/home/profile_person_4.webp"
                          className="p-task-item-avatar"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography className="social-account-name">@andres</Typography>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} justify="center" alignItems="flex-start">
                      <Grid item container xs={2} className="star-and-score-con">
                        <Grid item xs={12}>
                          <div className="popular-task-star" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="p-task-score"> 4.6</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} container>
                        <Grid item>
                          <Typography className="p-task-title">Ordenar casa</Typography>
                          <Typography className="p-task-desc">
                            Tengo muchos libros y necesito ordenar
                          </Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                          <Grid item xs={1}>
                            <div className="task-place-logo" />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography className="task-place-name">LAS PALMAS</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid
              className="populars-people-content"
              item
              container
              xs={12}
              md={9}
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={10}
                md={6}
                lg={4}
                container
                justify="center"
                data-aos="fade-up"
                className="populars-people-item"
              >
                <Grid item xs={12} container justify="center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://pliss-assets.s3.amazonaws.com/home/popular_person_1.webp"
                    className="populars-info-img"
                  />
                </Grid>
                <Grid item container spacing={5}>
                  <Grid item xs={12} container justify="center">
                    <Grid item xs={12} container justify="center">
                      <Typography variant="h3" component="h3" className="popular-task-name">
                        <Box fontWeight="fontWeightBold">JUAN</Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Typography component="p" className="popular-task-desc">
                        Necesito pintar patio de mi casa este fin de semana
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="center" alignItems="flex-end">
                      <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="#01d494" />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h5" className="popular-place">
                        ENVIGADO
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={10}
                md={6}
                lg={4}
                container
                justify="center"
                data-aos="fade-up"
                className="populars-people-item"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="https://pliss-assets.s3.amazonaws.com/home/popular_person_2.webp"
                  className="populars-info-img"
                />
                <Grid item container spacing={5}>
                  <Grid item xs={12} container justify="center">
                    <Grid item xs={12} container justify="center">
                      <Typography variant="h3" component="h3" className="popular-task-name">
                        <Box fontWeight="fontWeightBold">MARCELA</Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Typography component="p" className="popular-task-desc">
                        Necesito un tutor de inglés para mi hijo
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="center" alignItems="flex-end">
                      <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="#01d494" />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h5" className="popular-place">
                        MEDELLÍN
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={10}
                md={6}
                lg={4}
                container
                justify="center"
                data-aos="fade-up"
                className="populars-people-item"
              >
                <Avatar
                  alt="Remy Sharp"
                  src="https://pliss-assets.s3.amazonaws.com/home/popular_person_3.webp"
                  className="populars-info-img"
                />
                <Grid item container spacing={5}>
                  <Grid item xs={12} container justify="center">
                    <Grid item xs={12} container justify="center">
                      <Typography variant="h3" component="h3" className="popular-task-name">
                        <Box fontWeight="fontWeightBold">LUISA</Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Typography component="p" className="popular-task-desc">
                        Necesito una maquilladora para mañana
                      </Typography>
                    </Grid>
                    <Grid item xs={2} container justify="center" alignItems="flex-end">
                      <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" color="#01d494" />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h5" className="popular-place">
                        LA CEJA
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="work-in-your-profile" alignItems="center">
            <Grid item xs={12} md={7}>
              <Parallax
                bgImage="https://pliss-assets.s3.amazonaws.com/home/profile.webp"
                strength={300}
              >
                <div className="work-in-your-profile-image-container" />
              </Parallax>
            </Grid>
            <Grid item xs={12} md={5} className="work-in-your-right" data-aos="fade-up">
              <Grid item xs={12} container>
                <Grid item xs={12} md={12} lg={3}>
                  <div className="working-smile profile-smile" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" component="h2">
                    ¡Trabaja en tu Perfil!
                  </Typography>
                </Grid>
                <Grid xs={12} item container className="work_onprofile_stars">
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component="p">
                    Una buena calificación, generar experiencias positivas en tus servicios y la
                    impecabilidad en lo que haces, harán que más personas se sientan seguras a la
                    hora de elegirte.
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Fab
                    variant="extended"
                    className="work-in-your-btn"
                    onClick={() => handleRedirect('', '/myaccount/profile')}
                  >
                    IR A MI PERFIL
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="experiences" justify="center" data-aos="fade-up">
            <Grid item xs={12} md={10} container justify="center" alignItems="center">
              <Grid
                xs={12}
                md={6}
                lg={4}
                item
                container
                justify="center"
                direction="column"
                spacing={3}
              >
                <Grid item container justify="center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://pliss-assets.s3.amazonaws.com/home/profile_person_1.webp"
                    className="profile-experience-img"
                  />
                </Grid>

                <Grid xs={12} item container justify="center" alignItems="center">
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                </Grid>
                <Grid item container justify="center">
                  <Typography variant="h4" className="exp-title">
                    CAMILO
                  </Typography>
                  <Typography variant="body2" align="center" component="p" className="exp-desc">
                    En menos de 2 horas encontré a tres personas que podían pintar mi casa.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                xs={12}
                md={6}
                lg={4}
                item
                container
                justify="center"
                direction="column"
                spacing={3}
              >
                <Grid item container justify="center">
                  <Avatar
                    alt="Profile person"
                    src="https://pliss-assets.s3.amazonaws.com/home/profile_person_2.webp"
                    className="profile-experience-img"
                  />
                </Grid>
                <Grid xs={12} item container justify="center" alignItems="center">
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#b8b8b8" />
                  </Grid>
                </Grid>
                <Grid item container justify="center">
                  <Typography variant="h4" className="exp-title">
                    PEDRO
                  </Typography>
                  <Typography variant="body2" align="center" component="p" className="exp-desc">
                    Fue genial encontrar tantas alternativas y elegir la mejor opción al precio
                    justo.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                xs={12}
                md={6}
                lg={4}
                item
                container
                justify="center"
                direction="column"
                spacing={3}
              >
                <Grid item container justify="center">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://pliss-assets.s3.amazonaws.com/home/profile_person_3.webp"
                    className="profile-experience-img"
                  />
                </Grid>
                <Grid xs={12} item container justify="center" alignItems="center">
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                  <Grid item container justify="center" xs={1}>
                    <FontAwesomeIcon icon={faStar} size="lg" color="#01d494" />
                  </Grid>
                </Grid>
                <Grid item container justify="center">
                  <Typography variant="h4" className="exp-title">
                    MARIA
                  </Typography>
                  <Typography variant="body2" align="center" component="p" className="exp-desc">
                    Los arreglos quedaron perfectos, tenía muy buen conocimiento, gracias por toda
                    la ayuda.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="contact-us" justify="center" alignItems="center">
            <Grid item xs={10} md={6} container data-aos="fade-up">
              <Grid item container justify="center">
                <Typography
                  className="contact-label contact-header-label"
                  variant="h3"
                  component="h3"
                >
                  ¿Tienes dudas?
                </Typography>
              </Grid>
              <Grid item xs={12} container justify="center">
                <SocialMain renderType="button" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container className="big-footer" xs={12} justify="center" alignItems="center">
            <Grid container item xs={12} md={10} spacing={8}>
              <Grid className="big-footer-item" item xs={12} md={3}>
                <Typography className="footer-header-title">¿BUSCABAS ALGO MÁS?</Typography>
                <Typography className="footer-link">
                  <Link href="/faq-page">Preguntas Frecuentes</Link>
                </Typography>
              </Grid>
              <Grid className="big-footer-item" item xs={12} md={3}>
                <Typography className="footer-header-title">LEGAL</Typography>
                <Typography className="footer-link">
                  <Link
                    rel="noreferrer"
                    href="https://pliss-assets.s3.amazonaws.com/policies/termsAndConditions.pdf"
                    target="_blank"
                  >
                    Términos y Condiciones
                  </Link>
                </Typography>
                <Typography className="footer-link">
                  <Link
                    rel="noreferrer"
                    href="https://pliss-assets.s3.amazonaws.com/policies/policyInformation.pdf"
                    target="_blank"
                  >
                    Políticas de Privacidad
                  </Link>
                </Typography>
              </Grid>
              <Grid className="big-footer-item" item xs={12} md={3}>
                <Typography className="footer-header-title">NOSOTROS</Typography>
                <SocialMain renderType="text" />
                <Typography className="footer-link">
                  <Link href="/about-us">Quiénes somos</Link>
                </Typography>
              </Grid>
              <Grid className="big-footer-item" item xs={12} md={3}>
                <div className="footer-pliss-img" />
                <Typography className="footer-link">
                  <Hidden xlDown>
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      enterDelay={200}
                      leaveDelay={100}
                      placement="right"
                      title="Próximamente"
                      disableTouchListener
                    >
                      <Link href="#region">Socios Estratégicos</Link>
                    </Tooltip>
                  </Hidden>
                </Typography>
                <Typography className="footer-link">
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    enterDelay={200}
                    leaveDelay={100}
                    placement="right"
                    title="Próximamente"
                    disableTouchListener
                  >
                    <Link aria-label="Descarga la aplicación" href="/">
                      Descarga la aplicación{' '}
                    </Link>
                  </Tooltip>
                </Typography>
                <Apple style={{ color: 'rgba(255, 255, 255, 0.73)', marginLeft: '20px' }} />
                <Android
                  fontSize="small"
                  style={{ color: 'rgba(255, 255, 255, 0.73)', marginLeft: '20px' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container className="little-footer" justify="center">
            <Grid item container xs={12} md={10} alignItems="center">
              <Grid item xs={12} md={10} container alignItems="center">
                <Grid item xs={12} className="footer-light-container" align="center">
                  <PlissNameText className="footer-light-pliss-img" />
                </Grid>
                <Grid item xs={12} align="center" className="light-text-container">
                  <Typography className="light-text">
                    Todos los derechos reservados. {new Date().getFullYear()}. ©
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={4}
                md={2}
                className="light-network-container"
                justify="space-between"
                style={{ color: 'white' }}
              >
                <Grid item xs={4} md={4}>
                  <Link
                    aria-label="Twitter"
                    rel="noreferrer"
                    href="https://www.twitter.com/PlissApp"
                    color="inherit"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                  </Link>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Link
                    rel="noreferrer"
                    aria-label="Facebook"
                    href="https://www.fb.me/plissappco"
                    color="inherit"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </Link>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Link
                    rel="noreferrer"
                    aria-label="Instagram"
                    href="https://www.instagram.com/plissapp"
                    color="inherit"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <SocialMain renderType="opinions" />
          <ScrollTop {...props}>
            <Fab size="small" aria-label="scroll back to top" className="scroll-back-to-top">
              {' '}
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </Grid>
      )}
    </>
  );
};

export default Home;
