import Raven from 'raven-js';
import { apiEndpoint } from '../../../config.json';

function init() {
  Raven.config('https://df971d4a5fbe417f9bb21c68aeea24fa@sentry.io/1373633', {
    release: '1-0-0',
    environment: 'development-test',
  }).install();
}

function log(error, tags) {
  if (apiEndpoint.includes('localhost') || apiEndpoint.includes('api-dev')) {
    // eslint-disable-next-line no-console
    console.error(error);
  } else {
    const ravenOpts = { tags };

    if (tags) {
      Raven.captureException(error, ravenOpts);
    } else {
      Raven.captureException(error);
    }
  }
}

export default {
  init,
  log,
};
