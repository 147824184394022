import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsOutlined from '@material-ui/icons/NotificationsOutlined';
import * as service from './services';
import NotificationItems from './notificationItems';

export default function Notifications(props) {
  const { getBadges } = props;
  const [notificationsBadge, setNotificationsBadge] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (notifications?.length === 0) {
      setTimeout(() => handleClose(), 2500);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewNotification = async (taskId, pathNumber, notificatonState) => {
    if (notificatonState === 'Created') {
      await service.updateUserNotificationState(taskId);
    }
    const notPath = service.getPathByNumber(pathNumber);
    props.history.push(`${notPath}/${taskId}`);
    getUserNotificationsInfo();
  };

  const getUserNotificationsInfo = async () => {
    if (getBadges) {
      getBadges();
    }
    const userNotifications = await service.getUserNotifications();
    if (userNotifications.data) {
      setNotifications(userNotifications.data);
      const activeNotifications =
        userNotifications.data &&
        userNotifications.data.filter((notification) => notification.state === 'Created');
      setNotificationsBadge(activeNotifications && activeNotifications.length);
    } else {
      setNotifications([]);
      setNotificationsBadge(0);
    }
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
    // eslint-disable-next-line no-shadow
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  useEffect(() => {
    getUserNotificationsInfo();
    const interval = setInterval(() => {
      getUserNotificationsInfo();
    }, 90000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <IconButton
        color="primary"
        className="logged-nav-icon-btn logged-nav-icon-btn-right"
        onClick={handleClick}
      >
        <Badge color="secondary" badgeContent={notificationsBadge}>
          <NotificationsOutlined />
        </Badge>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="menu-list"
      >
        <NotificationItems
          NotificationsList={notifications}
          viewNotification={viewNotification}
          handleClose={handleClose}
          isMobile={false}
        />
      </StyledMenu>
    </div>
  );
}
