import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';
import { ListItem } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Moment from 'react-moment';
import 'moment/locale/es';
import withReactContent from 'sweetalert2-react-content';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import _ from 'lodash';
import * as oService from './services';
import GenericMessage from '../genericMessage/GenericMessage';
import GenericReply from '../genericReply/genericReply';
import NumberFormatSwal from '../newTask/NumberFormat';
import Form from '../common/form';
import PlissRating from '../plissRating/PlissRating';
import ContactInfo from '../taskDetail/contactInfo';
import { minBudget, maxBudget } from '../common/params.json';
import { formatBudget } from '../common/utils';
import logger from '../common/services/logService';

const messageRangeValueBudget = `El valor debe estár entre ${formatBudget(
  minBudget,
)} y ${formatBudget(maxBudget)}`;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

class Offer extends Form {
  state = {
    isEditable: false,
    showResponse: false,
    enableReply: false,
    expanded: false,
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  classes = () => useStyles();

  activateResponse = () => {
    const showResponse = !this.state.showResponse;
    this.setState({ showResponse });
  };

  enableEditOffer = () => {
    this.setState((prevState) => ({ isEditable: !prevState.value }));
  };

  updateOfferDescription = async (editedOffer) => {
    const { taskId } = this.props;
    try {
      const res = await oService.updateOfferDescription(taskId, editedOffer);
      this.props.refreshTaskInfo(taskId);
      return res;
    } catch (error) {
      logger.log(error, { component: 'Offer' });
      Swal.fire({
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
        icon: 'error',
      });
      return {};
    }
  };

  handleOfferMessage = async (messageText) => {
    try {
      const { taskId } = this.props;
      const offerId = this.props.offer._id;
      await oService.createOfferMessage(taskId, offerId, messageText);
      if (!this.state.expanded) this.handleExpandClick();
      this.activateResponse();
      this.props.refreshTaskInfo(taskId);
    } catch (error) {
      logger.log(error, { component: 'Offer' });
      Swal.fire({
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
        icon: 'error',
      });
    }
  };

  handleOfferMessageReply = async (messageId, messageText) => {
    try {
      const { taskId } = this.props;
      const offerId = this.props.offer._id;
      await oService.createOfferMessageReply(taskId, offerId, messageId, messageText);
      this.setState({ enableReply: false });
      this.props.refreshTaskInfo(taskId);
    } catch (error) {
      logger.log(error, { component: 'Offer' });
      Swal.fire({
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
        icon: 'error',
      });
    }
  };

  handleEdit = async () => {
    this.handleClose();
    const that = this;
    that.setState({ swalCustomErrorValidation: false });
    const reSwal = withReactContent(Swal);

    reSwal
      .mixin({
        confirmButtonText: 'Siguiente &rarr;',
        confirmButtonColor: '#5078ff',
        showCancelButton: true,
        showCloseButton: true,
        background: '#fff',
        cancelButtonText: 'Cancelar',
        progressSteps: ['1', '2'],
        allowOutsideClick: false,
      })
      .queue([
        {
          text: 'Actualiza la información de la oferta que realizaste.',
          confirmButtonText: 'Siguiente &rarr;',
          inputAutoTrim: false,
          input: 'textarea',
          inputValue: this.props.offer.description,
          inputAttributes: {
            maxlength: 1000,
            minlength: 10,
          },

          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.length < 10 || value.length >= 1000) {
                resolve('La longitud mínima son 10 caracteres, la máxima 1000');
              } else {
                resolve();
              }
            });
          },
        },
        {
          html: (
            <>
              <div className="swal2-content">¿Cuál es tu nueva oferta?</div>
              <NumberFormatSwal value={this.props.offer.value} />{' '}
            </>
          ),
          preConfirm() {
            const swalMessage = document.createElement('div');
            swalMessage.className = 'swal2-validation-message';
            swalMessage.id = 'swal2-validation-message';
            swalMessage.setAttribute(
              'style',
              'display: flex; margin-left: -20px; margin-right: -20px;',
            );
            swalMessage.innerHTML = messageRangeValueBudget;
            const swalContent = document.getElementsByClassName('swal2-content')[0];
            const valueBudget = document
              .getElementById('formatted-numberformat-input')
              .value.replace(/\./g, '');
            if (!valueBudget || valueBudget < minBudget || valueBudget > maxBudget) {
              if (!that.state.swalCustomErrorValidation) swalContent.appendChild(swalMessage);

              that.setState({ swalCustomErrorValidation: true });
              return Promise.resolve(false);
            }
            that.setState({ swalCustomErrorValidation: false });
            return Promise.resolve(valueBudget);
          },
        },
      ])
      .then(async (result) => {
        const { value } = result;
        if (value) {
          const editedOffer = {
            id: this.props.offer._id,
            description: value[0],
            value: parseFloat(value[1].replace(/,/g, '')),
            taskId: this.props.taskId,
          };
          try {
            const res = await this.updateOfferDescription(editedOffer);
            if (res.status === 200) {
              reSwal.fire({
                icon: 'success',
                title: 'Excelente!',
                text: '¡Has editado tu oferta exitosamente!',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#5078ff',
              });
            } else {
              reSwal.fire({
                title: 'Oops...',
                text: 'Ha ocurrido un error, intenta más tarde.',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: '#5078ff',
                icon: 'error',
              });
            }
          } catch (error) {
            logger.log(error, { component: 'Offer' });
            reSwal.fire({
              title: 'Oops...',
              text: 'Ha ocurrido un error, intenta más tarde.',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#5078ff',
              icon: 'error',
            });
          }
        }
      });
  };

  handleDeleteAction = async () => {
    this.handleClose();
    Swal.fire({
      text: 'Estás seguro de eliminar esta oferta?',
      icon: 'question',
      cancelButtonText: 'Eliminar',
      showCancelButton: true,
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancelar',
    }).then(async (result) => {
      if (!result.value) {
        try {
          const updatedTask = await oService.deletetOffer(this.props.taskId, this.props.offer._id);
          if (updatedTask) {
            Swal.fire({
              title: 'Tu oferta ha sido eliminada',
              confirmButtonText: 'Aceptar',
              icon: 'success',
            });
          } else {
            Swal.fire({
              title: 'Oops...',
              text: 'Ha ocurrido un error, intenta más tarde.',
              confirmButtonText: 'Aceptar',
              icon: 'error',
            });
          }
          this.props.refreshTaskInfo(this.props.taskId);
        } catch (error) {
          Swal.fire({
            title: 'Oops...',
            text: 'Ha ocurrido un error, intenta más tarde.',
            confirmButtonText: 'Salir',
            icon: 'error',
          });
        }
      }
    });
  };

  acceptOffer = async () => {
    const { offer, taskId } = this.props;

    Swal.fire({
      text: `¿Quieres aceptar esta oferta de ${
        offer.userId.name
      }, por valor de: $${new Intl.NumberFormat().format(offer.value)} ?`,
      icon: 'question',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: '#5078ff',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
    }).then(async (result) => {
      if (result.value) {
        try {
          const updatedTask = await oService.acceptOffer(taskId, offer._id);
          if (updatedTask.data.state === 'Assigned') {
            Swal.fire({
              title: `Tu tarea ha sido asignada a ${offer.userId.name} exitosamente!`,
              text: `Revisa tu correo y ponte en contacto con ${offer.userId.name}, para solucionar la necesidad!`,
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#5078ff',
              icon: 'success',
            });
          } else {
            Swal.fire({
              title: 'Oops...',
              text: 'Ha ocurrido un error, intenta más tarde.',
              confirmButtonText: 'Aceptar',
              icon: 'error',
            });
          }
          this.props.refreshTaskInfo(taskId);
        } catch (error) {
          Swal.fire({
            title: 'Oops...',
            text: 'Ha ocurrido un error, intenta más tarde.',
            confirmButtonText: 'Aceptar',
            icon: 'error',
          });
        }
      }
    });
  };

  handleMessageReply = async (messageText) => {
    try {
      await this.props.replyAction(this.state.messageId, messageText);
      this.activateResponse();
    } catch (error) {
      logger.log(error, { component: 'Offer' });
      Swal.fire({
        title: 'Oops...',
        text: 'Ha ocurrido un error, intenta más tarde.',
        confirmButtonText: 'Aceptar',
        icon: 'error',
      });
    }
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { isTaskInfoEditable, offer, isTaskOwner, taskState, user } = this.props;
    const offerMessages = _.sortBy(offer.messages, 'created').reverse();
    const { showResponse, enableReply, expanded } = this.state;

    let isOfferOwner = false;
    if (user) isOfferOwner = offer.userId._id === user._id;

    return (
      <>
        <ListItem className="offer-container-father">
          <Card className="offer-container">
            <CardHeader
              avatar={
                <Avatar
                  component={Link}
                  to={`/PublicProfile/${offer.userId._id}`}
                  src={offer.userId ? offer.userId.avatar : null}
                  className="small-avatar"
                />
              }
              title={<Typography className="MuiTypography-body1">{offer.userId.name}</Typography>}
              subheader={
                <>
                  <PlissRating
                    size="small"
                    initialRating={offer.userId.workerRating || 0}
                    readOnly
                  />

                  <div>
                    Publicó <Moment fromNow>{offer.created}</Moment>:
                  </div>
                </>
              }
              action={
                <Typography className="offer-user">
                  {(isTaskOwner || isOfferOwner) && (
                    <>
                      <NumberFormat
                        value={offer.value}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="$"
                      />
                    </>
                  )}
                </Typography>
              }
            />
            <CardContent className="offer-description-container line-breaks">
              <Typography variant="body2" color="textSecondary">
                {offer.description}
                {isTaskInfoEditable && isTaskOwner && (
                  <Grid container justify="flex-end" alignItems="flex-start">
                    <CardButton
                      size="small"
                      onClick={() => this.activateResponse()}
                      className="offer-message-repply-button"
                    >
                      ENVIAR MENSAJE
                    </CardButton>
                  </Grid>
                )}
                {showResponse && (
                  <GenericReply
                    user={user}
                    saveAction={(text) => this.handleOfferMessage(text)}
                    successMessage="Mensaje enviado exitosamente!"
                    label={`Mensaje para ${offer.userId.name}`}
                    autoFocus
                  />
                )}
                {(isTaskOwner || isOfferOwner) && (
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {offerMessages.map((message) => (
                      <Grid key={message._id}>
                        <GenericMessage
                          user={user}
                          message={message}
                          enableReply={enableReply}
                          buttonLabel={`Responder a ${message.userId.name}`}
                          activateReply={isTaskInfoEditable && (isOfferOwner || isTaskOwner)}
                        >
                          <GenericReply
                            user={user}
                            saveAction={(text) => this.handleOfferMessageReply(message._id, text)}
                            successMessage="Respuesta enviada exitosamente!"
                            label={`Respuesta para ${message.userId.name}`}
                          />
                        </GenericMessage>
                      </Grid>
                    ))}
                  </Collapse>
                )}
              </Typography>
            </CardContent>
            {(isTaskOwner || isOfferOwner) && <Divider light className="separator" />}
            <CardActions className="cardActionsStyle">
              <Grid container>
                {isTaskOwner && taskState === 'Created' && offer.state !== 'Accepted' && (
                  <Grid item sm={6} xs={6}>
                    <Tooltip
                      placement="bottom"
                      title="Próximamente Habilitaremos los Pagos!"
                      disableTouchListener
                    >
                      <span>
                        <CardButton
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={this.acceptOffer}
                        >
                          ACEPTAR OFERTA{' '}
                        </CardButton>{' '}
                      </span>
                    </Tooltip>
                  </Grid>
                )}
                {isTaskOwner && offer.state !== 'Created' && (
                  <Grid item sm={6} xs={6}>
                    <span>
                      <ContactInfo userId={this.props.offer.userId._id} />
                    </span>
                  </Grid>
                )}
                {isOfferOwner && taskState === 'Created' && offer.state === 'Created' && (
                  <Grid item justify="center" sm={6} xs={6}>
                    {this.renderEditIcon()}
                    {this.renderDeleteIcon()}
                  </Grid>
                )}
                {(isTaskOwner || isOfferOwner) && (
                  <>
                    {!expanded && offer.messages && offer.messages.length > 0 && (
                      <Grid item sm={6} xs={6} className="right-aligned-content">
                        <CardButton size="small" onClick={this.handleExpandClick}>
                          Mensajes
                          <ExpandMoreIcon />
                        </CardButton>
                      </Grid>
                    )}
                    {expanded && offer.messages && offer.messages.length > 0 && (
                      <Grid item sm={6} xs={6} className="right-aligned-content">
                        <CardButton size="small" onClick={this.handleExpandClick}>
                          Ocultar
                          <ExpandLessIcon />
                        </CardButton>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </CardActions>
          </Card>
        </ListItem>
      </>
    );
  }
}

export default Offer;
