import React from 'react';
import Swal from 'sweetalert2';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import 'react-tabs/style/react-tabs.css';
import Moment from 'react-moment';
import 'moment/locale/es';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import LinearProgress from '@material-ui/core/LinearProgress';
import PubSub from 'pubsub-js';
import imageCompression from 'browser-image-compression';
import Loading from '../common/loading';
import * as pService from './services';
import Form from '../common/form';
import Footer from '../common/footer';
import logger from '../common/services/logService';
import * as userService from '../auth/services';
import profileSchema from './ProfileSchema';
// eslint-disable-next-line no-unused-vars
import UserContext from '../../contexts/user-context';
import PoliticsModal from './PoliticsModal';
import NewSkill from './NewSkill';

import FirebaseContext from '../../contexts/firebase-services';

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

class Profile extends Form {
  state = {
    data: {},
    errors: {},
    profile: {},
    ratingWorker: 0,
    ratingBoss: 0,
    photoUrl: '',
    displayName: '',
    eMail: '',
    avatarLoaderVisible: false,
    isLoading: true,
    dayOfMonth: [],
    yearSelected: [],
    languages: [],
    skills: [],
    isDoer: false,
  };

  schema = profileSchema.schema;

  async componentDidMount() {
    PubSub.publish('EVAL_NAVBAR', {});
    try {
      const profile = await pService.getProfile();
      profile.data.birthdateDay = new Date(profile.data.birthdate).getDate();
      let daysOfMonthInLoad = 1;
      const currentProfileMonth = new Date(profile.data.birthdate).getMonth() + 1;

      if (profile.data.birthdate) daysOfMonthInLoad = currentProfileMonth;
      profile.data.birthdateMonth = currentProfileMonth;
      profile.data.birthdateYear = new Date(profile.data.birthdate).getFullYear();
      this.setState({ isLoading: false });
      this.setState({
        data: profile.data,
        photoUrl: profile.data.avatar,
        ratingBoss: profile.data.bossRating,
        ratingWorker: profile.data.workerRating,
        displayName: profile.data.name,
        eMail: profile.data.email,
        memberSince: new Date(parseInt(profile.data._id.substring(0, 8), 16) * 1000),
        renderRatingBoss: false,
        renderRatingWorker: false,
        languages: profile.data.languages,
        skills: profile.data.skills,
        isDoer: profile.data.isDoer,
      });

      if (this.state.ratingBoss > 0) this.setState({ renderRatingBoss: true });
      if (this.state.ratingWorker > 0) this.setState({ renderRatingWorker: true });

      profile.data.identification = profile.data.identification
        ? `${profile.data.identification}`
        : '';

      delete profile.data.email;
      delete profile.data.avatar;
      delete profile.data.rating;
      delete profile.data._id;
      delete profile.data.bossRating;
      delete profile.data.workerRating;
      delete profile.data.assignedTasks;
      delete profile.data.reviews;
      delete profile.data.__v;
      delete profile.data.isAdmin;
      delete profile.data.facebook;
      delete profile.data.isBoss;
      delete profile.data.isTasker;
      delete profile.data.offers;
      delete profile.data.password;
      delete profile.data.myTasksBadge;
      delete profile.data.myAssignedTasksBadge;
      delete profile.data.city;
      delete profile.data.selfDescription;
      delete profile.data.career;

      this.daysCalculation(daysOfMonthInLoad);
    } catch (error) {
      logger.log(error, { component: 'Profile' });
    }
  }

  handleRedirect = (target) => {
    this.props.history.push(target);
  };

  handleIsDoer = () => {
    this.setState((prevState) => ({ isDoer: !prevState.isDoer }));
  };

  doSubmit = async () => {
    const { analytics } = this.context;

    try {
      const apiData = { ...this.state.data };

      apiData.birthdate = new Date(
        this.state.data.birthdateYear,
        this.state.data.birthdateMonth - 1,
        this.state.data.birthdateDay,
      );

      delete apiData.birthdateDay;
      delete apiData.birthdateMonth;
      delete apiData.birthdateYear;

      apiData.isDoer = this.state.isDoer;
      const user = await pService.putProfile(apiData);
      analytics.logEvent('event', 'update_profile');

      if (user.data.identification) localStorage.setItem('isRegU', 'true');

      Swal.fire({
        text: 'Datos Almacenados. Desea ir a la lista de tareas publicadas?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Ir',
        cancelButtonText: 'Quedarse',
        cancelButtonColor: '',
      }).then((result) => {
        if (result.value) {
          this.handleRedirect('/tasks');
        }
      });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
        Toast.fire({
          icon: 'error',
          title: '¡Información no actualizada, intenta mas tarde!',
        });
      }
      logger.log(err, { component: 'Profile' });
    }
  };

  handleFile = async (event) => {
    const photo = event.target.files[0];
    if (photo && photo.size < 10240000) {
      const formData = new FormData();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
        onProgress: () => {
          this.setState({ avatarLoaderVisible: true });
        },
      };
      try {
        this.setState({ avatarLoaderVisible: true });
        const compressedFile = await imageCompression(photo, options);
        formData.append('image', compressedFile);
        const result = await pService.postNewProfilePhoto(formData);
        this.setState({ photoUrl: result.data.detail });
        this.setState({ avatarLoaderVisible: false });
        PubSub.publish('UPDATE_NAVBAR_PHOTO', result.data.detail);
        localStorage.setItem('photo', result.data.detail);
      } catch (error) {
        logger.log(error, { component: 'Profile' });
        this.setState({ avatarLoaderVisible: false });
      }
    } else if (photo !== undefined) {
      Swal.fire({
        icon: 'info',
        title: 'Importante!',
        text: 'El tamaño del archivo no debe superar 10 mb!',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  daysCalculation = (month) => {
    const days = [];
    const daysofMonth = new Date(this.state.yearSelected, month, 0).getDate();
    for (let i = 1; i <= daysofMonth; i += 1) days.push(i);
    this.setState({ dayOfMonth: days });
    return days;
  };

  getMonths = () => {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
  };

  getYears = () => {
    const years = [];
    const initialYear = new Date().getFullYear();
    for (let i = initialYear - 70; i <= initialYear - 18; i += 1) years.push(i);
    return years.reverse();
  };

  render() {
    const { _id: userid } = userService.getCurrentUser();

    return (
      <>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <Grid container justify="center">
            <Grid item>
              <div className="profile-paper">
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Paper className="profile-section">
                      <Grid container alignItems="center" justify="center" spacing={8}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={5}
                          className="profile-mobile-top-container"
                        >
                          <Typography variant="h6">Perfil</Typography>
                          <div className="photo-profile-c">
                            <Avatar className="profile-avatar" src={this.state.photoUrl} />
                            {this.state.avatarLoaderVisible && (
                              <LinearProgress className="avatar-progress" />
                            )}
                            <div
                              className="change-photo"
                              onClick={() => {
                                const el = document.getElementById('fileElem');
                                if (el) {
                                  el.click();
                                }
                              }}
                            >
                              <div className="change-logo-container">
                                <AddAPhotoIcon className="change-icon" />
                                <input
                                  type="file"
                                  id="fileElem"
                                  accept=".jpeg, .jpg, .png"
                                  style={{ display: 'none' }}
                                  onChange={this.handleFile}
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={7}
                          md={7}
                          lg={7}
                          className="profile-mobile-bottom-container"
                        >
                          <Grid
                            container
                            spacing={8}
                            alignItems="flex-end"
                            direction="row"
                            className="profile-header-container"
                          >
                            <Grid item className="profile-header-info">
                              <Grid container direction="column" alignItems="center">
                                <Grid item>
                                  <Typography variant="subtitle1">{this.state.eMail}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="caption">
                                    Miembro <Moment fromNow>{this.state.memberSince}</Moment>
                                  </Typography>
                                </Grid>
                                <Grid item style={{ padding: '10px' }}>
                                  <Typography variant="caption">
                                    <Link
                                      href={`/PublicProfile/${userid}`}
                                      variant="caption"
                                      color="primary"
                                    >
                                      <b>Ver Perfil público</b>
                                    </Link>
                                  </Typography>
                                </Grid>
                                {this.state.renderRatingBoss && (
                                  <Grid
                                    item
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    className="calification-line"
                                  >
                                    <Grid item>
                                      <Typography variant="caption">
                                        {this.state.ratingBoss.toFixed(1)}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="caption">
                                        <FontAwesomeIcon icon={faStar} size="1x" color="#01d494" />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="caption">Publicador</Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                {this.state.renderRatingWorker && (
                                  <Grid
                                    item
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    className="calification-line"
                                  >
                                    <Grid item>
                                      <Typography variant="caption">
                                        {this.state.ratingWorker.toFixed(1)}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="caption">
                                        <FontAwesomeIcon icon={faStar} size="1x" color="#01d494" />
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography variant="caption">Solucionador</Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Paper className="profile-section profile-basic">
                      <Grid container>
                        <Typography variant="h6">Datos Básicos</Typography>

                        <form className="profile-form">
                          <FormControl>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12} md={6}>
                                {this.renderInput(
                                  'name',
                                  'Nombres*',
                                  'text',
                                  undefined,
                                  '',
                                  false,
                                  true,
                                  true,
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                {this.renderInput(
                                  'lastName',
                                  'Apellidos*',
                                  'text',
                                  undefined,
                                  '',
                                  false,
                                  true,
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} style={{ padding: '0 0 0 20px' }}>
                                <Typography variant="caption">Fecha de Nacimiento*</Typography>
                              </Grid>
                              <Grid item xs={3} sm={3} md={3}>
                                {this.renderSelect('birthdateDay', 'Dia', this.state.dayOfMonth)}
                              </Grid>
                              <Grid item xs={5} sm={5} md={5}>
                                {this.renderSelect(
                                  'birthdateMonth',
                                  'Mes',
                                  this.getMonths(),
                                  this.daysCalculation,
                                )}
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                {this.renderSelect('birthdateYear', 'Año', this.getYears())}
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                {this.renderInput(
                                  'phone',
                                  'Celular*',
                                  '',
                                  '',
                                  '',
                                  false,
                                  '',
                                  false,
                                  true,
                                )}
                              </Grid>
                              <Grid item xs={12} className="radio-genre">
                                {this.renderRadio('sex', 'Genero*', [
                                  { label: 'Masculino', value: 'M' },
                                  { label: 'Femenino', value: 'F' },
                                ])}
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                {this.renderSelect('identificationType', 'Tipo*', ['CC'])}
                              </Grid>
                              <Grid item xs={12} sm={9}>
                                {this.renderInput('identification', 'Número Identificación*')}
                              </Grid>
                              <Grid item xs={12} className="profile-margin-input">
                                {this.renderInput(
                                  'address',
                                  'Dirección (Linea 1)*',
                                  'text',
                                  '',
                                  'Calle / Carrera / Avenida',
                                )}
                              </Grid>
                              <Grid item xs={12} md={12}>
                                {this.renderInput(
                                  'addressComplementary',
                                  'Dirección (Linea 2)',
                                  'text',
                                  '',
                                  'Unidad / Apto / Torre / Bloque (Opcional)',
                                )}
                              </Grid>
                              <Grid item xs={12} md={12}>
                                {this.renderInput('addressNeighborhood', 'Barrio*', 'text')}
                              </Grid>
                              <Grid item xs={12} md={12}>
                                {this.renderInput('cityAddress', 'Ciudad*', 'text')}
                              </Grid>
                            </Grid>
                          </FormControl>
                        </form>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6} className="profile-section-panel">
                    <Paper className="profile-section complementary-paper">
                      <Typography variant="h6">Datos Complementarios</Typography>
                      <Grid item>
                        <Grid container alignItems="flex-end">
                          <Grid item xs={12}>
                            <Typography>¿Te interesa ser solucionador?</Typography>
                            <Switch
                              checked={this.state.isDoer}
                              onChange={this.handleIsDoer}
                              color="primary"
                              name="checkedB"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            {this.state.isDoer && (
                              <form className="profile-form">
                                <FormControl margin="normal" fullWidth spacing={8}>
                                  <Grid container spacing={3}>
                                    <Grid item>
                                      <Typography variant="subtitle1" gutterBottom color="primary">
                                        Estos datos serán públicos para quienes quieran conocer de
                                        ti, es importante que no pongas datos confidenciales.
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      {this.renderMultilineInput(
                                        'proffExperience',
                                        'Experiencia',
                                        '',
                                        '',
                                        'Comparte tu experiencia laboral y/o profesional.',
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      {this.renderMultiValue(
                                        this.state.languages,
                                        10,
                                        'Idiomas',
                                        'Idiomas',
                                        'languages',
                                        async () => {
                                          const options = await pService.getLanguages();
                                          return options;
                                        },
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      {this.renderMultiValue(
                                        this.state.skills,
                                        10,
                                        'Profesión, Oficio y Habilidades',
                                        'Habilidades',
                                        'skills',
                                        async () => {
                                          const options = await pService.getSkills();
                                          return options;
                                        },
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <NewSkill
                                        user={this.state.data.name}
                                        email={this.state.eMail}
                                      />
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </form>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container className="button-container">
                  <Grid item xs={12} sm={8} md={6}>
                    <FormControl margin="normal" required fullWidth>
                      {this.renderButton('Guardar Cambios')}
                    </FormControl>
                    <PoliticsModal />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}

        <Footer />
      </>
    );
  }
}

export default Profile;

Profile.contextType = FirebaseContext;
