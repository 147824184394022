import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Typography from '@material-ui/core/Typography';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function AboutUs() {
  AOS.init();

  return (
    <>
      <Grid
        container
        justify="center"
        style={{ marginTop: '90px' }}
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h3" className="team-title-main">
            <Box fontWeight="fontWeightBold">Nuestro Equipo</Box>
          </Typography>
        </Grid>
        <Grid item container className="team">
          <Grid item data-aos="fade-up-left">
            <Grid className="team_member">
              <Grid className="team_img">
                <img
                  src="https://pliss-assets.s3.amazonaws.com/home/avatar_juan.png"
                  alt="Team_image_Juan"
                />
              </Grid>
              <Typography variant="h3">Juan Jaramillo</Typography>
              <Typography className="role">CO Founder</Typography>
              <Typography gutterBottom>Ingeniero Informático </Typography>
              <Typography gutterBottom>AWS Cloud Practitioner</Typography>
              <Grid>
                <LinkedInIcon style={{ color: '#0072b1' }} />
                <Typography style={{ display: 'inline-block', transform: 'translateY(-6px)' }}>
                  @juanf515
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item data-aos="fade-up" data-aos-delay="200">
            <Grid className="team_member">
              <Grid className="team_img">
                <img
                  src="https://pliss-assets.s3.amazonaws.com/home/avatar_alejo.png"
                  alt="Team_image_Alejo"
                />
              </Grid>
              <Typography variant="h3">Alejandro Arias</Typography>
              <Typography className="role">CO Founder</Typography>
              <Typography gutterBottom>Ingeniero de Sistemas</Typography>
              <Typography gutterBottom>Esp. Admon de Empresas</Typography>
              <Grid>
                <LinkedInIcon style={{ color: '#0072b1' }} />
                <Typography style={{ display: 'inline-block', transform: 'translateY(-6px)' }}>
                  @alejoarias
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item data-aos="fade-up-right" data-aos-delay="450">
            <Grid className="team_member">
              <Grid className="team_img">
                <img
                  src="https://pliss-assets.s3.amazonaws.com/home/avatar_daniel.png"
                  alt="Team_image_Daniel"
                />
              </Grid>
              <Typography variant="h3">Daniel Rico</Typography>
              <Typography className="role">CO Founder</Typography>
              <Typography gutterBottom>Ingeniero de Sistemas y Computación</Typography>
              <Typography gutterBottom>AWS Solutions Architect</Typography>
              <Grid>
                <LinkedInIcon style={{ color: '#0072b1' }} />
                <Typography style={{ display: 'inline-block', transform: 'translateY(-6px)' }}>
                  @danielrico
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" className="team-title-main" gutterBottom>
            <Box fontWeight="fontWeightBold">Nuestra Historia</Box>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Paper style={{ padding: '20px', width: '100%' }}>Próximamente</Paper>
        </Grid>
      </Grid>
    </>
  );
}
