import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Chip from '@material-ui/core/Chip';
import PanToolIcon from '@material-ui/icons/PanTool';
import BuildIcon from '@material-ui/icons/Build';

import { useMediaQuery } from 'react-responsive';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Swal from 'sweetalert2';
import moment from 'moment';
import Loading from '../common/loading';
import * as tService from '../taskDetail/services';
import * as pService from '../browse/services';
import Footer from '../common/footer';
import 'moment/locale/es';

const localizer = momentLocalizer(moment);

const messages = {
  allDay: '',
  next: '>',
  previous: '<',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  time: 'Tiempo',
  date: 'Fecha',
  event: 'Tarea',
  noEventsInRange: 'No hay tareas en este rango de fechas...',
  showMore: (total) => `+ ver más (${total})`,
};

const CalendarView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventsCollection, setEventsCollection] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const handleSelectedEvent = (event) => {
    let taskDetail;
    Swal.fire({
      icon: 'info',
      title: 'Cargando Datos...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: async () => {
        Swal.showLoading();
        taskDetail = await tService.getTask(event.taskId);
        Swal.fire({
          icon: 'info',
          title: taskDetail.data.title,
          text: taskDetail.data.description,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const mapTasktoEventsCategory = (tasks, category) => {
    if (tasks.data && tasks.data.length > 0) {
      const eventsCreated = tasks.data.map((task) => {
        const evento = {
          id: 1,
          title: task.title,
          start: moment(task.dueDate).add(1, 'days'),
          end: moment(task.dueDate).add(1, 'days'),
          taskId: task._id,
          allDay: true,
          category,
        };
        return evento;
      });
      return eventsCreated;
    }
    return [];
  };

  useEffect(() => {
    const loadTasks = async () => {
      const userTasks = await pService.getUserTasks(100, 1);
      const eventsCreated = mapTasktoEventsCategory(userTasks, 'published');

      const tasksAssigned = await pService.getUserAssignedTasks(100, 1);
      const eventsAssignned = mapTasktoEventsCategory(tasksAssigned, 'assigned');

      const events = eventsCreated.concat(eventsAssignned);

      setEventsCollection(events);
      setIsLoading(false);
    };
    loadTasks();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container justify="center" style={{ marginTop: '90px' }}>
          <Grid id="calendar" item>
            <Paper className="paper-calendar">
              <Grid className="chip-calendar">
                <Chip
                  icon={<PanToolIcon style={{ fill: 'white' }} />}
                  label="Posteador"
                  className="chip-worker"
                />
                <Chip
                  icon={<BuildIcon style={{ fill: 'white' }} />}
                  label="Solucionador"
                  className="chip-boss"
                />
              </Grid>
              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                popup
                step={60}
                defaultView={isTabletOrMobile ? 'agenda' : 'month'}
                events={eventsCollection}
                onSelectEvent={(event) => handleSelectedEvent(event)}
                format="DD/MM/YYYY HH:mm"
                className="calendar-view"
                messages={messages}
                eventPropGetter={(event) => ({
                  className: `category-${event.category}`,
                })}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Footer />
    </>
  );
};

export default CalendarView;
