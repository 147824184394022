const bannedWords = [
  '',
  'asesin',
  'bastard',
  'clitoris',
  'clítoris',
  'coño',
  'consolador',
  'culiar',
  'culo',
  'drogas',
  'escroto',
  'esmegma',
  'felacion',
  'felación',
  'follar',
  'guerra',
  'hacerse una paja',
  'imbecil',
  'imbécil',
  'maldita sea',
  'malparid',
  'mamada',
  'mamar',
  'marica',
  'maricon',
  'maricón',
  'matar',
  'mierda',
  'modelo webcam',
  'nigga',
  'orinar',
  'pene',
  'polla',
  'prostitu',
  'puta',
  'sangrient',
  'semen',
  'sexo',
  'sicario',
  'teta',
  'vagina',
  'babilla',
  'carechimba',
  'caremonda',
  'chiviado',
  'cipote ',
  'culicagado',
  'culicagao',
  'garbimba',
  'gonorrea',
  'güevón',
  'hijueputa',
  'huevón',
  'machete',
  'mamert',
  'marica',
  'pecueca',
  'pirobo',
  'vagina',
  'zunga',
  '',
];

function validate(props) {
  return props.match(`/${bannedWords.join('|')}/g`) != null;
}

function formatBudget(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);
}

export { validate, formatBudget };
