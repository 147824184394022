import http from '../common/services/httpService';
import { apiEndpoint } from '../../config.json';

import { getCurrentUser } from '../auth/services';

export function getProfile() {
  return http.get(`${apiEndpoint}/users/me`);
}

export function getPublicProfile(userId) {
  return http.get(`${apiEndpoint}/users/publicProfile/${userId}`);
}

export function getSkills() {
  return http.get(`${apiEndpoint}/skills`);
}

export function getLanguages() {
  return http.get(`${apiEndpoint}/users/languages`);
}

export function getUserBossReviews(userId) {
  return http.get(`${apiEndpoint}/reviews/userBossReviews/${userId}`);
}

export function getUserWorkerReviews(userId) {
  return http.get(`${apiEndpoint}/reviews/userWorkerReviews/${userId}`);
}

export function putProfile(userData) {
  const user = getCurrentUser();
  // eslint-disable-next-line no-underscore-dangle
  return http.put(`${apiEndpoint}/users/${user._id}`, userData);
}

export function postReview(reviewData, taskId) {
  return http.post(`${apiEndpoint}/reviews/${taskId}`, reviewData);
}

export function postNewProfilePhoto(data) {
  return http.post(`${apiEndpoint}/users/photo`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function postSkill(title) {
  return http.post(`${apiEndpoint}/skills/createSkill`, title);
}
