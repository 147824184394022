import React from 'react';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';

export default function TaskStepper(props) {
  const steps = ['Nueva', 'Asignada', 'Ejecutada', 'Confirmada', 'Finalizada'];
  const { taskState } = props;

  const getActiveStep = (step) => {
    switch (step) {
      case 'Assigned':
        return 1;
      case 'ReAsigned':
        return 2;
      case 'Executed':
        return 3;
      case 'Verified':
        return 4;
      case 'Finished':
        return 5;
      case 'Cancelled':
        return 7;
      default:
        return 0;
    }
  };

  return (
    <Grid container>
      <Stepper alternativeLabel activeStep={getActiveStep(taskState)} className="t-detail-steper">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <div className="t-detai-steper-label">{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}

TaskStepper.propTypes = {
  taskState: PropTypes.string.isRequired,
};
