import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Swal from 'sweetalert2';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import 'moment/locale/es';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CardButton from '@material-ui/core/Button';
import * as tService from './services';
import Form from '../common/form';
import MessageReply from '../messageReply/MessageReply';
import GenericReply from '../genericReply/genericReply';
import logger from '../common/services/logService';

class Message extends Form {
  state = {
    data: { text: '' },
    isEditable: false,
    showResponse: false,
  };

  resetState = () => {
    this.setState({ isEditable: false });
    this.setState({ showResponse: false });
  };

  editMessage = () => {
    const isEditable = !this.state.isEditable;
    this.setState({ isEditable });
  };

  activateResponse = () => {
    const showResponse = !this.state.showResponse;
    this.setState({ showResponse });
    window.scroll(0, 100);
  };

  handleMessageReply = async (reply) => {
    try {
      const { taskId } = this.props;
      const messageId = this.props.message._id;
      await tService.postMessageReply(taskId, messageId, reply);
      this.activateResponse();
      await this.props.refreshTaskInfo(taskId);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = err.response.data.error;
        this.setState({ errors });
      }
      logger.log(err, { component: 'Message' });
    }
  };

  updateMessage = async (text) => {
    try {
      const { taskId } = this.props;
      const messageId = this.props.message._id;
      const message = text;
      await tService.updateMessage(taskId, messageId, message);
      await this.props.refreshTaskInfo(taskId);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'Mesaje actualizado!',
      });
      this.resetState();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = err.response.data.error;
        this.setState({ errors });
      }
      logger.log(err, { component: 'Message' });
    }
  };

  render() {
    const { isTaskOwner, message, user, isTaskInfoEditable } = this.props;
    const userId = user ? user.userId : null;
    const { isEditable, showResponse } = this.state;
    const isMessageOwner = userId === message.userId._id;
    const sorted = _.sortBy(message.replies, 'created');

    return (
      <>
        <Divider variant="fullWidth" component="li" />
        <ListItem key={message._id} dense className="t-detail-comments-container">
          <ListItemAvatar>
            <Avatar
              component={Link}
              to={`/PublicProfile/${message.userId ? message.userId._id : null}`}
              className="small-avatar"
              src={message.userId ? message.userId.avatar : null}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography component="span" color="secondary" variant="subtitle2">
                  {message.userId.name}&nbsp;
                </Typography>
                &nbsp;dijo&nbsp;
                <Moment fromNow className=".m-time-stamp-message">
                  {message.created}
                </Moment>
                &nbsp;:
              </>
            }
            secondary={message.text}
          />
          {isTaskInfoEditable && isMessageOwner && !isEditable && (
            <ListItemSecondaryAction>
              <EditIcon onClick={this.editMessage} />
            </ListItemSecondaryAction>
          )}
        </ListItem>
        {sorted.map((reply) => (
          <MessageReply
            key={reply._id}
            reply={reply}
            messageId={this.props.message._id}
            user={user}
            taskId={this.props.taskId}
            refreshTaskInfo={this.props.refreshTaskInfo}
            isTaskInfoEditable={isTaskInfoEditable}
          />
        ))}
        {user && user.isRegistered && isTaskInfoEditable && isTaskOwner && (
          <Grid container justify="flex-end" alignItems="flex-start">
            <CardButton
              size="small"
              onClick={this.activateResponse}
              className="offer-message-repply-button"
            >
              RESPONDER A {message.userId.name}
            </CardButton>
          </Grid>
        )}
        {user && showResponse && isTaskInfoEditable && (
          <GenericReply
            user={user}
            saveAction={(text) => this.handleMessageReply(text)}
            successMessage="Respuesta enviada exitosamente!"
            label={`Respuesta para ${message.userId.name}`}
            focus
          />
        )}
      </>
    );
  }
}

export default Message;
