import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import getDoers from './services';

const columns = [
  { id: 'identification', label: 'Cédula', minWidth: 30 },
  { id: 'name', label: 'Nombre', minWidth: 100 },
  {
    id: 'phone',
    label: 'Celular',
    minWidth: 100,
  },
  {
    id: 'cityAddress',
    label: 'Ciudad',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Correo',
    minWidth: 100,
  },
  {
    id: 'skills',
    label: 'Habilidades',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

export default function DoersReport() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    (async () => {
      const response = await getDoers(page + 1, rowsPerPage);
      setCount(response.data.size);
      setRows(response.data.users);
    })();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper className={classes.root} style={{ 'margin-top': '5em' }}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.id === 'skills' && value !== '') {
                          let arrValue = '';
                          value.map((item) => {
                            arrValue += `${item}, `;
                            return arrValue;
                          });
                          value = arrValue.substr(0, arrValue.length - 2);
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
