import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Zoom from '@material-ui/core/Zoom';
import Avatar from '@material-ui/core/Avatar';
import NumberFormat from 'react-number-format';
import CalendarToday from '@material-ui/icons/CalendarToday';
import LocationCity from '@material-ui/icons/LocationCity';
import LocationRemote from '@material-ui/icons/FilterDrama';
import Chip from '@material-ui/core/Chip';

import Badge from '@material-ui/core/Badge';

import Moment from 'react-moment';

import 'moment/locale/es';

class Task extends Component {
  state = {
    taskStateCaption: null,
  };

  getTaskStyle = () => {
    const taskState = this.props.task.state;
    let style = '';
    if (
      taskState === 'Assigned' ||
      taskState === 'Executed' ||
      taskState === 'ReAsigned' ||
      taskState === 'Verified'
    ) {
      style = 'browse-item-assigned';
    } else if (taskState === 'Finished') {
      style = 'browse-item-finished';
    } else if (taskState === 'Cancelled') {
      style = 'browse-item-cancelled';
    } else {
      style = 'browse-item';
    }
    if (this.props.task.isSelected) return `${style}-selected`;
    return style;
  };

  render() {
    let enableBadge = false;
    const {
      task,
      delay,
      displayBadge,
      onDetail,
      myTasksBadge,
      myAssignedTasksBadge,
      taskSelected,
    } = this.props;
    const { xs, sm, md } = this.props.mQueriesValues;
    let style = this.getTaskStyle();
    let displayState = null;

    if (task._id === taskSelected) style += '-selected';

    if (task.state === 'Created') {
      displayState = 'Nueva';
    } else if (
      task.state === 'Assigned' ||
      task.state === 'Executed' ||
      task.state === 'Verified' ||
      task.state === 'ReAsigned' ||
      task.state === 'Payed'
    ) {
      displayState = 'Asignada';
    } else if (task.state === 'Finished') {
      displayState = 'Finalizada';
    } else if (task.state === 'Cancelled') {
      displayState = 'Cancelada';
    }

    if (myAssignedTasksBadge) {
      if (myAssignedTasksBadge.includes(task._id) || myTasksBadge.includes(task._id))
        enableBadge = true;
    }

    let showBadge = (
      <Typography variant="caption">
        {displayState} |{' '}
        <Badge color="primary" badgeContent="!">
          {task.offers ? task.offers.length : 0} Ofertas
        </Badge>
      </Typography>
    );

    if (!displayBadge || !enableBadge)
      showBadge = (
        <Typography variant="caption">
          <Chip
            className={`task-chip-state-${displayState}`}
            label={displayState}
            size="small"
            variant="outlined"
          />
          <span style={{ marginLeft: '5px' }}>{task.offers ? task.offers.length : 0} Ofertas</span>
        </Typography>
      );

    return (
      <Grid item xs={xs} sm={sm} md={md} key={task._id} className="browser-item-gc">
        <Zoom in style={{ transitionDelay: `${delay}ms` }}>
          <Paper className={`browse-item ${style}`} elevation={4} onClick={onDetail}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={12} className="browse-item-container">
                <Typography variant="h6" className="task-title" style={{ marginLeft: '3px' }}>
                  {task.title}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item sm={10}>
                <Grid container alignItems="center" className="task-caption" spacing={1}>
                  <Grid item sm={1}>
                    <CalendarToday className="task-calendar" />
                  </Grid>
                  <Grid item sm={11}>
                    <Typography variant="caption">
                      <Moment utc format="DD MMM YYYY">
                        {task.dueDate}
                      </Moment>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" className="task-caption" spacing={1}>
                  <Grid item sm={1}>
                    {task.isRemote && <LocationRemote className="task-calendar" />}
                    {!task.isRemote && <LocationCity className="task-calendar" />}
                  </Grid>
                  <Grid item sm={11}>
                    {task.isRemote && <Typography variant="caption">Tarea Remota</Typography>}
                    {!task.isRemote && (
                      <Typography variant="caption">{task.addressNeighborhood}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={1} className="avatar-container">
                <Avatar
                  style={{ marginRight: '5px' }}
                  className="small-avatar"
                  src={task.userId ? task.userId.avatar : null}
                />
              </Grid>
            </Grid>

            <Divider light className="separator" />

            <Grid container spacing={1} justify="space-between">
              <Grid item sm={6} className="price-container" style={{ alignSelf: 'center' }}>
                {showBadge}
              </Grid>
              <Grid item sm={6} className="price-container">
                <Typography variant="h6" align="right">
                  <NumberFormat
                    value={task.budget}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Zoom>
      </Grid>
    );
  }
}

export default Task;
