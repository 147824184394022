import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { Redirect, useHistory } from 'react-router-dom';
import NewTask from '../../newTask/NewTask';
import MobileNotifications from './MobileNotifications';

export default function LabelBottomNavigation(props) {
  const history = useHistory();
  const [value, setValue] = React.useState('tareas');
  const [open, setOpen] = React.useState(false);
  const TASKS = 'tasks';
  const PROFILE = 'profile';

  function handleChange(evt, newValue) {
    setValue(newValue);
  }

  const handleDialog = () => {
    setOpen(!open);
  };

  return (
    <>
      {value === TASKS && <Redirect to="/tasks" />}
      {value === PROFILE && <Redirect to="/myaccount/profile" />}

      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        className="mobile-bottom-menu"
      >
        <BottomNavigationAction
          className="mobile-bottom-icons"
          label="Tareas"
          value={TASKS}
          icon={<AssignmentOutlinedIcon />}
        />
        <NewTask user={props.user} history={history} onTaskCreated={props.onTaskCreated} onBar />
        <BottomNavigationAction
          className="mobile-bottom-icons mobile-notification-icon"
          label="Notificaciones"
          onClick={handleDialog}
          icon={<MobileNotifications open={open} {...props} />}
        />
      </BottomNavigation>
    </>
  );
}
