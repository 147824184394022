import { Component } from 'react';
import firebase from 'firebase/app';
import { logout } from '../auth/services';

class Logout extends Component {
  componentDidMount() {
    logout();
    firebase.auth().signOut();
    window.location = '/';
  }

  render() {
    return null;
  }
}

export default Logout;
