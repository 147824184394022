import http from './httpService';
import { apiEndpoint } from '../../../config.json';

export function search(value) {
  return http.get(`${apiEndpoint}/tasks?s=${value}`);
}

export default {
  search,
};
