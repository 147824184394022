import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PubSub from 'pubsub-js';
import LandingNavBar from '../home/LandingNavBar';
import NavBar from '../common/NavBar';

export default function ContextNavbar() {
  const history = useHistory();
  const [homeMenuAvailable, setHomeMenuAvailable] = useState(false);
  const [appMenuAvailable, setAppMenuAvailable] = useState(false);

  useEffect(() => {
    evalCurrentNavBar();
    const token = PubSub.subscribe('EVAL_NAVBAR', evalCurrentNavBar);
    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  const evalCurrentNavBar = () => {
    const location = history.location.pathname;

    if (location === '/') {
      setHomeMenuAvailable(true);
      setAppMenuAvailable(false);
    } else {
      setAppMenuAvailable(true);
      setHomeMenuAvailable(false);
    }
  };

  return (
    <>
      {homeMenuAvailable && <LandingNavBar />}
      {appMenuAvailable && <NavBar history={history} />}
    </>
  );
}
