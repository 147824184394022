import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

export default function NotificationItems(props) {
  const [notificationsList] = React.useState(props.NotificationsList);

  return (
    <div>
      {props.isMobile && notificationsList?.length === 0 && (
        <Grid container justify="center">
          <div style={{ width: '100%' }}>
            <Alert severity="info">No tienes notificaciones.</Alert>
          </div>
          <img
            src="https://pliss-assets.s3.amazonaws.com/common/EmptyNotifications.png"
            type="image/svg+xml"
            style={{ width: '100%' }}
            alt="No tienes notificaciones."
          />
        </Grid>
      )}

      {!props.isMobile && notificationsList?.length === 0 && (
        <div style={{ width: '228px' }}>
          <Alert severity="info">No tienes notificaciones.</Alert>
        </div>
      )}

      <Grid>
        {props.isMobile &&
          notificationsList &&
          notificationsList.map((notification) => (
            <div
              key={notification._id}
              onClick={() => {
                props.viewNotification(
                  notification.taskId._id,
                  notification.type,
                  notification.state,
                );
                props?.handleClose();
              }}
            >
              <Card style={{ margin: 3 }}>
                <CardHeader
                  className={
                    notification.state === 'Created'
                      ? 'notifications-card'
                      : 'notifications-viewed-card'
                  }
                  avatar={<Avatar className="assigment-not" src={notification.userId.avatar} />}
                  title={`${notification.userId.name}, ${notification.text}`}
                  subheader={notification.taskId.title}
                />
              </Card>
            </div>
          ))}

        {!props.isMobile &&
          notificationsList &&
          notificationsList.map((notification) => (
            <MenuItem
              className={
                notification.state === 'Created'
                  ? 'notifications-items'
                  : 'notifications-viewed-item'
              }
              alignItems="flex-start"
              key={notification._id}
              onClick={() => {
                props.viewNotification(
                  notification.taskId._id,
                  notification.type,
                  notification.state,
                );
                props?.handleClose();
              }}
            >
              <ListItemAvatar>
                <Avatar className="assigment-not" src={notification.userId.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Typography />
                    {notification.userId.name}: {notification.text}
                  </>
                }
                secondary={
                  <>
                    <Typography />
                    {notification.taskId.title}
                  </>
                }
              />
            </MenuItem>
          ))}
      </Grid>
    </div>
  );
}
