import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as pService from './services';
import Footer from '../common/footer';
import 'moment/locale/es';
import Loading from '../common/loading';
import PublicProfileCard from './PublicProfileCard';
import PublicProfileInfo from './PublicProfileInfo';
import logger from '../common/services/logService';

class PublicProfile extends Component {
  state = {
    rating: 0,
    photoUrl: '',
    displayName: '',
    displayLastName: '',
    bossReviews: [],
    workerReviews: [],
    reviews: [],
    ratingWorker: 0,
    ratingBoss: 0,
    isLoading: true,
  };

  async componentDidMount() {
    try {
      const userId = this.props.match.params.id;
      const { data: profile } = await pService.getPublicProfile(userId);
      const { data: bossReviews } = await pService.getUserBossReviews(userId);
      const { data: workerReviews } = await pService.getUserWorkerReviews(userId);

      this.setState({
        photoUrl: profile.avatar,
        displayName: profile.name,
        displayLastName: profile.lastName,
        memberSince: new Date(parseInt(userId.substring(0, 8), 16) * 1000),
        selfDescription: profile.selfDescription,
        proffExperience: profile.proffExperience,
        skills: profile.skills,
        career: profile.career,
        bossReviews,
        workerReviews,
        reviews: bossReviews,
        ratingWorker: profile.workerRating || 0,
        ratingBoss: profile.bossRating || 0,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      logger.log(error, { component: 'PublicProfile' });
    }
  }

  handleRedirect = (target) => {
    this.props.history.push(target);
  };

  loadBossReviews = () => {
    this.setState({ reviews: this.state.bossReviews });
  };

  loadWorkerReviews = () => {
    this.setState({ reviews: this.state.workerReviews });
  };

  render() {
    const bossRating = this.state.ratingBoss;
    const workerRating = this.state.ratingWorker;

    return (
      <>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <Grid container justify="center" className="public-profile-paper">
            <Grid item xs={12} container justify="center">
              <PublicProfileInfo
                experience={this.state.proffExperience}
                skills={this.state.skills}
                bossRating={bossRating}
                workerRating={workerRating}
                avatar={this.state.photoUrl}
                name={this.state.displayName}
                bossReviews={this.state.bossReviews.length}
                workerReviews={this.state.workerReviews.length}
                onBossReviews={this.loadBossReviews}
                onWorkerReviews={this.loadWorkerReviews}
              />
            </Grid>

            <Grid item xs={12} container spacing={3} className="review-cards-container">
              {this.state.reviews.map((option) => (
                <PublicProfileCard key={option} cardInfo={option} />
              ))}
            </Grid>
          </Grid>
        )}

        <Footer />
      </>
    );
  }
}

export default PublicProfile;
