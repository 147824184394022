import React from 'react';
import Typography from '@material-ui/core/Typography';
import Swal from 'sweetalert2';
import * as pService from './services';

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const handleSubmit = () => {
  Swal.fire({
    input: 'text',
    text: `Indicanos cuál es tu sugerencia:`,
    inputAttributes: {
      autocapitalize: 'off',
    },
    showCancelButton: true,
    confirmButtonText: 'Enviar',
    cancelButtonText: 'Cancelar',
    showLoaderOnConfirm: true,
    preConfirm: async (value) => {
      const newSkill = { title: value };
      await pService.postSkill(newSkill);
      return true;
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then(() => {
    Toast.fire({
      icon: 'success',
      text: `Gracias por tu sugerencia`,
    });
  });
};

export default function NewSkill(props) {
  return (
    <Typography variant="body2" color="textPrimary" display="inline">
      ¿No encuentras alguna{' '}
      <Typography variant="body2" display="inline">
        profesión/oficio/habilidad?
      </Typography>{' '}
      Haz{' '}
      <Typography
        variant="body2"
        color="primary"
        display="inline"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleSubmit(props);
        }}
      >
        clic aquí{' '}
      </Typography>
      para sugerirla y la incluiremos lo más pronto posible.
    </Typography>
  );
}
