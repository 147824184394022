import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import 'moment/locale/es';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CardButton from '@material-ui/core/Button';
import Form from '../common/form';
import MessageReply from '../messageReply/MessageReply';

class GenericMessage extends Form {
  state = {
    data: { text: '' },
    isEditable: false,
    showResponse: this.props.enableReply,
    messageId: this.props.message._id,
  };

  resetState = () => {
    this.setState({ showResponse: false });
  };

  editMessage = () => {
    const isEditable = !this.state.isEditable;
    this.setState({ isEditable });
  };

  activateResponse = () => {
    const showResponse = !this.state.showResponse;
    this.setState({ showResponse });
    window.scroll(0, 100);
  };

  render() {
    const { message, user, buttonLabel, activateReply } = this.props;
    const { showResponse } = this.state;
    const sorted = _.sortBy(message.replies, 'created');

    return (
      <>
        <Divider variant="fullWidth" component="li" />
        <ListItem key={message._id} className="generic-message-style">
          <ListItemAvatar>
            <Avatar
              component={Link}
              to={`/PublicProfile/${message.userId ? message.userId._id : null}`}
              className="small-avatar"
              src={message.userId ? message.userId.avatar : null}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography component="span" color="secondary" variant="subtitle2">
                  {message.userId.name}&nbsp;
                </Typography>
                &nbsp;dijo&nbsp;
                <Moment fromNow className=".m-time-stamp-message">
                  {message.created}
                </Moment>
                &nbsp;:
              </>
            }
            secondary={message.text}
          />
        </ListItem>
        <Grid>
          {sorted.map((reply) => (
            <MessageReply
              key={reply._id}
              reply={reply}
              messageId={this.props.message._id}
              user={user}
              taskId={this.props.taskId}
            />
          ))}
        </Grid>
        {activateReply && (
          <Grid container justify="flex-end" alignItems="flex-start">
            <CardButton
              size="small"
              onClick={this.activateResponse}
              className="offer-message-repply-button"
            >
              {buttonLabel}
            </CardButton>{' '}
          </Grid>
        )}
        {showResponse && <div>{this.props.children}</div>}
      </>
    );
  }
}

export default GenericMessage;
