import React, { Component } from 'react';
import Joi from 'joi';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import SaveIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import logger from './services/logService';
import AsyncMultipleSelect from './async-multiple-select/AsyncMultipleSelect';

class Form extends Component {
  state = {
    data: {},
    errors: {},
    idtypeSelected: false,
    open: false,
    options: [],
  };

  validate = () => {
    const options = { abortEarly: false };
    const { data } = this.state;
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      logger.log(`Error handleSubmitBtn: ${JSON.stringify(errors)}`);
      return;
    }
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }, capitalizable) => {
    const errors = { ...this.state.errors };
    if (capitalizable)
      // eslint-disable-next-line no-param-reassign
      input.value = input.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase());

    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleMultiValueChange = (values, attrName) => {
    const valuesFlatten = values.map((item) => item.title);
    const data = { ...this.state.data };
    data[attrName] = valuesFlatten;
    this.setState({ data });
  };

  toTitleCase = (str) =>
    str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  handleChangeToUpper = ({ currentTarget: input }) => {
    if (input.value) {
      // eslint-disable-next-line no-param-reassign
      input.value = this.toTitleCase(input.value);
    }
    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleSelectChange = (event, callBack) => {
    const data = { ...this.state.data };
    data[event.target.name] = event.target.value;
    this.setState({ data });
    // this.setState({ idtypeSelected: true });
    if (callBack) callBack(event.target.value);
  };

  fieldHasErrors = (field) => {
    return this.state.errors[field] !== undefined;
  };

  renderButton(label) {
    return (
      <Fab
        variant="extended"
        size="medium"
        style={{ background: '#5078ff', color: 'white' }}
        className="camel-case"
        onClick={this.handleSubmit}
      >
        {label}
      </Fab>
    );
  }

  renderSaveIcon() {
    return (
      <IconButton
        size="small"
        color="secondary"
        onClick={this.handleSubmit}
        disabled={this.validate()}
      >
        <SaveIcon />
      </IconButton>
    );
  }

  renderDeleteIcon() {
    return (
      <IconButton
        aria-label="Eliminar"
        onClick={this.handleDeleteAction}
        style={{ marginRight: '7px' }}
        className="card-buttons"
      >
        <DeleteForeverIcon />
      </IconButton>
    );
  }

  renderEditIcon() {
    return (
      <IconButton
        aria-label="Eliminar"
        color="secondary"
        onClick={this.handleEdit}
        style={{ marginRight: '7px' }}
        className="card-buttons"
      >
        <EditIcon />
      </IconButton>
    );
  }

  renderInput(
    name,
    label,
    type = 'text',
    icon,
    placeholder,
    disabled,
    capitalizable,
    autofocus = false,
    isPhone = false,
  ) {
    const { data } = this.state;
    return (
      <TextField
        className="profile-input"
        label={label}
        name={name}
        disabled={disabled}
        margin="dense"
        variant="outlined"
        autoFocus={autofocus}
        value={data[name] || ''}
        helperText={this.fieldHasErrors(name) ? this.state.errors[name] : ''}
        onChange={(event) => {
          this.handleChange(event, capitalizable);
        }}
        error={this.fieldHasErrors(name)}
        placeholder={placeholder}
        autoComplete="new-password"
        type={type}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon || isPhone ? '+57' : ''}</InputAdornment>
          ),
        }}
      />
    );
  }

  renderSingleLineMultilineInput(name, label, type = 'text', icon, placeholder, focus) {
    const { data } = this.state;
    return (
      <TextField
        defaultValue=""
        className="input-full-width"
        label={label}
        name={name}
        margin="dense"
        variant="outlined"
        multiline
        rows="1"
        rowsMax="4"
        value={data[name]}
        onChange={this.handleChange}
        placeholder={placeholder}
        type={type}
        autoFocus={focus}
        InputProps={{
          startAdornment: <InputAdornment position="start">{icon || ''}</InputAdornment>,
        }}
      />
    );
  }

  renderMultilineInput(name, label, type = 'text', icon, placeholder) {
    const { data } = this.state;
    return (
      <TextField
        className="input-full-width"
        label={label}
        name={name}
        margin="dense"
        variant="outlined"
        multiline
        rows="8"
        rowsMax="10"
        value={data[name]}
        onChange={this.handleChange}
        error={this.fieldHasErrors(name)}
        placeholder={placeholder}
        helperText={this.fieldHasErrors(name) ? this.state.errors[name] : ''}
        type={type}
        InputProps={{
          startAdornment: <InputAdornment position="start">{icon || ''}</InputAdornment>,
        }}
      />
    );
  }

  renderCheck = (name, label) => {
    return (
      <FormControlLabel control={<Checkbox value="checkedB" color="secondary" />} label={label} />
    );
  };

  renderRadio(name, label, options) {
    const { data } = this.state;

    return (
      <FormControl variant="outlined" component="fieldset">
        <FormLabel component="legend" className="label-gender">
          {label}
        </FormLabel>
        <RadioGroup
          aria-label="Genero"
          name={name}
          value={`${data[name]}`}
          onChange={this.handleChange}
          className="form-radio-group"
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.value}
              label={option.label}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  renderSelect(name, label, options, callBack) {
    const { data, idtypeSelected } = this.state;
    return (
      <FormControl variant="outlined" className="select-input-form">
        <InputLabel
          className="label-white"
          ref={(ref) => {
            this.InputLabelRef = ref;
          }}
          htmlFor={label}
          shrink={idtypeSelected}
        >
          {label}
        </InputLabel>
        <Select
          value={data[name] || ''}
          onChange={(event) => {
            this.handleSelectChange(event, callBack);
          }}
          input={<OutlinedInput name={name} id={label} error={this.fieldHasErrors(name)} />}
        >
          {options.map((option, index) => (
            <MenuItem key={option} value={name === 'birthdateMonth' ? index + 1 : option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderMultiValue = (data, limitTags, title, placeHolder, name, asyncFunc) => {
    return (
      <AsyncMultipleSelect
        limitTags={limitTags}
        data={data}
        onHandleMultiValueChange={this.handleMultiValueChange}
        name={name}
        title={title}
        placeHolder={placeHolder}
        asyncFunc={asyncFunc}
      />
    );
  };
}

export default Form;
